<template>
    <div class="conatiner-custom">
        <Loading v-if="isLoading" />
        <Popup v-if="notif.isAlert" isError :message="notif.message" @close="() => notif.isAlert = false" />
        <div class="card">
            <img src="/img/icons/Logo Hicolleagues Text.png" alt="HiColleagues">
            <div class="body">
                <h2>Kode Redeem</h2>
                <p>Masukkan Kode Redeem yang kamu dapatkan dari dashboard Kartu Prakerja untuk verifikasi data peserta!</p>
                <v-text-field color="gray" hide-details placeholder="Masukkan Kode Redeem" class="text-input" clearable rounded single-line v-model="redeem_code"></v-text-field>
                <!-- <v-checkbox class="save" v-model="saveRedeem" label="Simpan Kode Redeem"></v-checkbox> -->
                <v-btn depressed :color="redeem_code ? '#162CA2': '#ACAFBF'" class="btn-radius mt-3 btn" @click="verificationCode">
                    <span class="text-capitalize font-weight-bold white--text">KONFIRMASI</span>
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import Popup from '@/components/others/Popup.vue'
import Loading from '../others/Loading.vue'

export default {
    name:"DVerivicationClass",
    components: {
        Popup,
        Loading
    },
    data() {
        return {
            notif: {
                isAlert: false,
                message: ""
            },
            isLoading: false,
            saveRedeem: true,
            redeem_code: '',
            param: {
                class_id: this.$route.params.id,
                session: this.$route.params.session,
            }
        }
    },
    methods: {
        async getClass() {
            try {
                const res = await this.$http.get("/v1/user-schedule/detail?user_id=" + this.$decode_token["id"] + "&class_id=" + this.param.class_id)
                if (res.data.data.schedule.category !== 4) {
                    this.$router.push(`/class/${this.param.class_id}/detail`);
                }
            } catch (error) {
                this.$router.push(`/home`)
            }
        },
        async checkStatus() {
            this.isLoading = true
            try {
                const res = await this.$http.get(`/v1/prakerja/status?redeem_code=${this.redeem_code}&sequence=${this.param.session}&class_id=${this.param.class_id}&email=${this.$decode_token["email"]}&user_id=${this.$decode_token["id"]}`)
                this.isLoading = false
                return res.data.data
            } catch (error) {
                this.isLoading = false
                this.notif.isAlert = true
                this.notif.message = error.response.data.data
                return false
            }
        },
        async verificationCode() {
            const checkingStatus = await this.checkStatus()
            if (checkingStatus === false) return
            this.isLoading = true
            const data = {
                redeem_code: this.redeem_code,
                class_id: this.param.class_id,
                session: this.param.session
            }
            if (checkingStatus.attendance_status) {
                if (this.saveRedeem) this.$utils.setLocalPrakerja(data)
                else this.$utils.setSessionPrakerja(data)
                this.isLoading = false
                this.$router.push(`/class/${this.param.class_id}/detail/${this.param.session}`);
                return
            }
            try {
                const res = await this.$web_http.post(`/v1/prakerja/url-generate`, {
                    redeem_code: this.redeem_code,
                    email: this.$decode_token["email"],
                    class_id: Number(this.param.class_id),
                    user_id: this.$decode_token["id"],
                    sequence: Number(this.param.session)
                })
                if (this.saveRedeem) this.$utils.setLocalPrakerja(data)
                else this.$utils.setSessionPrakerja(data)
                window.location.href = res.data.data.url
                this.isLoading = false
            } catch (error) {
                this.notif.isAlert = true
                this.notif.message = error.response.data.data             
                this.isLoading = false
            }
        },
        async checkRedeemCode() {
            const redeem_code = await this.$utils.getRedeem(this.param.class_id)
            if (!redeem_code) return
            this.redeem_code = redeem_code
        }
    },
    mounted() {
        this.getClass();
        this.checkRedeemCode()
    }
}
</script>


<style scoped>
.conatiner-custom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: #F5F5F5;
}

.step {
    top: 30px;
    position: absolute;
    display: flex;
    max-width: 540px;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    gap: 12px;
}

.card {
    background-color: #fff;
    border-radius: 30px;
    max-width: 540px;
    box-shadow: 2px 2px 10px 3px rgba(13, 28, 113, 0.1) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    flex-direction: column;
}

.card img {
    margin-bottom: 20px;
}

.card .body {
    width: 100%;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card p {
    text-align: center;
    margin-bottom: 20px;
}

.btn-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}

.btn {
    width: 100%;
    margin: 0;
}

.btn span {
    font-size: large;
}

.btn-outline span {
    color: #7B7E8C !important;
}

.class-name {
    font-weight: bold;
    margin-bottom: 20px;
}

.text-input {
    width: 100%;
    margin-bottom: 20px;
    padding: 10px 0;
    background-color: #F7F9FD;
    border-radius: 20px;
}

.save {
    width: 100%;
    justify-self: start;
    margin: 0px;
}
</style>
