<template>
  <div style="background: #f5f5f5; min-height: 100vh">
    <NavbarBack />

    <div class="d-flex" style="padding: 20px">
      <div><img :src="assesment.image_url" class="img-class" /></div>
      <div style="margin-left: 30px">
        <div style="display: flex; gap: 10px">
          <div
            class="d-flex button-light-blue"
            v-if="assesment.category === 4"
            style="align-items: center; justify-content: center"
          >
            <img src="/img/other/ic_prakerja.png" width="69" height="22" />
          </div>
          <h4
            class="button-light-blue"
            style="width: 120px"
            v-if="assesment.category === 4"
          >
            Webinar
          </h4>
          <h4 class="button-light-blue" style="width: 120px" v-else>
            Public Training
          </h4>
        </div>
        <h2 class="title">{{ assesment.name }}</h2>
      </div>
    </div>
    <div class="class-info mt-2 mb-6">
      <div class="content" style="width: 60px">
        <div>
          <img src="/img/icons/ic_level.png" width="20px" height="20px" />
        </div>
        <span style="margin-left: 10px">{{ $utils.formatLevel(assesment.level) }}</span>
      </div>

      <div class="content">
        <img
          src="/img/icons/ic_training_method.png"
          width="20px"
          height="20px"
        />
        <span style="margin-left: 10px">{{
          assesment.learning_method | formatMethode
        }}</span>
      </div>
    </div>

    <div style="background: #ffffff; border-radius: 24px; padding: 24px 15px">
      <div class="d-flex">
        <h2 style="font-size: 17px">Nilai Pelatihan</h2>
        <InformationForm />
      </div>
      <span
        >Nilai kompetensi yang berhasil HiColers selesaikan dalam pelatihan
        ini</span
      >

      <div
        class="bg-ghost-white card-assessment mt-5"
        style="align-items: center"
      >
        <div class="box-left">
          <h3>PreTest</h3>
          <p class="mt-3" style="font-size: 13px">
            Tes untuk mengukur kemampuan dasar sebelum mengikuti pelatihan
          </p>
        </div>
        <div class="box-right">
          <h3>{{ assesment.pre_test_score }}</h3>
          <div
            :style="getColor(assesment.pre_test_score)"
            class="green-radius"
          ></div>
        </div>
      </div>

      <div class="bg-ghost-white" style="margin-top: 20px">
        <div class="card-assessment">
          <div class="box-left">
            <h3>Quiz</h3>
            <span>(Rata-rata Nilai)</span>
            <p class="mt-3" style="font-size: 13px">
              Tes untuk mengukur kemampuan & kepahaman terhadap materi pelatihan
            </p>
          </div>

          <div class="box-right">
            <h3>{{ assesment.quiz_score }}</h3>
            <div
              :style="getColor(assesment.quiz_score)"
              class="green-radius"
            ></div>
          </div>
        </div>

        <div
          v-for="i in assesment.assesment_quiz"
          :key="i.iteration"
          style="padding: 10px 20px"
        >
          <div class="d-flex detail-quizz">
            <h4 style="width: 100%">Quiz Sesi {{ i.iteration }}</h4>
            <span>{{ i.score }}</span>
          </div>
          <div class="long-line"></div>
        </div>
      </div>
      <div
        class="bg-ghost-white"
        style="margin-top: 20px"
        v-if="assesment.category === 4"
      >
        <div class="card-assessment">
          <div class="box-left">
            <h3>Tugas Praktik Mandiri</h3>
            <span>(Rata-rata Nilai)</span>
            <p class="mt-3" style="font-size: 13px">
              Tes untuk mengukur kemampuan & kepahaman terhadap materi pelatihan
            </p>
          </div>
          <div class="box-right">
            <h3>{{ assesment.tpm_score }}</h3>

            <div
              :style="getColor(assesment.tpm_score)"
              class="green-radius"
            ></div>
          </div>
        </div>

        <div
          v-for="i in assesment.assesment_tpm"
          :key="i.iteration"
          style="padding: 10px 20px"
        >
          <div class="d-flex detail-quizz">
            <h4 style="width: 100%">TPM Sesi {{ i.iteration }}</h4>
            <span>{{ i.score }}</span>
          </div>
          <span><b>Catatan</b>: <span style="color: #b80f0a;">{{ i.note }}</span></span>
          <div class="long-line"></div>
        </div>
      </div>
      <div
        class="bg-ghost-white d-flex card-assessment"
        style="align-items: center; margin-top: 20px"
      >
        <div class="box-left">
          <h3>Post-Test</h3>
          <p class="mt-3" style="font-size: 13px">
            Tes evaluasi untuk mengetahui tingkat penyerapan ilmu & pemahaman
            seluruh materi pelatihan
          </p>
        </div>
        <div class="box-right">
          <h3>{{ assesment.post_test_score }}</h3>

          <div
            :style="getColor(assesment.post_test_score)"
            class="green-radius"
          ></div>
        </div>
      </div>

      <div
        class="bg-ghost-white d-flex card-assessment"
        style="align-items: center; margin-top: 20px"
      >
        <div class="box-left">
          <h3>Unjuk Keterampilan</h3>
          <p class="mt-3" style="font-size: 13px">
            Evaluasi performa HiColers melalui karya Unjuk Keterampilan sebagai
            pendukung kompetensi pelatihan
          </p>
        </div>
        <div class="box-right">
          <h3>{{ assesment.final_project_score }}</h3>
          <div
            :style="getColor(assesment.final_project_score)"
            class="green-radius"
          ></div>
        </div>
      </div>

      <div class="bg-ghost-white" style="margin-top: 20px">
        <div style="width: 100%">
          <h3>Catatan</h3>
          <span>{{ assesment.notes }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InformationForm from "@/components/Assesment/Mobile/Information";
import NavbarBack from "@/components/Navbar/Mobile/NavbarBack";

export default {
  components: {
    InformationForm,
    NavbarBack,
  },
  data() {
    return {
      assesment: {},
    };
  },
  methods: {
    getColor(e) {
      if (e <= 50) return "background:#B80F0A";
      else if (e >= 70 && e <= 79) return "background:#E5AF0B";
      else return "background:#01A368";
    },
    async getDetailAssesment() {
      await this.$http
        .get("/v1/user-assesment/" + this.$route.params.id)
        .then((response) => {
          this.assesment = response.data.data.assesment;
        })
        .catch((err) => console.error(err.response));
    },
  },
  mounted() {
    this.getDetailAssesment();
  },
};
</script>

<style scoped>
.title {
  font-weight: bold;
  font-size: 15px !important;
  line-height: 20px;
  color: #36373f;
}

span {
  font-size: 12px;
}

.green-radius {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #01a368;
  margin-left: 8px;
}

h3 {
  font-size: 13px;
}

h4 {
  font-size: 13px;
}
.img-class {
  width: 70px;
  height: 70px;
  border-radius: 18px;
}
.class-info {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  width: 80%;
  margin: auto;
  margin-top: -90px;
}
.class-info .content {
  align-items: flex-start;
  gap: 8px;
  display: flex;
  width: 150px;
}
.temp-1 {
  display: flex;
  align-items: center;
}
.bg-ghost-white {
  border-radius: 16px !important;
}
.card-assessment {
  display: flex;
  align-items: flex-start !important;
  gap: 15px;
}

.card-assessment .box-right .desc {
  line-height: 16px;
  display: block;
}

.card-assessment .box-right {
  display: flex;
  gap: 8px;
  align-items: center;
}
</style>
