<template>
    <div class="bg">
        <div class="mt-5" @click="goToPreviousPage()">
            <img src="/img/icons/ic_back.png" width="24px" height="24px">
        </div>

        <h3 class="mt-5">Akun</h3>
        <span>Di sini dapat mengatur tentang informasi HiColers</span>

        <h3 class="mt-10">Title</h3>
        <v-autocomplete
            ref="gender"
            v-model="gender"
            :items="genders"
            label="Titel"
            placeholder="Select..."
            required
            solo
            class="gender-dropdown"
        ></v-autocomplete>

        <h3>Nama Lengkap</h3>
        <v-text-field 
            append-icon="mdi-pencil"
            label="Nama Lengkap" 
            v-model="fullname"
            solo
            clearable
            placeholder="Nama Lengkap"
            class="custom-text-field"
            :rules="[rules.required]"
            required>
        </v-text-field>

        <v-btn depressed color="#162CA2" style="width: 100%;" class="btn-radius d-flex" @click="updateAccount()">
            <span class="text-capitalize font-weight-bold white--text" >SIMPAN</span>
        </v-btn>
    </div>
</template>

<script>
export default {
    data () {
        return {
            gender: '',
            genders: ['Tuan', 'Nyonya', 'Nona'],
            fullname: '',
            data: JSON.parse(localStorage.getItem("account")),
            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 5 || 'Min 5 characters',
            },
        }
    },
    methods: {
        goToPreviousPage() {
            history.back()
        },
        async updateAccount() {
            await this.$http
                .put("/v1/account/profile", {
                    'gender': this.gender,
                    'full_name': this.fullname,
                    'email': this.data["email"]
                })
                .then(() => {
                    const req = JSON.stringify({ 
                        "full_name": this.fullname,
                        "email": this.data["email"],
                        "gender": this.gender,
                        "phone_number": this.data["phone_number"]
                    })
                    localStorage.setItem("account", req);
                    window.location.href = '/setting';
                })
            .catch((err) => console.error(err))
        },
    },
    mounted () {
        this.fullname = this.data["full_name"]
        this.phoneNumber = this.data["phone_number"]
        this.gender = this.data["gender"]
    }
}
</script>

<style scoped>
.bg {
    padding: 0px 19px;
    min-height: 100vh;
}

.gender-dropdown {
    width: 360px;
    margin-top: 16px;
    box-shadow: none !important;
}

.gender-dropdown >>> .v-input__control > .v-input__slot:before {
    border-color: #7B7E8C !important;
    box-shadow: none !important;
    
}

.gender-dropdown >>> .v-input__control > .v-input__slot:after {
    border-color: #7B7E8C !important;
    box-shadow: none !important;
}

.gender-dropdown >>> .v-input__control > .v-input__slot{
    border-radius: 16px;
    background: #F7F9FD;
    border-style: none;
    box-shadow: none !important;
}

.custom-text-field >>> .v-input__control > .v-input__slot:before {
    border-color: inherit;
    border-style: none !important;
    border-width: thin 0 0 0;
}

.custom-text-field >>> .v-input__control > .v-input__slot:after {
    border-color: inherit;
    border-style: none !important;
    border-width: thin 0 0 0;
}

.custom-text-field >>> .v-input__control > .v-input__slot{
    border-radius: 16px;
    background: #F7F9FD;
    width: 100%;
    box-shadow: none !important;
    margin-top: 10px;
}
</style>