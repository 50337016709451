<template>
    <svg :width="width" :height="height"
        :style="`transform: rotate(${direct ? (direct == 'right' ? 90 : 270) : (down ? 180 : 0)}deg)`" viewBox="0 0 32 32"
        fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M15.9599 11.2929C16.3504 10.9024 16.9836 10.9024 17.3741 11.2929L25.3741 19.2929C25.7646 19.6834 25.7646 20.3166 25.3741 20.7071C24.9836 21.0976 24.3504 21.0976 23.9599 20.7071L16.667 13.4142L9.3741 20.7071C8.98357 21.0976 8.35041 21.0976 7.95989 20.7071C7.56936 20.3166 7.56936 19.6834 7.95989 19.2929L15.9599 11.2929Z"
            :fill="strokeColor" />
    </svg>
</template>

<script>
export default {
    props: {
        width: {
            type: Number,
            default: 25
        },
        height: {
            type: Number,
            default: 25
        },
        direct: {
            type: String,
        },
        down: {
            type: Boolean,
            default: false
        },
        strokeColor: {
            type: String,
            default: "#36373F"
        }
    }
}
</script>