<template>
    <div class="container-body-mobile">
        <h2>FAQ</h2>

        <div style="padding: 35px 0px;text-align: center;">
            <v-list style="background: #F5F6F9;margin-bottom:65px;">
                <v-list-group
                v-for="item in faq"
                :key="item.title"
                v-model="item.active"
                :prepend-icon="item.action"
                no-action
                active-class="bg-rounded"
                class="bg-rounded"
                >
                <template v-slot:activator>
                    <v-list-item-content class="font-weight-bold" style="margin-bottom;color: #36373f;">
                    <v-list-item-title class="custom-text-size" v-text="item.title"></v-list-item-title>
                    </v-list-item-content>
                </template>

                    <v-list-item-content style="padding-left:15px;color: #36373f;">
                    <v-list-item-title class="custom-desc-text-size" v-html="item.description"></v-list-item-title>
                    </v-list-item-content>
                </v-list-group>
            </v-list>


            <span>Tidak ada pertanyaan yang ingin kamu tanyakan di atas? </span>
            <div>
                <v-btn depressed @click="goToCustomerService" style="margin-top:15px;padding:10px 105px;" class="btn-stoke-blue">
                    <span class="text-capitalize font-weight-bold">Ajukan Pertanyaan</span>
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            faq: [
                {
                    description: "<ul><li>[Wajib] Mengerjakan Pre Test (Bisa dikerjakan sebelum Sesi 1 dimulai)</li><li>Hadir dalam Sesi 1 (LIVE ZOOM)</li><li>[Wajib] Mengerjakan Kuis (Minimal nilai 80, boleh di kerjakan berulang kali)</li><li>Hadir dalam Sesi 2 (LIVE ZOOM)</li><li>[Wajib] Mengerjakan Post Test (Pengerjaan Post Test hanya 1X aja)</li><li>Mengerjakan Ujian Praktik [Tidak Wajib]</li><li>Hadir dalam Sesi Konsultasi [Tidak Wajib]</li><li>Dapat Sertifikat.</li></ul>",
                    title: "Cara mengikuti Pelatihannya gimana ya kak?",
                },
                {
                    description: "Kelas pelatihan dimulai sesuai dengan jadwal yang sudah kami kirimkan ke Email dan Whatsapp peserta masing-masing ya. Jadi tolong di cek ya teman-teman email nya.",
                    title: "Kak, Kelas pelatihannya mulainya kapan ya?",
                },
                {
                    description: "Boleh ya, pengerjaanya cukup 1x aja dan ini wajib dikerjakan ya teman-teman.",
                    title: "Pre test nya boleh dikerjakan beberapa hari sebelumnya gak kak?",
                },
                {
                    description: "Nilai pretest tidak dibagikan ya teman-teman. Nilai yang akan dibagikan yaitu nilai kuis dan juga post test. Nilainya akan kami kirimkan ke email masing-masing ya. Silakan cek di email inbox / spam.",
                    title: "Kak, mau lihat nilai tugas-tugas kita dimana ya kak?",
                },
                {
                    description: "Wajib ya teman-teman. Kita akan melakukan absensi masuk dan juga absensi keluar.",
                    title: "Kak, peserta wajib ikut Live Zoom nya?",
                },
                {
                    description: "Nilai kuis yang dibawah 80, boleh mengerjakan ulang ya kuisnya. Sampai kalian mendapatkan nilai 80. Sedangkan, untuk yang sudah mendapatkan nilai 80 keatas tidak perlu mengerjakan ulang lagi ya teman-teman.",
                    title: "Kak, nilai kuis saya dibawah 80 nih, gimana ya kak?",
                },
                {
                    description: "Bagi yang sudah lulus boleh mengikuti Sesi pelatihan ke 2 pada hari Rabu (sesuai jadwal masing- masing ya). Karena masih ada test lainnya. [Wajib ikut lagi ya]",
                    title: "Bagi yang sudah lulus KUIS di Sesi-1, gimana lagi ya kak kelanjutannya?",
                },
                {
                    description: "Bagi yang belum lulus, dapat mengerjakan ulang form kuis tersebut. Kerjakan ulang hingga nilai yang di dapatkan min nilai 80 ya teman-teman. [Boleh dikerjakan berkali-kali]",
                    title: "Kak, kalau yang KUIS nya tidak lulus gimana?",
                },
                {
                    description: "Sabar ya rekan-rekan peserta semua, kami mengirimkan email 1 per 1 kepada kalian jdi harap bersabar ya🥺",
                    title: "Kak, saya sudah mengerjakan tapi kok nilainya belum ada di email ya kak?",
                },
                {
                    description: "Nilai kuis akan dikirimkan ke email masing-masing ya. Silakan cek di email inbox / spam.",
                    title: "Nilai Kuis nya lihat dimana ya kak?",
                },
                {
                    description: "Link tugas-tugas seperti Kuis, Post Test, absensi masuk dan keluar, Ujian Praktik, dan informasi- informasi lainnya akan diberikan di ROOM CHAT ZOOM. Jadi tolong diperhatikan ROOM CHAT ZOOM ya teman-teman.",
                    title: "Link Form Kuis dan Post Test di kirimkan dimana ya kak?",
                },
                {
                    description: "<ul><li>Iya Betul hanya 1X aja ya teman-teman. Hal yang harus diperhatikan dari pengerjaan Post Test</li><li>Jika nilai post test >= 60 mendapatkan Sertifikat Kompetensi, jika nilai post test <= 60 mendapatkan Sertifikat Penyelesaian.</li><li>Hasil dari nilai post test akan dikirimkan ke email masing-masing ya. Bisa di cek di inbox/spam.</li></ul>",
                    title: "Kak, Pengerjaan Post Test hanya 1X aja ya kak?",
                },
            ],
        }
    },
    methods: {
        goToCustomerService() {
            window.location.href = 'https://api.whatsapp.com/send/?phone=6282127274632&text=Hi Kak HiColleagues, saya mau bertanya';
        },
    }
}
</script>

<style scoped>
.bg-rounded {
  background: #ffffff;
  border-radius: 25px;
  color: #36373f;
  text-align: left;
  padding: 4px;
  margin-bottom: 10px;
}

</style>