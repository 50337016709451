<template>
    <div>
        <div class="d-flex navbar-mobile" style="justify-content: space-between;" v-if="isHome">
            <div style="width:50%" @click="isHamburgerOpen = !isHamburgerOpen"><img src="/img/icons/ic_hamburger_menu.png" width="32px" height="32px"></div>
            <div class="d-flex justify-end">
                <span>{{ name }}</span>
                <div class="name-circle d-flex">
                    <span>{{ initialName }}</span>
                </div>
            </div>
        </div>

        <div class="d-flex navbar-mobile" style="background:white" v-if="!isHome && !isHideNavbar">
            <div @click="openNavMo"><img src="/img/icons/ic_hamburger_menu.png" width="32px" height="32px"></div>
            <h4 style="width:100%;text-align:center">{{toolbarTitle}}</h4>
        </div>

        <div v-if="isHamburgerOpen" class="hamburger-menu">
            <div class="d-flex">
                <div style="width:100%;margin:-40px -10px;"><img src="/img/logo.png" width="180px" height="120px"></div>
                <div @click="isHamburgerOpen = !isHamburgerOpen"><img src="/img/icons/ic_close.png" width="17px" height="17px"></div>
            </div>

            <HamburgerMenu />
        </div>
    </div>
</template>

<script>
import HamburgerMenu from '@/components/Navbar/Mobile/HamburgerMenu'
import VueJwtDecode from 'vue-jwt-decode';

export default {
    components: {
        HamburgerMenu
    },
    data () {
        return {
            isHamburgerOpen: false,
            isHome: false,
            isHideNavbar: false,
            toolbarTitle: '',
            name: '',
            initalName: ''
        }
    },
    methods: {
        openNavMo() {
            setTimeout(() => {
                window.scrollTo(0,0)
            }, 200);
            this.isHamburgerOpen = !this.isHamburgerOpen
        }
    },
    beforeMount () {
        if (this.$route.params.token != null) {
            localStorage.setItem("user", this.$route.params.token)
            this.$forceUpdate()

            let decoded = VueJwtDecode.decode(this.$route.params.token)
            this.$decode_token = decoded
        }
    },
    mounted () {
        if (this.$route.name == 'Beranda' || this.$route.name == 'BerandaAuth') {
            this.isHome = true
        }

        if (this.$route.name == 'Detail Nilai Pelatihan' || this.$route.name == 'Detail Kelas' || this.$route.name == 'Pengaturan') {
            this.isHideNavbar = true
            this.isHome = false
        }


        if (this.$route.name == 'Question') {
            this.toolbarTitle = this.$route.query.c
        } else {
            this.toolbarTitle = this.$route.name
        }
        

        var fullname = ''
        var data = JSON.parse(localStorage.getItem("account"))
        if (data != null) {
            
            fullname = data["full_name"].split(" ")
            this.name = data["full_name"]
        } else {
            fullname = this.$decode_token["name"].split(" ")
            this.name = this.$decode_token["name"]
        }

        if (fullname.length >= 2) {
            this.initialName = fullname[0][0] + '' + fullname[1][0]
        } else {
            this.initialName = fullname[0][0]
        }
    }
}
</script>

<style scoped>

.navbar-mobile {
    padding: 10px 18px;
    align-items:center;
    position: fixed;
    width: 100%;
    z-index: 1;
    background: #F5F5F5;;
}

.name-circle {
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    margin-left: 19px;
    align-items: center;
    justify-content: center;
    width: 30px;
    height:30px;
    border-radius:50%;
    background: linear-gradient(111.75deg, #179EFF -0.58%, #162CA2 100.82%);
}
</style>