<template>
  <div class="container-size bg-success" style="min-height: 100vh">
    <div class="s-card">
      <div class="text-center">
        <img src="/img/other/success_notif.png" style="width: 80%" />
      </div>
      <div class="text-center font-weight-bold mt-10">
        <p style="font-size: 24px; max-width: 410px; margin: auto">
          Ulasan Terposting!
        </p>
      </div>
      <div class="text-center mt-5 mb-10">
        <span style="font-size: 1rem"
          >Rating & ulasan yang kamu berikan telah berhasil terposting. Hal ini
          berguna untuk meningkatkan layanan dan pengalaman peserta. Terima
          kasih.</span
        >
      </div>
      <div class="text-center mt-5 d-flex" style="justify-content: center">
        <Button
          name="KEMBALI KE LMS"
          @click="goToHomePage"
          width="270px"
          height="47px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/others/Button.vue";
export default {
  components: {
    Button,
  },
  methods: {
    goToHomePage() {
      this.$router.push("/home");
    },
  },
};
</script>
<style scoped>
.s-card {
  padding: 40px 60px;
  width: 40%;
  margin: auto;
  background-color: white;
  border-radius: 36px;
}
.bg-success {
  background-color: rgb(243, 240, 240);
  padding: 30px 0;
}
@media screen and (max-width: 900px) {
  .s-card {
    width: 90%;
    padding: 30px 18px;
    margin-top: 40px;
  }
}
</style>
