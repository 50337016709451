<template>
  <div class="container-custom">
    <Loading v-if="isLoading" />
    <Popup :message="errorMessage" isError v-if="isShowPopup"
            @close="() => { isShowPopup = !isShowPopup }" />
    <div class="list-scrumb" style="margin-top: -28px">
      <div
        v-for="(item, idx) in items"
        :key="idx"
        class="list-scrumb ml-4 cursor-pointer"
        @click="$router.push(item.href)"
      >
        <span>{{ item.text }}</span>
        <arrow strokeColor="#7B7E8C" direct="right" />
      </div>
      
      <span class="title-scrumb">{{
        this.$helpers.maxStringLength(dataClass.name, 20)
      }}</span>
    </div>
    <h1 class="text-center mt-10">Reflective Journal & Ulasan</h1>
    <p class="text-center">Bantu kami untuk meningkatkan pelatihan ini jadi semakin lebih baik</p>
    <div class="mt-8 ratting-ctn">
      <div class="card">
      <h3>Kelas</h3>
      <div class="mt-2 ratting-ctn-class">
        <img
          :src="dataClass.image_url"
          width="80px"
          height="80px"
          style="border-radius: 16px"
        />
        <div>
          <div v-if="dataClass.category === 4" style="display: flex">
            <div
              class="d-flex button-light-blue"
              v-if="dataClass.category === 4"
              style="align-items: center; justify-content: center"
            >
              <img src="/img/other/ic_prakerja.png" width="69" height="22" />
            </div>
            <div
              class="d-flex button-light-blue"
              v-if="dataClass.category === 4"
              style="
                align-items: center;
                justify-content: center;
                margin-left: 10px;
              "
            >
              <span>Webinar</span>
            </div>
          </div>
          <div class="button-light-blue" v-if="dataClass.category !== 4">
            <span>Public Training</span>
          </div>
          <h3 id="font-poppins">{{ dataClass.name }}</h3>
          <div style="display: flex; gap: 20px; align-items: center;" class="mt-3">
          <StarTemplate
            :starCount="form.stars"
            :width="40"
            :height="40"
            isInput
            @onChange="changeStars"
          />
          <h5 style="text-transform: uppercase;">{{ indikatorStar(form.stars) }}!</h5>
        </div>
        </div>
      </div>
    </div>
    <div class="card mt-4">
      <div v-for="(item, idx) in reflective_journals" :key="idx" class="mt-4">
        <p>{{ idx +1 }}. {{ item.question }}<span style="color: red;">*</span></p>
        <v-textarea
          rows="4"
          v-model="item.answer"
          class="form-retting"
          outlined
          placeholder="Tulis di sini...."
          :maxlength="limitText"
          @input="handleCheckInput"
          :clearable="!isCompleteReview"
          hide-details
          :readonly="isCompleteReview"
        ></v-textarea>
        <p class="text-right">
          {{ item.answer.length }} / {{ limitText }}
        </p>
      </div>
      <div class="mt-4">
        <p>{{ reflective_journals.length +1 }}. {{ questionFinal }}<span style="color: red;">*</span></p>
        <div class="group-shorcut">
          <span class="shorcut" v-for="(item2, idx2) in shortCut"  :key="idx2" @click="onChortCut(item2)">{{ item2 }}</span>
        </div>
        <v-textarea
          rows="4"
          v-model="form.feedback"
          class="form-retting mt-5"
          outlined
          placeholder="Tulis di sini...."
          :maxlength="limitText"
          hide-details
          @input="handleCheckInput"
          :readonly="isCompleteReview"
        ></v-textarea>
        <p class="text-right">
          {{ form.feedback.length }} / {{ limitText }}
        </p>
      </div>
        <div style="display: flex; justify-content: center" class="mb-4" v-if="!isCompleteReview">
          <Button
            name="KIRIM"
            width="55%"
            :disabled="!isCompleteAllInput"
            @click="submitUlasan"
          ></Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StarTemplate from "../../others/StarTemplate.vue";
import Loading from "@/components/others/Loading.vue";

import Button from "../../others/Button.vue";
import Arrow from "@/assets/icons/Arrow.vue";
import Popup from "@/components/others/Popup.vue";

export default {
  name: "DSurvey",
  data() {
    return {
      limitText: 300,
      questionFinal: "Bagikan pengalamanmu tentang pelatihan ini!",
      dataClass: {},
      isShowPopup: false,
      isLoading: false,
      errorMessage: "",
      items: [
        {
          text: "Kelas Aktif",
          disabled: false,
          href: "/home",
        },
        {
          text: "Reflective journal & Ulasan",
          disabled: false,
          href: "/review",
        },
      ],
      shortCut: [
        "Pelatihan berjalan baik", "Materi relevan", "Mentor berdedikasi", "Membangun relasi","Meningkatkan wawasan & keterampilan"
      ],
      isCompleteReview: false,
      isCompleteAllInput: false,
      reflective_journals: [],
      form: {
        stars: 5,
        feedback: "",
      },
    };
  },
  components: {
    StarTemplate,
    Button,
    Popup,
    Arrow,
    Loading
  },
  methods: {
    async getClassById() {
      try {
        const resp = await this.$http.get(
          "/v1/class/" + this.$route.params.class_id
        );
        this.dataClass = resp.data.data.class;
      } catch (err) {
        console.log(err);
      }
    },
    async getReview() {
      try {
        const resp = await this.$web_http.get(`/v1/survey?category=PRAKERJA&class_id=${this.$route.params.class_id}&user_id=${this.$decode_token["id"]}`)
        const dataSurvey = resp.data.data.surveys
        if (!dataSurvey) {
          this.isCompleteReview = false
          return    
        }
        this.reflective_journals.forEach((item, idx) => {
          item.answer = dataSurvey[0].answers[idx].answer
        });
        this.form.feedback = dataSurvey[0].answers[2].answer
        this.isCompleteReview = true
      } catch {
        this.isCompleteReview = false
      }
    },
    changeStars(v) {
      if (this.isCompleteReview) {
        return
      }
      this.form.stars = v;
    },
    handleCheckInput() {
      let valid = true
      if (!this.form.feedback) valid = false
      this.reflective_journals.forEach((item) => {
        if (!item.answer) valid = false 
      })
      this.isCompleteAllInput = valid
    },
    indikatorStar(star){
      if (star === 1) {
        return "Sangat Buruk"
      } else if (star === 2) {
        return "Buruk"
      }else if (star === 3) {
        return "Cukup Bagus"
      }else if (star === 4) {
        return "Bagus"
      } else {
        return "Sempurna"
      }
    },
    onChortCut(text) {
      if (this.isCompleteReview) {
        return
      }
      const arr = this.form.feedback.split(",")
      const result = arr.filter(element => element.includes(text));
      if (result.length) return
      this.form.feedback = this.form.feedback + `${!arr[0] ? '' : ", "}${text}`
      this.handleCheckInput()
    },
    async submitUlasan() {
      this.isLoading = true
      try {
        this.reflective_journals.push({
          question: this.questionFinal,
          answer: this.form.feedback
        })
        await this.$web_http.post("/v1/survey/testimony", {
          category: "PRAKERJA",
          class_id: this.dataClass.class_id,
          user_id: this.$decode_token["id"],
          date: this.dataClass.dates,
          name: this.$decode_token["name"],
          stars: this.form.stars,
          feedback: this.form.feedback,
          answers: this.reflective_journals.map((item) => ({
            answer: item.answer,
            question: item.question
          }))
        })
        this.isLoading = false
        this.$router.push("/survey-done")  
      } catch (err) {
        this.isShowPopup = true
        this.errorMessage = "Terjadi kesalahan"
        this.isLoading = false
        this.reflective_journals.splice(this.reflective_journals.length - 1, 1)
      }
      
    },
    async getReflectiveJournal() {
      this.isLoading = true
      try {
        const resp = await this.$web_http.get("/v1/reflective-journal")
        this.reflective_journals = resp.data.data.reflective_journals.map((item) => ({
          question: item.question,
          question_type: item.question_type,
          answer: ""
        }))
        this.getReview()
        this.isLoading = false
      } catch {
        this.isLoading = false
      }
    }
  },
  mounted() {
    this.getClassById();
    this.getReflectiveJournal()
  },
};
</script>

<style scoped>
.list-scrumb {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: bold;
  color: #162ca2;
  font-size: 18px;
}


.title-scrumb {
  color: #7b7e8c;
  font-weight: 400;
}

.ratting-ctn {
  width: 100%;
  max-width: 800px;
  margin: auto;
}
.ratting-ctn .text-right {
  font-size: 13px;
}
.ratting-ctn-class {
  display: flex;
  gap: 15px;
  align-items: flex-start;
}
.ratting-ctn-star {
  width: 70%;
  margin: auto;
}
.ratting-ctn-star .penilaian {
  display: flex;
  justify-content: space-between;
}

.ratting-ctn-star .penilaian span {
  font-size: 12px;
}
.form-retting {
  border-radius: 14px !important;
}
.card {
    background-color: #FFFFFF;
    width: 100%;
    border-radius: 20px;
    padding: 20px 25px;
}

.card .title {
    font-size: medium !important;
    line-height: 27px;
    color: #36373F;
    font-weight: bold;
    font-family: 'Poppins' !important;
}
.group-shorcut {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}
.shorcut {
  min-width: max-content;
  padding: 8px 25px;
  background-color: #F7F9FD;
  cursor: pointer;
  border-radius: 20px;
  font-size: 15px;
}

</style>
