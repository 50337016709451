<template>
  <div>
    <Loading v-if="isLoading" />
    <div class="d-flex" style="padding: 80px 15px 20px 15px">
      <div style="display: flex; justify-content: center; align-items: center">
        <img
          :src="session.image_url ? session.image_url : '/img/logo-hc.png'"
          width="90px"
          height="90px"
          style="border-radius: 24px"
        />
      </div>
      <div style="margin-left: 25px">
        <div style="display: flex">
          <div
            class="d-flex button-light-blue"
            style="align-items: center; justify-content: center"
          >
            <img src="/img/other/ic_prakerja.png" width="69" height="22" />
          </div>
          <div
            class="d-flex button-light-blue"
            style="
              align-items: center;
              justify-content: center;
              margin-left: 10px;
            "
          >
            <span>Webinar</span>
          </div>
        </div>
        <h3 class="title">
          {{ session.name }}
        </h3>
      </div>
    </div>
    <div style="display: flex;">
        <div class="box-info">
          <img
            class="box-img"
            src="/img/icons/ic_level.png"
            width="30px"
            height="30px"
          />
          <span>{{ $utils.formatLevel(session.level) }}</span>
        </div>
        <div class="box-info">
          <img
            class="box-img"
            src="/img/icons/ic_date.png"
            width="30px"
            height="30px"
          />
          <span>{{ session.dates }}</span>
        </div>
        <div class="box-info">
          <img
            class="box-img"
            src="/img/icons/ic_training_method.png"
            width="30px"
            height="30px"
          />
          <span>{{ session.learning_method | formatMethode }}</span>
        </div>
    </div>

    <div class="container-radius">
        <div style="width: 100%">
          <h3>Presensi Kelas</h3>
          <span class="font-normal-mobile">Riwayat kehadiran HiColers dalam pelatihan ini</span>
        </div>

        <v-btn
          depressed
          style="background: white"
          width="100%"
          height="40px"
          class="btn-stoke-blue mt-5"
          @click="goToPage()"
          disabled
        >
          <img
            class="box-img"
            src="/img/icons/ic_download.png"
            width="30px"
            height="30px"
          />

          <span class="text-capitalize font-weight-bold">UNDUH</span>
        </v-btn>
    </div>

    <div class="container-radius">
        <div style="width: 100%">
          <h3>Materi Pelatihan</h3>
          <span class="font-normal-mobile">Akses materi sebagai modul pendukung dalam mengikuti
            pelatihan</span>
        </div>

        <v-btn
          depressed
          style="background: white"
          width="100%"
          height="40px"
          class="btn-stoke-blue mt-5"
          @click="goToPage(session.material_link)"
        >
          <span class="text-capitalize font-weight-bold">MATERI PELATIHAN</span>
        </v-btn>
    </div>

    <div class="container-radius">
      <div class="d-flex">
        <div style="width: 100%">
          <h3>Tugas Praktik Mandiri</h3>
          <span class="font-normal-mobile">Riwayat tes evaluasi untuk mengetahui tingkat penyerapan ilmu
            melalui tugas praktik</span>
        </div>
      </div>
      <div
        v-for="(item, idx) in session.list_tpm"
        :key="idx"
        class="bg-ghost-white d-flex"
        style="align-items: center; margin-top: 20px"
      >
        <div style="width: 100%">
          <h4>Pertemuan {{ idx + 1 }}</h4>
        </div>
        <v-btn
          depressed
          style="background: white"
          class="btn-stoke-blue"
          width="120px"
          @click="goToPage(item.attachment_url)"
          :disabled="!item.attachment_url"
        >
          <span class="text-capitalize font-weight-bold">LIHAT</span>
        </v-btn>
      </div>
    </div>

    <div class="container-radius">
        <div style="width: 100%">
          <h3>Tugas Unjuk Keterampilan</h3>
          <span class="font-normal-mobile">Riwayat Tugas Unjuk Keterampilan sebagai pendukung kelengkapan
            pelatihan</span>
        </div>

        <v-btn
          depressed
          style="background: white"
          width="100%"
          height="40px"
          class="btn-stoke-blue mt-5"
          @click="goToPage(finalUk.attachment_url)"
          :disabled="!finalUk.attachment_url"
        >
          <span class="text-capitalize font-weight-bold">LIHAT</span>
        </v-btn>
    </div>

    <div class="container-radius" v-if="true">
      <div class="d-flex" style="align-items: center">
        <div style="width: 100%">
          <h3>Materi Silabus</h3>
          <span class="font-normal-mobile">Apa saja yang peserta pelajari dalam pelatihan ini</span>
        </div>
      </div>

      <div>
        <div
          v-for="(item, idx) in session.syllabuses"
          :key="idx"
          class="bg-ghost-white d-flex"
          style="align-items: center; margin-top: 20px"
        >
          <div style="width: 100%">
            <h4>{{ item.session }}</h4>
            <div v-html="item.description" class="font-normal-mobile"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/others/Loading.vue";

export default {
  components: {
    Loading,
  },
  name: "DSessionClass",
  data() {
    return {
      isLoading: false,
      classId: this.$route.params.id,
      userID: this.$decode_token["id"],
      finalUk: {attachment_url: ""},
      session: {
        id: 0,
        name: "",
        image_url: "",
        category: 0,
        learning_method: "",
        dates: "",
        days: "",
        hours: "",
        level: "",
        syllabuses: [],
        list_tpm: [],
      },
    };
  },
  methods: {
    async getDetailSchedule() {
      await this.$http
        .get(
          `/v1/user-schedule/detail?user_id=${this.$decode_token["id"]}&class_id=${this.$route.params.id}`
        )
        .then((response) => {
          const data = response.data.data.schedule;
          this.session = data;
          if (!this.session.list_tpm) {
            this.session.list_tpm = [];
          }
          const ukData = data.list_tpm.find((item) => item.iteration === 999);
          if (ukData) {
            this.finalUk = ukData;
            data.list_tpm.pop();
            this.session.list_tpm = data.list_tpm;
          }
        })
        .catch((err) => console.error(err.response));
    },
    goToPage(link) {
      window.open(link);
    },
  },
  mounted() {
    this.getDetailSchedule();
  },
};
</script>

<style scoped>
.list-scrumb {
  display: flex;
  align-items: center;
  gap: 15px;
  font-weight: bold;
  color: #162ca2;
  font-size: 18px;
}
.title-scrumb {
  color: #7b7e8c;
  font-weight: 400;
}

.title {
  font-weight: bold;
  font-size: 18px !important;
  line-height: 25px !important;
  color: #36373f;
}
.opt-session {
  cursor: pointer;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
  color: #7b7e8c;
}
.opacity-1 {
  opacity: 0.4;
}
.font-normal-mobile {
  font-size: 15px !important;
}
.box-info {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    align-items: center;
    justify-content: flex-start;
}
.box-info span {
  font-size: 14px !important;
}

</style>
