<template>
    <div>
        <DesktopDetailClassPrakerja v-if="!isMobile" />
        <MobileDetailClassPrakerja v-else />
    </div>
  </template>
  
  <script>
  import DesktopDetailClassPrakerja from '@/components/DetailClass/Desktop/DetailClassPrakerja'
  import MobileDetailClassPrakerja from '@/components/DetailClass/Mobile/DetailClassPrakerja'
  export default {
    components: {
      DesktopDetailClassPrakerja,
      MobileDetailClassPrakerja
    },
    data() {
      return{
        isMobile: false
      }
    },
    methods: {
        handleView() {
            this.isMobile = screen.width <= 990
        }
    },
    beforeMount() {
      this.handleView()
    }
  }
  </script>