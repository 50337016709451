import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Main/Home.vue';
import Certificate from '../views/Main/Certificate.vue';
import Assesment from '../views/Assesment/Assesment.vue';
import DetailAssesment from '../views/Assesment/DetailAssesment.vue';
import PageNotFound from '../views/ErrorPage/PageNotFound.vue';
import UserProfile from '../views/User/UserProfile.vue';
import ActiveClass from '../views/Main/ActiveClass.vue';
import HistoryActiveClass from '../views/Main/HistoryActiveClass.vue';
import DetailClass from '../views/Class/DetailClass.vue';
import DetailClassPrakerja from '../views/Class/DetailClassPrakerja.vue';
import Verification from '../views/Verification/Verification.vue';
import VerificationCallback from '../views/Verification/CallBack.vue';
import HistoryClass from '../views/Main/HistoryClass.vue';
import AboutUs from '../views/Profile/AboutUs.vue';
import Review from '../views/Review/Review.vue';
import Survey from '../views/Survey/Survey.vue';
import SurveySuccess from '../views/Survey/SurveyDone.vue';
import Faq from '../views/Profile/Faq.vue';
import SessionClass from '../views/Class/SessionClass.vue';
import Profile from '../views/Profile/Profile.vue';
import PrivacyTerms from '../views/Profile/PrivacyTerms.vue';
import TermCondition from '../views/Profile/TermCondition.vue';
import Setting from '../views/Profile/Setting.vue';
import Question from '../views/Question/Question.vue';
import QuestionFinish from '../views/Question/QuestionFinish.vue';
import Account from '../components/Setting/Mobile/Account.vue';
import AccountPhonenumber from '../components/Setting/Mobile/AccountPhonenumber.vue';
import AccountChangePassword from '../components/Setting/Mobile/AccountChangePassword.vue';
// import AccountRedeem from '../components/Setting/Mobile/AccountRedeem.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/home/:token',
    name: 'BerandaAuth',
    component: Home,
  },
  {
    path: '/home',
    name: 'Beranda',
    component: Home,
  },
  {
    path: '/active-class',
    name: 'Kelas Aktif',
    component: ActiveClass,
  },
  {
    path: '/history-class',
    name: 'Riwayat Kelas',
    component: HistoryClass,
  },
  {
    path: '/certificate',
    name: 'Sertifikat',
    component: Certificate,
  },
  {
    path: '/assesment',
    name: 'Penilaian',
    component: Assesment,
  },
  {
    path: '/assesment/:id/detail/:class_id',
    name: 'Detail Nilai Pelatihan',
    component: DetailAssesment,
  },
  {
    path: '/class/:id/detail',
    name: 'Detail Kelas',
    component: DetailClass,
  },
  {
    path: '/class/:id/detail/:session',
    name: 'Detail Kelas Prakerja',
    component: DetailClassPrakerja,
  },
  {
    path: '/class/:id/session',
    name: 'Sesi Kelas',
    component: SessionClass,
  },
  {
    path: '/class/:id/verification/:session',
    name: 'Verifikasi Kelas',
    component: Verification,
  },
  {
    path: '/class/:id/riwayat',
    name: 'Riwayat Kelas Aktif',
    component: HistoryActiveClass,
  },
  {
    path: '/account/user-profile',
    name: 'UserProfile',
    component: UserProfile,
  },
  {
    path: '/account/user-account',
    name: 'Account',
    component: Account,
  },
  {
    path: '/account/user-phonenumber',
    name: 'AccountPhonenumber',
    component: AccountPhonenumber,
  },
  {
    path: '/account/user-changepassword',
    name: 'AccountChangePassword',
    component: AccountChangePassword,
  },
  // {
  //   path: '/account/user-redeem-code',
  //   name: 'AccountRedeem',
  //   component: AccountRedeem,
  // },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: AboutUs,
  },
  {
    path: '/privacy-terms',
    name: 'PrivacyTerms',
    component: PrivacyTerms,
  },
  {
    path: '/term-condition',
    name: 'TermCondition',
    component: TermCondition,
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: Faq,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
  },
  {
    path: '/review',
    name: 'Review',
    component: Review,
  },
  {
    path: '/survey/:class_id',
    name: 'Reflective Journal',
    component: Survey,
  },
  {
    path: '/survey-done',
    name: 'Survey Success',
    component: SurveySuccess
  },
  {
    path: '/setting',
    name: 'Pengaturan',
    component: Setting,
  },
  {
    path: '/question/finish',
    name: 'FinishQuestion',
    component: QuestionFinish,
  },
  {
    path: '/verification/callback',
    name: 'VerificationCallback',
    component: VerificationCallback,
  },
  {
    path: '/question/:id',
    name: 'Question',
    component: Question,
  },
  { path: '*', component: PageNotFound },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
