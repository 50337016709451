<template>
    
<svg :width="width"
    :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 12H16" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M4 6H20" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M4 18H12" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
</svg>

</template>
<script>
export default {
    name: "IcGrow",
  props: {
    width: {
      type: Number,
      default: 20,
    },
    height: {
      type: Number,
      default: 20,
    },
    outline: {
      type: Boolean,
      default: false,
    }
  },
};
</script>