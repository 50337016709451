<template>
    <div>
        <div><img src="/img/other/sea-banner.png" width="100%" height="278px"></div>

        <div class="position-img" @click="goToPage('/setting')"><img src="/img/icons/ic_setting.png" width="24px" height="24px"></div>

        <div>
            <div class="bg-rounded d-flex">
                <div class="name-circle d-flex mr-5"><span>{{initialName}}</span></div>
                <h4>{{ user.gender }} {{name}}</h4>
            </div>
        </div>

        <div class="bg-top-rounded">
            <div class="d-flex">
                <span style="width:50%">No. Handphone</span>
                <span style="width:50%;text-align: right;">{{ user.phone_number }}</span>
            </div>
            <div class="d-flex mt-5">
                <span style="width:50%">Email</span>
                <span style="width:50%;text-align: right;">{{ email }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            name: '',
            split_name: '',
            email: '',
            user: {},
            initialName: ''
        }
    },
    methods: {
        goToPage(e) {
            window.location.href = e;
        },
        async getAccount() {
            await this.$http
                .get("/v1/account?email="+this.email)
                    .then((response) => {
                        this.user = response.data.data.user

                        const req = JSON.stringify({ 
                            "full_name": this.user.full_name,
                            "email": this.user.email,
                            "phone_number": this.user.phone_number,
                            "gender": this.user.gender,
                        })
                        localStorage.setItem("account", req)

                        this.name = this.user.full_name
                        var nameSplit= this.user.full_name.split(" ")
                        if (nameSplit.length >= 2) {
                            this.initialName = nameSplit[0][0] + '' + nameSplit[1][0]
                        } else {
                            this.initialName = nameSplit[0][0]
                        }
                })
            .catch((err) => console.error(err.response));
        },
    },
    mounted () {
        this.email = this.$decode_token["email"]
        this.getAccount()

    }
}
</script>

<style scoped>
.position-img {
    position: absolute;
    display: flex;
    margin-top: -110px;
    z-index: 5;
    background: white;
    padding: 5px;
    right: 19px;
    border-radius: 24px;
}
.name-circle {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    width: 48px;
    height:48px;
    border-radius:50%;
    background: linear-gradient(111.75deg, #179EFF -0.58%, #162CA2 100.82%);
}

.bg-top-rounded {
    position: relative;
    background: #fff;
    width: 100%;
    margin-top: -40px;
    padding: 80px 19px 19px 19px;
    border-radius: 24px 24px 0px 0px;
}

.bg-rounded {
  position: relative;
  background: #fff;
  border-radius: 24px;
  color: #36373f;
  text-align: left;
  padding: 10px 15px;
  margin-bottom: 10px;
  width: 90%;
  margin-top: -60px;
  box-shadow: 1px 1px 4px 2px rgba(13, 28, 113, 0.1);
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
}
</style>