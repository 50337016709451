<template>
    <div class="stars">
        <span @click="countedStar(1, idx+1)" v-for="(n, idx) in starCount" :key="`A-${idx}`" :class="`star-ctn ${isInput ? 'cursor-pointer': ''}`"><StarIcon :width="width" :height="height" :type="disabled ? 3 : 1" /></span>
        <span @click="countedStar(0, idx+1)" v-for="(n, idx) in minVal" :key="`B-${idx}`" :class="`star-ctn ${isInput ? 'cursor-pointer': ''}`"><StarIcon :width="width" :height="height" :type="isInput ? 2 : 3" v-if="starCount < 5" /></span>
    </div>
</template>
<script>
import StarIcon from '@/assets/icons/Star.vue'
export default {
    name: "StarTemplate",
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        isInput: {
            type: Boolean,
            default: false,
        },
        starCount: {
            type: Number,
            default: 5,
        },
        width: {
            type: Number,
            default: 25,
        },
        height: {
            type: Number,
            default: 25,
        },
    },
    components: {
        StarIcon
    },
    data() {
        return {
            minVal: 0,
        }
    },
    watch: {
        starCount() {
            const data = 5-this.starCount
            this.minVal = data
        }
    },
    methods: {
        countedStar(n, i) {
            if (!this.isInput) return
            let stars = this.starCount
            if (n) {
                stars -= Math.abs(stars - i)
                if (stars < 1) {
                    stars = 1
                }
            } else {
                stars += i
                if (stars > 5) {
                    stars = 5
                }
            }
            this.$emit("onChange", stars)
        }
    },

    mounted() {
        this.minVal = 5-this.starCount
    }
}
</script>

<style scoped>
.stars {
    display: flex;
    gap: 8px;
    align-items: center;
}
.star-ctn{
    display: flex; align-items: center;
}
.cursor-pointer {
    cursor: pointer;
}
</style>