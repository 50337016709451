<template>
    <div class="modal" @click.self="$emit('close')">
        <div class="hidden" :style="`width: ${width ? width : 'auto'};`" v-if="!cleared">
            <div class="close" @click="$emit('close')">
                <CloseX />
            </div>
        </div>
        <div :class="`modal-content ${!isOriginal ? 'mo-size' : ''}`" :style="`width: ${width ? width : 'auto'}; height: ${height ? height : 'auto'};`">
            <slot />
        </div>
    </div>
</template>

<script>
import CloseX from "@/assets/icons/CloseX.vue";

export default {
    components: {
        CloseX
    },
    name: "ModalComponent",
    props: {
        width: {
            type: String,
            default: ""
        },
        cleared: {
            type: Boolean,
            default: false
        },
        height: {
            type: String,
            default: ""
        },
        isOriginal: {
            type: Boolean,
            default: false,
        }
    }
}
</script>

<style scoped>
.modal {
    top: 0;
    left: 0;
    z-index: 10;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(12, 11, 11, 0.2);
    width: 100vw;
    height: 100vh;
}

.hidden {
    position: relative;
}

.modal-content {
    background-color: white;
    border-radius: 18px;
    padding: 25px;
    max-height: 90%;
    text-align: center;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}

.modal-content::-webkit-scrollbar {
    width: 8px;
	background-color: transparent;
    margin-top: 15px;
    margin-bottom: 15px;
}

/* Track */
.modal-content::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(53, 53, 53, 0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
    margin-top: 20px;
    margin-bottom: 20px;
}

/* Handle */
.modal-content::-webkit-scrollbar-thumb {
    border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(59, 59, 59, 0.3);
	background-color: #797878;
}


.close {
    width: 100%;
    height: 0;
    display: flex;
    justify-content: flex-end;
    background-color: red;
}

.close * {
    position: absolute;
    cursor: pointer;
    right: -50px;
    top: -2px;
}
@media only screen and (max-width: 990px) {
    .mo-size {
        width: 95% !important;
    }
    .modal-content {
        padding: 20px;
    }
    .modal { 
        flex-direction: column-reverse;
        align-items: center;
    }
    .hidden {
        top: 20px;
        margin-left: -50px;
    }
}
</style>