<template>
  <svg
    v-if="!outline"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 3.5C13.6811 3.5 15.3245 3.99852 16.7224 4.93251C18.1202 5.8665 19.2096 7.19402 19.853 8.74719C20.4963 10.3004 20.6647 12.0094 20.3367 13.6583C20.0087 15.3071 19.1992 16.8217 18.0104 18.0104C16.8217 19.1992 15.3071 20.0087 13.6583 20.3367C12.0094 20.6646 10.3004 20.4963 8.74719 19.853C7.19402 19.2096 5.8665 18.1202 4.93251 16.7223C3.99852 15.3245 3.5 13.6811 3.5 12C3.50265 9.74647 4.39903 7.586 5.99252 5.99252C7.58601 4.39903 9.74648 3.50265 12 3.5ZM12 2.5C10.1211 2.5 8.28435 3.05717 6.72209 4.10104C5.15982 5.14491 3.94218 6.62861 3.22315 8.36451C2.50412 10.1004 2.31598 12.0105 2.68254 13.8534C3.0491 15.6962 3.95389 17.3889 5.28249 18.7175C6.61109 20.0461 8.30383 20.9509 10.1466 21.3175C11.9895 21.684 13.8996 21.4959 15.6355 20.7769C17.3714 20.0578 18.8551 18.8402 19.899 17.2779C20.9428 15.7157 21.5 13.8789 21.5 12C21.5 9.48044 20.4991 7.06408 18.7175 5.28249C16.9359 3.50089 14.5196 2.5 12 2.5Z"
      fill="#575966"
    />
    <path
      d="M12.35 16.9997H11.93C11.7974 16.9997 11.6702 16.947 11.5765 16.8532C11.4827 16.7595 11.43 16.6323 11.43 16.4997V11.2697C11.4437 11.2358 11.4479 11.1988 11.4421 11.1627C11.4362 11.1266 11.4206 11.0928 11.3969 11.065C11.3732 11.0371 11.3423 11.0163 11.3076 11.0048C11.2729 10.9933 11.2357 10.9915 11.2 10.9997H10.54C10.31 10.9997 10.31 10.7497 10.31 10.7497V9.99969C10.3086 9.96386 10.3146 9.92812 10.3277 9.89473C10.3408 9.86133 10.3606 9.831 10.386 9.80564C10.4113 9.78029 10.4417 9.76045 10.475 9.74738C10.5084 9.73431 10.5442 9.72829 10.58 9.72969H12.35C12.4818 9.73225 12.6075 9.78575 12.7007 9.87897C12.794 9.97219 12.8475 10.0979 12.85 10.2297V16.4197C12.8617 16.4917 12.8575 16.5654 12.8376 16.6357C12.8178 16.7059 12.7828 16.7709 12.7352 16.8262C12.6875 16.8815 12.6283 16.9256 12.5618 16.9556C12.4953 16.9856 12.423 17.0006 12.35 16.9997Z"
      fill="#575966"
    />
    <path
      d="M12.93 7.85037C12.9309 7.96446 12.9083 8.07751 12.8635 8.18246C12.8188 8.28742 12.7529 8.38202 12.67 8.46037C12.5068 8.60317 12.2951 8.67819 12.0784 8.67006C11.8617 8.66194 11.6563 8.57129 11.5042 8.41667C11.3522 8.26205 11.2649 8.05516 11.2604 7.83834C11.2559 7.62152 11.3345 7.41118 11.48 7.25037C11.555 7.16929 11.6464 7.10508 11.7481 7.06198C11.8498 7.01889 11.9595 6.99788 12.07 7.00037C12.1835 6.99694 12.2965 7.01743 12.4015 7.0605C12.5066 7.10357 12.6015 7.16826 12.68 7.25037C12.7618 7.32692 12.8264 7.42002 12.8695 7.52347C12.9126 7.62691 12.9332 7.73835 12.93 7.85037Z"
      fill="#575966"
    />
  </svg>
  
<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
<path d="M21 4.375C17.7119 4.375 14.4976 5.35004 11.7637 7.17682C9.02969 9.0036 6.89882 11.6001 5.64051 14.6379C4.3822 17.6757 4.05297 21.0184 4.69445 24.2434C5.33593 27.4683 6.91931 30.4306 9.24436 32.7557C11.5694 35.0807 14.5317 36.6641 17.7566 37.3056C20.9816 37.947 24.3243 37.6178 27.3621 36.3595C30.3999 35.1012 32.9964 32.9703 34.8232 30.2364C36.65 27.5024 37.625 24.2881 37.625 21C37.625 16.5908 35.8734 12.3621 32.7557 9.24435C29.6379 6.12656 25.4092 4.375 21 4.375ZM21.6125 29.75H20.8775C20.6454 29.75 20.4229 29.6578 20.2588 29.4937C20.0947 29.3296 20.0025 29.1071 20.0025 28.875V19.7225C20.0265 19.6632 20.0338 19.5984 20.0236 19.5353C20.0134 19.4721 19.986 19.4129 19.9445 19.3642C19.903 19.3155 19.849 19.2791 19.7882 19.259C19.7275 19.2388 19.6624 19.2357 19.6 19.25H18.445C18.0425 19.25 18.0425 18.8125 18.0425 18.8125V17.5C18.04 17.4373 18.0506 17.3748 18.0735 17.3163C18.0963 17.2579 18.131 17.2048 18.1754 17.1604C18.2198 17.116 18.2729 17.0813 18.3313 17.0585C18.3898 17.0356 18.4523 17.025 18.515 17.0275H21.6125C21.8432 17.032 22.0631 17.1256 22.2263 17.2887C22.3894 17.4519 22.483 17.6718 22.4875 17.9025V28.735C22.508 28.8611 22.5006 28.9901 22.4658 29.113C22.4311 29.2359 22.3699 29.3497 22.2865 29.4464C22.2031 29.5431 22.0996 29.6204 21.9832 29.6729C21.8667 29.7253 21.7402 29.7516 21.6125 29.75ZM22.1725 14.875C21.8869 15.1249 21.5165 15.2562 21.1373 15.242C20.758 15.2278 20.3986 15.0691 20.1325 14.7985C19.8664 14.5279 19.7137 14.1659 19.7058 13.7865C19.6979 13.407 19.8354 13.0389 20.09 12.7575C20.2148 12.6029 20.3718 12.4773 20.5502 12.3897C20.7285 12.302 20.9238 12.2543 21.1225 12.25C21.3212 12.244 21.5189 12.2799 21.7028 12.3552C21.8867 12.4306 22.0527 12.5438 22.19 12.6875C22.3332 12.8215 22.4463 12.9844 22.5217 13.1654C22.5972 13.3465 22.6332 13.5415 22.6275 13.7375C22.6291 13.9372 22.5895 14.135 22.5112 14.3187C22.433 14.5023 22.3176 14.6679 22.1725 14.805V14.875Z" fill="#162CA2"/>
</svg>

</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 20,
    },
    height: {
      type: Number,
      default: 20,
    },
    outline: {
      type: Boolean,
      default: false,
    }
  },
};
</script>
