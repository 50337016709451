<template>
  <div>
    <Loading v-if="isLoading" />
    <div class="category-container" v-if="isCategory">
      <v-radio-group v-model="select">
        <v-radio
          v-for="(i, idx) in categories"
          :key="idx"
          :label="`${i.name}`"
          :value="i.value"
          @click="() => (select = i.value)"
        ></v-radio>
      </v-radio-group>
      <div class="mt-5">
        <Button
          name="TERAPKAN"
          width="100%"
          height="40px"
          @click="onFilterClass"
        />
      </div>
    </div>
    <div class="container-body-mobile pb-10">
      <h2>Hi, {{ full_name }}</h2>
      <span>Selamat Datang di LMS HiColleagues</span>
      <h4 class="mt-10 mb-5">Jadwal Kelasmu</h4>
      <div v-for="item in schedules" :key="item.class_id">
        <CardClass :data="item" :isTester="isTester"/>
      </div>
      <div class="button-filter" v-if="!isCategory">
        <Button
          name="KATEGORI"
          height="40px"
          width="80%"
          @click="() => (isCategory = true)"
          ><Grow
        /></Button>
      </div>
    </div>
  </div>
</template>

<script>
import Grow from "@/assets/icons/Grow.vue";
import Button from "../../others/Button.vue";
import Loading from "../../others/Loading.vue";
import CardClass from "../../others/CardClass.vue";

import getAuthentification from "@/utils/badrequest.js";
import VueJwtDecode from "vue-jwt-decode";

export default {
  data() {
    return {
      categories: [
        {name: "Semua", value: 0},
        {name: "Bootcamp", value: 5},
        {name: "Prakerja", value: 4},
        {name: "Public Training", value: 1},
      ],
      isTester: this.$decode_token["email"].search("tester") < 0 ? false : true,
      schedules_list: [],
      schedules: [],
      full_name: "",
      select: 0,
      isCategory: false,
      isLoading: false,
    };
  },
  components: {
    Button,
    Loading,
    Grow,
    CardClass
  },
  beforeMount() {
    if (this.$route.params.token != null) {
      localStorage.setItem("user", this.$route.params.token);
      this.$forceUpdate();
      let decoded = VueJwtDecode.decode(this.$route.params.token);
      this.$decode_token = decoded;
      window.location.href = "/home"
      // this.loginDashboard(this.$route.params.email, this.$route.params.token, this.$route.params.role)
    } else {
      getAuthentification();
    }

    var data = JSON.parse(localStorage.getItem("account"));
    if (data != null) {
      this.full_name = data["full_name"];
    } else {
      this.full_name = this.$decode_token["name"];
    }
  },
  methods: {
    onFilterClass() {
      if (!this.select) this.schedules = this.schedules_list;
      else
        this.schedules = this.schedules_list.filter(
          (item) => item.category === this.select
        );
      this.isCategory = false;
    },
    async getClass() {
      this.isLoading = true;
      this.schedules = [];
      this.schedules_list = [];
      await this.$http
        .get(
          "/v1/user-schedule?user_id=" +
            this.$decode_token["id"] +
            "&status=true"
        )
        .then(async (response) => {
          for (let i = 0; i < response.data.data.schedules.length; i++) {
            const item = response.data.data.schedules[i];
            this.schedules.push(item);
            this.schedules_list.push(item);
          }
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
   
    checkingParam(param) {
      if (!param) return;
      if (param === "a") {
        // authentication success
        if (this.$utils.getSession(this.$utils.getClass()) == 999) {
          this.$router.push(`/class/${this.$utils.getClass()}/session`);
        } else {
          this.$router.push(`/class/${this.$utils.getClass()}/detail/${this.$utils.getSession(this.$utils.getClass())}`);
        }
      } else if (param === "b") {
        // authentication error
        this.$router.push(
          `/class/${this.$utils.getClass()}/verification/${this.$utils.getSession(
            this.$utils.getClass()
          )}`
        );
      } else {
        // authentication cancel
        this.$utils.removeSessionCodePrakerja();
      }
    },
  },
  mounted() {
    this.getClass();
    this.checkingParam(this.$route.query.feedback);
  },
};
</script>

<style scoped>

</style>
