<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.5"
      d="M25.0002 47.9154C37.6567 47.9154 47.9168 37.6552 47.9168 24.9987C47.9168 12.3422 37.6567 2.08203 25.0002 2.08203C12.3436 2.08203 2.0835 12.3422 2.0835 24.9987C2.0835 37.6552 12.3436 47.9154 25.0002 47.9154Z"
      fill="#FFFFFF"
    />
    <path
      d="M36.4171 15.7916L15.7921 36.4166C15.649 36.5701 15.4765 36.6932 15.2849 36.7786C15.0932 36.864 14.8863 36.9099 14.6765 36.9136C14.4667 36.9173 14.2583 36.8788 14.0637 36.8002C13.8692 36.7216 13.6924 36.6046 13.5441 36.4562C13.3957 36.3079 13.2787 36.1311 13.2001 35.9366C13.1216 35.742 13.083 35.5336 13.0867 35.3238C13.0904 35.114 13.1363 34.9071 13.2217 34.7155C13.3071 34.5238 13.4302 34.3513 13.5837 34.2082L34.2087 13.5832C34.3518 13.4297 34.5243 13.3066 34.7159 13.2212C34.9076 13.1358 35.1145 13.0899 35.3243 13.0862C35.5341 13.0825 35.7425 13.1211 35.9371 13.1997C36.1316 13.2782 36.3084 13.3952 36.4567 13.5436C36.6051 13.692 36.7221 13.8687 36.8007 14.0633C36.8792 14.2578 36.9178 14.4662 36.9141 14.676C36.9104 14.8858 36.8645 15.0927 36.7791 15.2844C36.6937 15.476 36.5706 15.6485 36.4171 15.7916Z"
      fill="#ACAFBF"
    />
    <path
      d="M36.4168 36.4168C36.1238 36.7094 35.7267 36.8737 35.3126 36.8737C34.8985 36.8737 34.5014 36.7094 34.2084 36.4168L13.5834 15.7918C13.3074 15.4956 13.1572 15.1038 13.1643 14.699C13.1714 14.2942 13.3354 13.908 13.6217 13.6217C13.908 13.3354 14.2942 13.1714 14.699 13.1643C15.1038 13.1572 15.4956 13.3074 15.7918 13.5834L36.4168 34.2084C36.7094 34.5014 36.8737 34.8985 36.8737 35.3126C36.8737 35.7267 36.7094 36.1238 36.4168 36.4168Z"
      fill="#ACAFBF"
    />
  </svg>
</template>

<script>
export default {
  name: "CloseX",
  props: {
    width: {
      type: Number,
      default: 40,
    },
    height: {
      type: Number,
      default: 40,
    },
  },
};
</script>
