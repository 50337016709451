<template>
    <div>
        <Verification />
    </div>
</template>
  
<script>
import Verification from '@/components/Verification/Verification.vue'
export default {
    name: "VerificationPage",
    components: {
        Verification
    },
}
</script>