<template>
    <div>
        <NavbarBack />
        <div class="bg-custom pt-5">
            <div class="bg-rounded d-flex " style="align-items: center;" @click="goToPage('/account/user-account')">
                <h4 style="width:100%">Akun</h4>
                <div><img src="/img/icons/ic_arrow_right.png" width="20px" height="20px"></div>
            </div>

            <div class="bg-rounded d-flex" style="align-items: center;" @click="goToPage('/account/user-phonenumber')">
                <h4 style="width:100%">No. Handphone</h4>
                <div><img src="/img/icons/ic_arrow_right.png" width="20px" height="20px"></div>
            </div>

            <div class="bg-rounded d-flex" style="align-items: center;" @click="goToPage('/account/user-changepassword')">
                <h4 style="width:100%">Ubah Kata Sandi</h4>
                <div><img src="/img/icons/ic_arrow_right.png" width="20px" height="20px"></div>
            </div>
            <!-- <div class="bg-rounded d-flex" style="align-items: center;" @click="goToPage('/account/user-redeem-code')">
                <h4 style="width:100%">List Kode Redeem</h4>
                <div><img src="/img/icons/ic_arrow_right.png" width="20px" height="20px"></div>
            </div> -->
        </div>
    </div>
</template>

<script>
import NavbarBack from "@/components/Navbar/Mobile/NavbarBack";

export default{
    components: {
        NavbarBack
    },
    methods: {
        goToPage(e) {
            this.$router.push(e).catch(()=>{});
        }
    }
}
</script>

<style>
.bg-rounded {
  background: #ffffff;
  border-radius: 25px;
  color: #36373f;
  text-align: left;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.bg-custom {
    background: #F5F5F5;
    min-height: 100vh;
    padding: 0px 19px;
}
</style>