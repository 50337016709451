<template>
    <div style="background: #F5F5F5;">
        <NavbarBack />

        <div class="d-flex" style="padding: 20px">
            <div><img :src="schedule.image_url" class="img-class"></div>
            <div style="margin-left:30px">
                <div class="d-flex button-light-blue" v-if="schedule.category === 4" style="align-items:center;justify-content:center"><img src="/img/other/ic_prakerja.png" width="69" height="22"></div>
                <h4 class="button-light-blue" style="width: 120px" v-if="schedule.category !== 4">Public Training</h4>
                <h2 class="title">{{schedule.name}}</h2>
            </div>
        </div>
        <div class="class-info mt-2">
            <div class="content" style="width: 60px">
                <div><img src="/img/icons/ic_level.png" width="20px" height="20px"></div>
                <span style="margin-left:10px">{{$utils.formatLevel(schedule.level)}}</span>
            </div>
            <div class="content">
                <img src="/img/icons/ic_date.png" width="20px" height="20px" />
                <span>{{ schedule.dates }}, {{ schedule.days }}</span>
            </div>
            <div class="content">
                <img src="/img/icons/ic_training_method.png" width="20px" height="20px" />
                <span style="margin-left:10px">{{ schedule.learning_method | formatMethode }}</span>
            </div>
            
        </div>
        <div class="container-radius" style="color: #36373F;" v-if="schedule.status">
           <div class="d-flex">
                <div>
                    <h2>Presensi Kelas</h2>
                    <span>Klik kehadiran pada saat 5 menit sebelum pelatihan dimulai dan setelah pelatihan berakhir</span>
                </div>
           </div>
           <!-- <div class="d-flex justify-end mt-4" style="align-items:center;cursor: pointer;">
                <span style="font-weight: 600;font-size: 13px;">LIHAT SEMUA PRESENSI KELAS</span>
                <img src="/img/icons/ic_arrow_right.png" width="20px" height="20px">
            </div> -->

           <div class="d-flex mt-8">
                <div style="width:30%;"></div>
                <div style="width:30%;text-align:right"><h4>Masuk</h4></div>
                <div style="width:30%;text-align:right"><h4>Keluar</h4></div>
           </div>

            <div class="bg-ghost-white mt-3">
                <div class="d-flex absent">
                    <div style="width:30%;"><span style="font-weight:600">{{date}}</span></div>
                    <div style="width:30%;text-align:right"><span>{{ attendance.start_date }}</span></div>
                    <div style="width:32%;text-align:right"><span>{{ attendance.end_date }}</span></div>
                </div>

                <v-btn depressed color="#162CA2" style="padding:0px 80px;width: 100%;" class="btn-radius d-flex mt-5" @click="addAttendance()">
                    <span class="text-capitalize font-weight-bold white--text" >HADIR</span>
                </v-btn>

            </div>
        </div>

       
        <div class="container-radius" v-if="(schedule.learning_method == 'Online' || schedule.learning_method == 'Daring') && schedule.status">
            <h2>Aktivitas Kelas</h2>
            <span >Pastikan HiColers memiliki akun aplikasi Zoom untuk mengikuti pelatihan ini</span>

            <div class="bg-ghost-white mt-3">
                <h2>Bergabung dalam Kelas</h2>
                <div class="d-flex mt-3">
                    <div class="d-flex" style="width:50%">
                        <div class="d-flex mr-2" style="align-items:center"><img src="/img/icons/ic_date.png" width="20px" height="20px"></div>
                        <div><span>{{schedule.dates}}</span></div>
                    </div>

                    <div class="d-flex" style="width:50%">
                        <div class="d-flex mr-2" style="align-items:center"><img src="/img/icons/ic_day.png" width="20px" height="20px"></div>
                        <div><span>{{schedule.hours}}</span></div>
                    </div>
                </div>

                <div class="d-flex mt-1" style="align-items:center">
                    <div class="d-flex mr-2" style="align-items:center"><img src="/img/icons/ic_clock.png" width="20px" height="20px"></div>
                    <div><span>{{schedule.days}}</span></div>
                </div>

                <v-btn depressed style="background: #F7F9FD;width: 100%;" class="btn-stoke-blue mt-5" @click="goToPage(schedule.learning_link)">
                    <span class="text-capitalize font-weight-bold">GABUNG</span>
                </v-btn>
            </div>
        </div>

        <div class="container-radius">
            <div>
                <h2>Materi Pelatihan</h2>
                <span>Akses materi sebagai modul pendukung dalam mengikuti pelatihan</span>
            </div>
            <v-btn depressed style="background: white;width: 100%;" class="btn-stoke-blue mt-5" @click="goToPage(schedule.material_link)">
                <span class="text-capitalize font-weight-bold">MATERI PELATIHAN</span>
            </v-btn>
        </div>

        <div class="container-radius" v-if="schedule.status">
            <div class="d-flex" style="align-items:center">
                <h2 style="width:100%">Soal</h2>
                <div style="cursor:pointer" @click="isAssesmentHide = !isAssesmentHide"><img :src="isAssesmentHide === true ? '/img/icons/ic_plus.png' : '/img/icons/ic_minus.png'" width="24px" height="24px"></div>
            </div>

            <div v-if="!isAssesmentHide">
                <span>Kerjakan semua tahapan dengan ketentuan di bawah ini :</span>
                <ul>
                    <li>Wajib mengerjakan soal PreTest sebelum kelas di mulai</li>
                    <li>Soal Quiz wajib dikerjakan ketika sesi pertama selesai dengan mendapatkan nilai minimum 80.</li>
                    <li>Soal Post Test wajib dikerjakan ketika sesi kedua selesai dan akan menjadi syarat untuk mendapatkan sertifikat</li>
                </ul>

                <div class="bg-ghost-white mt-5">
                    <div>
                        <h3>PreTest</h3>
                        <span>Tes kemampuan dasar sebelum mengikuti pelatihan</span>
                    </div>

                    <v-btn depressed style="background: none; width:100%" class="btn-stoke-blue mt-5" @click="goToCurrentPage('Pre Test', 1)">
                        <span class="text-capitalize font-weight-bold">KERJAKAN</span>
                    </v-btn>
                </div>

                <div class="bg-ghost-white" style="margin-top: 20px;">
                    <div class=" d-flex" style="align-items:center;margin-bottom: 20px;">
                        <div style="width:100%">
                            <h3>Quiz</h3> 
                            <span>Tes untuk mengukur kemampuan & kepahaman terhadap materi pelatihan</span>
                        </div>
                    </div>

                    <div v-for="i in schedule.quizes" :key="i.iteration" style="padding: 10px;">
                        <div class="d-flex" style="align-items:center">
                            <h4 style="width:100%">Quiz Sesi {{ i.iteration }}</h4>
                            <v-btn depressed style="background: none; width: 30%;" class="btn-stoke-blue" @click="goToCurrentPage('Quiz', i.iteration)" :disabled = i.disable>
                                <span class="text-capitalize font-weight-bold">KERJAKAN</span>
                            </v-btn>
                        </div>
                        <div class="long-line" ></div>
                    </div>
                </div>

                <div class="bg-ghost-white" style="align-items:center;margin-top: 20px;">
                    <div>
                        <h3>Post-Test</h3>
                        <span>Tes evaluasi penyerapan ilmu & pemahaman seluruh materi pelatihan</span>
                    </div>
                    <v-btn depressed style="background: none;width:100%" class="btn-stoke-blue mt-5" @click="goToCurrentPage('Post Test', 1)">
                        <span class="text-capitalize font-weight-bold">KERJAKAN</span>
                    </v-btn>
                </div>
            </div>
        </div>

        <div class="container-radius" v-if="schedule.status">
            <div>
                <h2>Tugas Unjuk Keterampilan</h2>
                <span>Unggah Tugas Unjuk Keterampilan untuk mendukung kelengkapan dalam pelatihan</span>
                <div class="mt-3">
                    <span>Skema penilaian unjuk keterampilan dapat <u @click="goToPage(schedule.rubrik_url)">dilihat disini</u></span>
                </div>
                <div class="mt-3">
                    <b >Instruksi Unjuk Keterampilan:</b>
                    <span v-html="schedule.instruction_final_project"></span>
                </div>
            </div>

            <v-btn depressed style="background: white;width:100%" class="btn-stoke-blue mt-5" @click="goToPage(schedule.final_project_link)">
                <span class="text-capitalize font-weight-bold">UNGGAH</span>
            </v-btn>
        </div>

        <div class="container-radius">
            <div class="d-flex" style="align-items:center">
                <div style="width:100%">
                    <h2 >Materi Silabus</h2>
                    <span>Apa saja yang HiColers pelajari dalam pelatihan ini</span>
                </div>
                <div style="cursor:pointer" @click="isMateriSilabus = !isMateriSilabus"><img :src="isMateriSilabus === true ? '/img/icons/ic_plus.png' : '/img/icons/ic_minus.png'" width="24px" height="24px"></div>
            </div>

            <div class="mt-5" v-if="!isMateriSilabus">
                <v-list style="background: white;">
                    <v-list-group
                        v-for="item in schedule.syllabuses"
                        :key="item.session"
                        no-action
                        active-class="bg-ghost-white"
                        class="bg-ghost-white"
                        style="margin-bottom: 10px;padding: 5px"
                    >
                        <template v-slot:activator>
                        <v-list-item-content class="font-weight-bold" style="margin-bottom;color: #36373f;">
                            <v-list-item-title class="custom-text-size" v-text="item.session"></v-list-item-title>
                        </v-list-item-content>
                        </template>

                        <v-list-item-content style="padding-left:15px;color: #36373f;">
                            <v-list-item-title class="custom-desc-text-size" v-html="item.description"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-group>
                </v-list>
            </div>
        </div>
    </div>
</template>

<script>
import NavbarBack from "@/components/Navbar/Mobile/NavbarBack";
import moment from "moment";

export default {
    components: {
        NavbarBack
    },
    data () {
        return {
            isAssesmentHide: true,
            isMateriSilabus: true,
            syllabuses: [
                {id: 1, session: 'Pertemuan 1', description: '<ul><li>Dasar dalam Desain</li></ul>'},
                {id: 2, session: 'Pertemuan 2', description: '<ul><li>Dasar dalam Desain</li></ul>'},
            ],
            attendance: {},
            schedule: {},
            assesment: {},
            userID: this.$decode_token["id"],
            isAssesmentAvailable: false,
            date: moment(new Date()).format("ddd, DD MMM YYYY")
        }
    },
    methods: {
        goToCurrentPage(e, g) {
            window.location.href = '/question/'+this.$route.params.id+'?c='+e+'&g='+g
        },
        async getClass() {
            await this.$http
                .get("/v1/user-schedule/detail?user_id="+this.userID+"&class_id="+this.$route.params.id)
                    .then((response) => {
                        this.schedule = response.data.data.schedule
                })
            .catch((err) => console.error(err.response));
        },
        async addAttendance() {
            await this.$http
                .post("/v1/attendance/student", {
                    'user_id': parseInt(this.userID),
                    'class_id': parseInt(this.$route.params.id)
                })
                .then(() => {
                    window.location.href = '/class/'+this.$route.params.id+'/detail';
                })
            .catch((err) => console.error(err.response))
        },
        async getAttendance() {
            await this.$http
                .get("/v1/user-attendance?user_id="+this.userID+"&class_id="+this.$route.params.id)
                .then((response) => {
                    this.attendance = response.data.data.attendance
                    if (this.attendance.start_date == "00:00") {
                        this.attendance.start_date = "-"
                    } else {
                        this.attendance.start_date = response.data.data.attendance.start_date + " WIB"
                    }

                    if (this.attendance.end_date == "00:00") {
                        this.attendance.end_date = "-"
                    } else {
                        this.attendance.end_date = response.data.data.attendance.end_date + " WIB"
                    }
                })
            .catch((err) => console.error(err.response))
        },
        async getAssesmentByUserClass() {
            await this.$http
                .get("/v1/user-assesment/detail?user_id="+this.userID+"&class_id="+this.$route.params.id)
                    .then((response) => {
                        this.assesment = response.data.data
                        if (response.data.data == "NO_DATA") {
                            this.isAssesmentAvailable = true
                        }
                })
            .catch((err) => console.error(err.response));
        },
        goToPage(e) {
            window.open(
                e,
                "_blank" // <- This is what makes it open in a new window.
            );
        },
    },
    mounted () {
        this.getClass()
        this.getAttendance()
        this.getAssesmentByUserClass()
    }
}
</script>

<style scoped>
 li {
    font-size: 12px;
 }

  .title {
    font-weight: bold;
    font-size: 15px !important;
    line-height: 20px !important;
    color: #36373F;
  }

  span {
    font-size: 12px;
  }

  .container-radius {
    margin-top: 35px;
    padding: 19px;
    background: #FFFFFF;
    border-radius: 24px;
  }  

  h2 {
    font-size: 17px;
  }

  h4 {
    font-size: 12px;
  }

  .absent {
    align-items: center;
    justify-content: center;
    color:#36373F;
  }
  .img-class {
    width: 70px;
    height: 70px;
    border-radius: 18px;
  }
  .container-radius {
    margin-top: 15px;
    text-align: justify;
    padding: 19px;
    background: #FFFFFF;
    border-radius: 24px;
  }
  .class-info {
    display: flex;
    justify-content: center;
    gap: 20px;
    width: 80%;
    margin: auto;
    margin-top: -90px;
  }
  .class-info .content {
    align-items:flex-start; gap: 8px;
    display: flex; width: 150px;
  }
</style>