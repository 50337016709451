<template>
    <div>
        <DesktopAssesment v-if="!isMobile" />
        <MobileAssesment v-else />
    </div>
</template>

<script>
import DesktopAssesment from '@/components/Assesment/Desktop/Assesment'
import MobileAssesment from '@/components/Assesment/Mobile/Assesment'
export default {
  components: {
    DesktopAssesment,
    MobileAssesment
  },
  data() {
    return{
      isMobile: false
    }
  },
  methods: {
      handleView() {
          this.isMobile = screen.width <= 990
      }
  },
  beforeMount() {
    this.handleView()
    // this.$gtag.pageview(this.$route)
  }
}
</script>


<!-- <template>
    <div class="pa-10">
        <h2>Penilaian</h2>

        <v-data-table
          :headers="headers"
          :items="assesments"
          class="elevation-1 mt-5"
          hide-default-footer
        />
    </div>
</template>

<script>
export default {
    data() {
        return {
            headers: [
                { text: 'Nama Kelas', value: 'class_name' },
                { text: 'Nilai Pre Test', value: 'pre_test_score', sortable: false},
                { text: 'Nilai Quiz', value: 'quiz_score', sortable: false},
                { text: 'Nilai Post Test', value: 'post_test_score', sortable: false},
                { text: 'Catatan', value: 'notes', sortable: false},
            ],
            assesments: [],
            user_id: 0,
        }
    },
    methods: {
        async getAssesments() {
            await this.$http
                .get("/v1/assesment/user/"+this.user_id)
                .then((response) => {
                    this.assesments = response.data.data.assesments
                })
            .catch((err) => console.error(err.response))
        },
    },
    mounted() {
        var data = JSON.parse(localStorage.getItem("user"))
        this.user_id = data.id
        this.getAssesments()
    }
}
</script> -->