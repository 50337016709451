<template>
    <div :class="!isMobile ? 'container-custom-body'  :'container-body-mobile'">
        <h2>Syarat & Ketentuan</h2>
        <div v-for="(item, idx) in data" :key="idx" class="priv-head">
            <h2 class="mb-2">{{ item.title }}</h2>
            <p v-html="item.description" style="text-align: justify;"></p>
        </div>
    </div>
</template>

<script>
export default {
    name: "TermCondition",
    data() {
        return {
            isMobile: false,
            data: []
        }
    },
    methods: {
        async fetchPrivacyPolicy() {
            try {
                const response = await this.$web_http.get("/v1/privacy-policy/syarat--ketentuan-lms");
                const responseData = response.data.data;
                const content = responseData.privacy_policy.content
                if (content) {
                    this.data = content;
                } else {
                    throw new Error("Invalid privacy policy content");
                }
            } catch (error) {
                console.error("Error fetching privacy policy:", error);
            }
        },
      handleView() {
          this.isMobile = screen.width <= 990
      }
    },
    beforeMount() {
        this.handleView()
        this.fetchPrivacyPolicy()
    }
}
</script>
<style scoped>
.priv-head {
    margin-bottom: 15px;
    font-family: 'Poppins', sans-serif !important;
}
</style>