import { render, staticRenderFns } from "./DetailClass.vue?vue&type=template&id=3e9aa892&scoped=true&"
import script from "./DetailClass.vue?vue&type=script&lang=js&"
export * from "./DetailClass.vue?vue&type=script&lang=js&"
import style0 from "./DetailClass.vue?vue&type=style&index=0&id=3e9aa892&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e9aa892",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VBreadcrumbs,VBtn,VList,VListGroup,VListItemContent,VListItemTitle})
