<template>
    <div class="conatiner-custom">
        <div class="card">
            <img src="/img/icons/Success Notification.png" alt="Success">
            <h2>Ujian Selesai</h2>
            <p>Yeay, HiColers telah selesai mengerjakan ujian. Cari tahu seluruh skor yang HiColers dapatkan?</p>
            <div class="btn-group">
                <v-btn depressed color="#162CA2" class="btn-radius btn" @click="goToPage('ASSESSMENT')">
                    <span class="text-capitalize font-weight-bold white--text">LIHAT RAPOR</span>
                </v-btn>
                <v-btn depressed class="btn-radius btn btn-outline"
                @click="goToPage('HISTORY')">
                    <span class="text-capitalize font-weight-bold white--text">KEMBALI KE KELAS</span>
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        goToPage(page) {
            if (page === 'ASSESSMENT') {
                window.location.href = `/assesment`;
            } else {
                let back_to = sessionStorage.getItem("bck_to")
                if (!back_to) {
                    window.location.href = "/active-class"
                    return    
                }
                back_to = back_to.split("-")
                window.location.href = `/class/${back_to[0]}/detail/${back_to[1]}?done=true`;
                sessionStorage.removeItem("bck_to")
            }
        }
    }
}
</script>


<style scoped>
.conatiner-custom {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: #F5F5F5;
}

.card {
    background-color: #fff;
    border-radius: 30px;
    max-width: 500px;
    box-shadow: 2px 2px 10px 3px rgba(13, 28, 113, 0.1) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    flex-direction: column;
}

.card img {
    margin-bottom: 20px;
}

.card p {
    text-align: center;
    margin-bottom: 20px;
}

.btn-group {
    display: flex;
    gap: 10px;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
}

.btn {
    width: 45%;
    margin: 0;
}

.btn-outline {
    border: 1px solid #ACAFBF;
}

.btn-outline span {
    color: #7B7E8C !important;
}
</style>
