<template>
    <div class="pa-10">
        <h2>User Profile</h2>

        <v-card class="mt-5 pa-5">
            <h3 class="mb-5">Password</h3>

            <v-text-field
                :append-icon="show_current_password ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show_current_password ? 'text' : 'password'"
                name="input-10-2"
                label="Password saat ini"
                hint="At least 5 characters"
                value=""
                class="input-group--focused"
                :rules="[rules.required, rules.min]"
                @click:append="show_current_password = !show_current_password"
                v-model="current_password"
                outlined
                dense>
            </v-text-field>

            <v-text-field
                :append-icon="show_new_password ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show_new_password ? 'text' : 'password'"
                name="input-10-2"
                label="Password baru"
                hint="At least 5 characters"
                value=""
                class="input-group--focused"
                :rules="[rules.required, rules.min]"
                @click:append="show_new_password = !show_new_password"
                v-model="new_password"
                outlined
                dense>
            </v-text-field>

            <v-text-field
                :append-icon="show_confirmation_password ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show_confirmation_password ? 'text' : 'password'"
                name="input-10-2"
                label="Password baru"
                hint="At least 5 characters"
                value=""
                class="input-group--focused"
                :rules="[rules.required, rules.min]"
                @click:append="show_confirmation_password = !show_confirmation_password"
                v-model="confirmation_password"
                outlined
                dense>
            </v-text-field>

            <v-btn color="#000080" class="mt-10 pa-5">
                <span class="text-capitalize white--text font-weight-bold" @click="changePassword">Simpan</span>
            </v-btn>
        </v-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            current_password: '',
            new_password: '',
            confirmation_password: '',
            show_current_password: false,
            show_new_password: false,
            show_confirmation_password: false,
            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 5 || 'Min 5 characters',
            },
        }
    },
    methods: {
        changePassword() {

        }
    }
}
</script>