<template>
  <div>
    <DesktopHistoryActiveClass v-if="!isMobile" />
    <MobileHistoryActiveClass v-else />
  </div>
</template>

<script>
import DesktopHistoryActiveClass from '@/components/ActiveClass/Desktop/HistoryActiveClass';
import MobileHistoryActiveClass from '@/components/ActiveClass/Mobile/HistoryActiveClass';
export default {
  components: {
    DesktopHistoryActiveClass,
    MobileHistoryActiveClass,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    handleView() {
      this.isMobile = screen.width <= 990;
    },
  },
  beforeMount() {
    this.handleView();
    // this.$gtag.pageview(this.$route)
  },
};
</script>
