<template>
  <div class="container-custom">
    <Loading v-if="isLoading" />
    <div class="list-scrumb">
      <div
        v-for="(item, idx) in menus"
        :key="idx"
        class="list-scrumb ml-4 cursor-pointer"
        @click="$router.push(item.href)"
      >
        <span>{{ item.text }}</span>
        <arrow strokeColor="#162CA2" direct="right" />
      </div>
      <span class="title-scrumb">{{
        this.$helpers.maxStringLength(session.name, 60)
      }}</span>
    </div>
    <div class="d-flex" style="margin-top: 20px; margin-left: 10px">
      <div style="display: flex; justify-content: center; align-items: center">
        <img
          :src="session.image_url ? session.image_url : '/img/logo-hc.png'"
          width="120px"
          height="120px"
          style="border-radius: 24px"
        />
      </div>
      <div style="margin-left: 25px">
        <div style="display: flex">
          <div
            class="d-flex button-light-blue"
            style="align-items: center; justify-content: center"
          >
            <img src="/img/other/ic_prakerja.png" width="69" height="22" />
          </div>
          <div
            class="d-flex button-light-blue"
            style="
              align-items: center;
              justify-content: center;
              margin-left: 10px;
            "
          >
            <span>Webinar</span>
          </div>
        </div>
        <span class="title" id="font-poppins">{{ session.name }}</span>
        <div style="display: flex; padding: 16px 0">
          <div>
            <div class="box-info">
              <img
                class="box-img"
                src="/img/icons/ic_level.png"
                width="30px"
                height="30px"
              />
              <span>{{ $utils.formatLevel(session.level) }}</span>
            </div>
          </div>
          <div>
            <div class="box-info">
              <img
                class="box-img"
                src="/img/icons/ic_date.png"
                width="30px"
                height="30px"
              />
              <span>{{ session.dates }}</span>
            </div>
          </div>

          <div>
            <div class="box-info">
              <img
                class="box-img"
                src="/img/icons/ic_training_method.png"
                width="30px"
                height="30px"
              />
              <span>{{ session.learning_method | formatMethode }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-radius">
      <div class="d-flex">
        <div style="width: 100%">
          <h2>Presensi Kelas</h2>
          <span>Riwayat kehadiran HiColers dalam pelatihan ini</span>
        </div>

        <v-btn
          depressed
          style="background: white"
          class="btn-stoke-blue"
          width="250px"
          height="40px"
          @click="goToPage()"
          disabled
        >
          <img
            class="box-img"
            src="/img/icons/ic_download.png"
            width="30px"
            height="30px"
          />

          <span class="text-capitalize font-weight-bold">UNDUH</span>
        </v-btn>
      </div>
    </div>

    <div class="container-radius">
      <div class="d-flex">
        <div style="width: 100%">
          <h2>Materi Pelatihan</h2>
          <span
            >Akses materi sebagai modul pendukung dalam mengikuti
            pelatihan</span
          >
        </div>

        <v-btn
          depressed
          style="background: white"
          width="250px"
          height="40px"
          class="btn-stoke-blue"
          @click="goToPage(session.material_link)"
        >
          <span class="text-capitalize font-weight-bold">MATERI PELATIHAN</span>
        </v-btn>
      </div>
    </div>

    <div class="container-radius">
      <div class="d-flex">
        <div style="width: 100%">
          <h2>Tugas Praktik Mandiri</h2>
          <span
            >Riwayat tes evaluasi untuk mengetahui tingkat penyerapan ilmu
            melalui tugas praktik</span
          >
        </div>
      </div>
      <div
        v-for="(item, idx) in session.list_tpm"
        :key="idx"
        class="bg-ghost-white d-flex"
        style="align-items: center; margin-top: 20px"
      >
        <div style="width: 100%">
          <h3>Pertemuan {{ idx + 1 }}</h3>
        </div>
        <v-btn
          depressed
          style="background: white"
          class="btn-stoke-blue justify-end"
          @click="goToPage(item.attachment_url)"
          :disabled="!item.attachment_url"
        >
          <span class="text-capitalize font-weight-bold">LIHAT</span>
        </v-btn>
      </div>
    </div>

    <div class="container-radius">
      <div class="d-flex">
        <div style="width: 100%">
          <h2>Tugas Unjuk Keterampilan</h2>
          <span
            >Riwayat Tugas Unjuk Keterampilan sebagai pendukung kelengkapan
            pelatihan</span
          >
        </div>

        <v-btn
          depressed
          style="background: white"
          class="btn-stoke-blue justify-end"
          @click="goToPage(finalUk.attachment_url)"
          :disabled="!finalUk.attachment_url"
        >
          <span class="text-capitalize font-weight-bold">LIHAT</span>
        </v-btn>
      </div>
    </div>

    <div class="container-radius" v-if="true">
      <div class="d-flex" style="align-items: center">
        <div style="width: 100%">
          <h2>Materi Silabus</h2>
          <span>Apa saja yang peserta pelajari dalam pelatihan ini</span>
        </div>
        <div style="cursor: pointer" @click="isSessionHide = !isSessionHide">
          <img
            :src="
              isSessionHide === true
                ? '/img/icons/ic_plus.png'
                : '/img/icons/ic_minus.png'
            "
            width="24px"
            height="24px"
          />
        </div>
      </div>

      <div v-if="!isSessionHide">
        <div
          v-for="(item, idx) in session.syllabuses"
          :key="idx"
          class="bg-ghost-white d-flex"
          style="align-items: center; margin-top: 20px"
        >
          <div style="width: 100%">
            <h3>{{ item.session }}</h3>
            <div v-html="item.description"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from "@/assets/icons/Arrow.vue";
import Loading from "@/components/others/Loading.vue";

export default {
  components: {
    Arrow,
    Loading,
  },
  name: "DSessionClass",
  data() {
    return {
      isSessionHide: false,
      menus: [
        {
          text: "Riwayat Kelas",
          href: "",
        },
      ],
      isLoading: false,
      classId: this.$route.params.id,
      userID: this.$decode_token["id"],
      finalUk: {attachment_url: ""},

      session: {
        id: 0,
        name: "",
        image_url: "",
        category: 0,
        learning_method: "",
        dates: "",
        days: "",
        hours: "",
        level: "",
        syllabuses: [],
        list_tpm: [],
      },
    };
  },
  methods: {
    async getDetailSchedule() {
      await this.$http
        .get(
          `/v1/user-schedule/detail?user_id=${this.$decode_token["id"]}&class_id=${this.$route.params.id}`
        )
        .then((response) => {
          const data = response.data.data.schedule;
          this.session = data;
          if (!this.session.list_tpm) {
            this.session.list_tpm = [];
          }
          const ukData = data.list_tpm.find((item) => item.iteration === 999)
          if (ukData) {
            this.finalUk = ukData;
            data.list_tpm.pop();
            this.session.list_tpm = data.list_tpm
          }
        })
        .catch((err) => console.error(err.response));
    },
    goToPage(link) {
      window.open(link);
    },
  },
  mounted() {
    this.getDetailSchedule();
  },
};
</script>

<style scoped>
.list-scrumb {
  display: flex;
  align-items: center;
  gap: 15px;
  font-weight: bold;
  color: #162ca2;
  font-size: 18px;
}
.title-scrumb {
  color: #7b7e8c;
  font-weight: 400;
}
.opt-session {
  cursor: pointer;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
  color: #7b7e8c;
}
.opacity-1 {
  opacity: 0.4;
}
</style>
