<template>
    <div class="bg">
        <div class="mt-5" @click="goToPreviousPage()">
            <img src="/img/icons/ic_back.png" width="24px" height="24px">
        </div>

        <h3 class="mt-5">Ubah Kata Sandi</h3>
        <span>Atur Kata Sandi untuk keamanan akun HiColers</span>

        <h3 class="mt-10">Kata Sandi Saat Ini</h3>
        <v-text-field 
            :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show_password ? 'text' : 'password'"
            label="Masukkan Kata Sandi Saat ini" 
            filled
            clearable
            class="custom-text-field"
            placeholder="Password"
            :rules="[rules.min]"
            @click:append="show_password = !show_password"
            v-model="old_password"
            required>
        </v-text-field>

        <div class="long-line" style="width: 840px !important;height:2px;margin-bottom: 30px;"></div>

        <h3>Kata Sandi Baru</h3>
        <v-text-field 
            :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show_password ? 'text' : 'password'"
            label="Masukkan Kata Sandi Baru" 
            filled
            clearable
            class="custom-text-field"
            placeholder="Password"
            :rules="[rules.min]"
            @click:append="show_password = !show_password"
            v-model="new_password"
            required>
        </v-text-field>

        <v-btn depressed color="#162CA2" style="width: 100%;" class="btn-radius d-flex" @click="updateCurrentPasssword()">
            <span class="text-capitalize font-weight-bold white--text" >SIMPAN</span>
        </v-btn>
    </div>
</template>

<script>
export default {
    data () {
        return {
            old_password: '',
            new_password: '',
            show_password: false,
            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 5 || 'Min 5 characters',
            },
            data: JSON.parse(localStorage.getItem("account"))
        }
    },
    methods: {
        goToPreviousPage() {
            history.back()
        },
        async updateCurrentPasssword() {
            await this.$http
                .put("/v1/account/current-password", {
                    'old_password': this.old_password,
                    'new_password': this.new_password,
                    'email': this.data["email"]
                })
                .then(() => {
                    window.location.href = '/setting';
                })
            .catch((err) => console.error(err))
        },
    }
}
</script>


<style scoped>
.bg {
    padding: 0px 19px;
    min-height: 100vh;
}

.custom-text-field >>> .v-input__control > .v-input__slot:before {
    border-color: inherit;
    border-style: none !important;
    border-width: thin 0 0 0;
}

.custom-text-field >>> .v-input__control > .v-input__slot:after {
    border-color: inherit;
    border-style: none !important;
    border-width: thin 0 0 0;
}

.custom-text-field >>> .v-input__control > .v-input__slot{
    border-radius: 16px;
    background: #F7F9FD;
    width: 100%;
    box-shadow: none !important;
    margin-top: 10px;
}
</style>