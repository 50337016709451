<template>
  <div class="container-custom">
    <Loading v-if="isLoading" />
    <h2>Hi, {{ name }}</h2>
    <span>Selamat Datang di LMS HiColleagues</span>
    <div
      class="d-flex"
      style="margin-top: 20px; margin-bottom: 40px; align-items: center"
    >
      <div class="justify-start" style="width: 100%">
        <h4>Jadwal Kelasmu</h4>
      </div>
      <div class="d-flex justify-end" style="align-items: center">
        <span>Kategori</span>
        <div style="width: 250px">
          <v-select
            v-model="category"
            :items="categories"
            item-text="name"
            item-value="value"
            dense
            outlined
            hide-details
            class="ma-2"
          ></v-select>
        </div>
      </div>
    </div>

    <div v-for="item in schedules" :key="item.class_id">
      <CardClass :data="item" :isTester="isTester" />
    </div>
  </div>
</template>

<script>
import getAuthentification from "@/utils/badrequest.js";
import VueJwtDecode from "vue-jwt-decode";
import CardClass from "../../others/CardClass.vue";

import Loading from "../../others/Loading.vue";

export default {
  data() {
    return {
      categories: [
        {name: "Semua", value: 0},
        {name: "Bootcamp", value: 5},
        {name: "Prakerja", value: 4},
        {name: "Public Training", value: 1},
      ],
      isTester: this.$decode_token["email"].search("tester") < 0 ? false : true,
      schedules: [],
      schedules_list: [],
      name: "",
      category: 0,
      isLoading: false,
    };
  },
  components: {
    Loading,
    CardClass
  },
  watch: {
    category(v) {
      if (!v) this.schedules = this.schedules_list;
      else
        this.schedules = this.schedules_list.filter(
          (item) => item.category === v
        );
    },
  },
  beforeMount() {
    if (this.$route.params.token != null) {
      localStorage.setItem("user", this.$route.params.token);
      this.$forceUpdate();
      let decoded = VueJwtDecode.decode(this.$route.params.token);
      this.$decode_token = decoded;
      window.location.href = "/home"
      // this.loginDashboard(this.$route.params.email, this.$route.params.token, this.$route.params.role)
    } else {
      getAuthentification();
    }
    var data = JSON.parse(localStorage.getItem("account"));
    if (data != null) {
      this.name = data["full_name"];
    } else {
      this.name = this.$decode_token["name"];
    }
  },
  methods: {
    async getClass() {
      this.isLoading = true;
      this.schedules = [];
      this.schedules_list = [];
      await this.$http
        .get(
          "/v1/user-schedule?user_id=" +
            this.$decode_token["id"] +
            "&status=true"
        )
        .then(async (response) => {
          for (let i = 0; i < response.data.data.schedules.length; i++) {
            const item = response.data.data.schedules[i];
            this.schedules.push(item);
            this.schedules_list.push(item);
          }
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    checkingParam(param) {
      if (!param) return;
      if (param === "a") {
        // authentication success
        if (this.$utils.getSession(this.$utils.getClass()) == 999) {
          this.$router.push(`/class/${this.$utils.getClass()}/session`);
        } else {
          this.$router.push(`/class/${this.$utils.getClass()}/detail/${this.$utils.getSession(this.$utils.getClass())}`);
        }
      } else if (param === "b") {
        // authentication error
        this.$router.push(
          `/class/${this.$utils.getClass()}/verification/${this.$utils.getSession(
            this.$utils.getClass()
          )}`
        );
      } else {
        // authentication cancel
        this.$utils.removeSessionCodePrakerja();
      }
    },
  },
  mounted() {
    this.getClass();
    this.checkingParam(this.$route.query.feedback);
  },
};
</script>
