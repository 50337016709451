<template>
  <div class="container-custom-body">
    <div
      class="d-flex justify-end"
      style="margin-bottom: 40px; align-items: center"
    >
      <span>Kategori</span>
      <div style="width: 250px">
          <v-select
            v-model="category"
            :items="categories"
            item-text="name"
            item-value="value"
            dense
            outlined
            hide-details
            class="ma-2"
          ></v-select>
        </div>
    </div>
    <div
      v-for="item in classes"
      :key="item.class_id"
    >
     <CardClass :data="item" :isTamat="true" /> 
    </div>
  </div>
</template>

<script>
import CardClass from '../../others/CardClass.vue';
export default {
  data() {
    return {
      categories: [
        {name: "Semua", value: 0},
        {name: "Bootcamp", value: 5},
        {name: "Prakerja", value: 4},
        {name: "Public Training", value: 1}
      ],
      category: 0,
      classes: [],
      classes_list: [],
    };
  },
  components:{
    CardClass
  },
  watch: {
      category(v) {
        if (!v) this.classes = this.classes_list 
        else this.classes = this.classes_list.filter((item) => item.category === v)
      }
    },
  methods: {
    async getClass() {
      this.classes = [];
      this.classes_list = [];
      await this.$http
        .get(
          "/v1/user-schedule?user_id=" +
            this.$decode_token["id"] +
            "&status=false"
        )
        .then(async (response) => {
          for (let i = 0; i < response.data.data.schedules.length; i++) {
            const item = response.data.data.schedules[i];
            this.classes.push({...item});
            this.classes_list.push({...item});
          }
        })
        .catch((err) => console.error(err.response));
    },
  },
  mounted() {
    this.getClass();
  },
};
</script>

<style scoped>
.history-class-card {
  width: 460px;
  height: 150px;
  background: #ffffff;
  border-radius: 24px;
  padding: 20px 30px;
  margin-right: 5px;
}

.info-class {
  margin-left: 20px;
}

.title {
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  color: #36373f;
}
</style>
