<template>
    <div class="container-custom-body">
        <!-- <div class="justify-start" style="width:100%">
                <h3>Syarat untuk medapatkan Sertifikat</h3>
                <p>Peserta wajib menyelesaikan seluruh rangkaian pelatihan baik dari mengikuti pelatihan webinar, mengerjakan pre test, quiz, post test, dan unjuk keterampilan</p>
                <p></p>
                <br>
                <h3>Cara mendapatkan Sertifikan</h3>
                <p>Kamu dapat mengunduh sertifikat dengan tap gambar pada sertifikat yang dipilih></p>
            </div> -->
       
        <div class="d-flex mb-5" style="justify-content: space-between; align-items: flex-start;">
            <div>
            <h2>Syarat Mendapatkan Sertifikat</h2>
                <p>Peserta wajib menyelesaikan seluruh rangkaian pelatihan baik dari mengikuti pelatihan Webinar, mengerjakan Evaluasi (<b>Pre-Test</b>, <b>Quiz</b>, <b>Post-Test</b>, <b>Tes Praktek Mandiri</b> & <b>Tes Uji Keterampilan</b>)</p>
                <p>Sertifikat akan diterbitkan maximal <b>7x24 jam</b></p>
            <span>Kamu dapat mengunduh sertifikat dengan klik gambar atau klik Lihat Detail pada sertifikat yang dipilih</span>
        </div>
            <div class="d-flex justify-end" style="align-items: center;">
                <span>Kategori</span>
                <div style="width:250px">
                    <v-select
                        v-model="category"
                        :items="categories"
                        item-text="name"
          item-value="value"
                        dense
                        outlined
                        hide-details
                        class="ma-2"
                    ></v-select>
                </div>
            </div>
        </div>
        
        <div class="certificates">
            <div class="cardss" v-for="(n, idx) in certificates" :key="idx" @click="goToLink(n.file_url)">
                <div class="d-flex button-light-blue label-category" v-if="n.category === 4" style="align-items:center;justify-content:center"><img src="/img/other/ic_prakerja.png" width="69" height="22"></div>
                        <div class="button-light-blue label-category" v-if="n.category !== 4"><span>Public Training</span></div>
                        <img src="/img/prakerja/default_certificate.png" class="ctn">
                        <div class="title"><span>{{ n.name.length > 50 ? n.name.substring(0,50)+".." : n.name }}</span></div>
            </div>
        </div>
    </div>
</template>

<script>

export default {

    data () {
        return {
            categories: [
                {name: "Semua", value: 0},
                {name: "Bootcamp", value: 5},
                {name: "Prakerja", value: 4},
                {name: "Public Training", value: 1}
            ],
            certificates_list: [],
            certificates: [],
            category: 0
        }
    },
    watch: {
    category(v) {
      if (!v) this.certificates = this.certificates_list 
      else this.certificates = this.certificates_list.filter((item) => item.category === v)
    }
  },
    methods: {
        async getCertificates() {
            await this.$http
                .get("/v1/user-certificate?user_id="+ this.$decode_token["id"])
                .then((response) => {
                    this.certificates = response.data.data.certificates
                    this.certificates_list = response.data.data.certificates
                })
            .catch((err) => console.error(err.response))
        },
        goToLink(e) {
            window.open(
                e,
                '_blank' // <- This is what makes it open in a new window.
            )
        },
    },
    mounted() {
        // var data = JSON.parse(localStorage.getItem("user"))
        // this.user_id = data.id
        this.getCertificates()
    }
}
</script>

<style scoped>
.certificate-card {
    border-radius:10px;
    cursor:pointer;
    width: 500px;
    height: 380px;
}
.notification-info {
    padding: 20px 30px;
    background-color: #F1F6FF;
    display: flex;
    align-items: flex-start;
    gap: 30px;
}

.title {
    font-weight: 700;
    font-size: 22px;
    line-height: 22px;
    margin: 25px 20px;    
    color: #36373F;
}

.label-category {
    position: absolute;
    right: 20px;
    top: 20px;
}
.certificates {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
}
.certificates .cardss {
    box-shadow: 2px 2px 10px 3px rgba(13, 28, 113, 0.1) !important;
    border-radius: 24px;
    cursor: pointer;
    padding: 0px !important;
    position: relative;
    background-color: white;
}
.certificates .ctn {
    width: 100%;
    border-radius: 14px 14px 0 0 ;
    height: 200px;
    object-fit: cover;
}
@media only screen and (max-width: 990px) {
    .certificates { 
        grid-template-columns: repeat(2, 1fr);
    }
}
</style>