<template>
    <div>
        <DesktopNavbar v-if="!isMobile && !hideNavbarDesktop"/>
        <MobileNavbar v-if="isMobile" />
    </div>
</template>

<script>
import DesktopNavbar from '@/components/Navbar/Desktop/Navbar'
import MobileNavbar from '@/components/Navbar/Mobile/Navbar'
export default {
  components: {
    DesktopNavbar,
    MobileNavbar
  },
  data() {
    return{
      isMobile: false,
      hideNavbarDesktop: false,
    }
  },
  methods: {
      handleView() {
          this.isMobile = screen.width <= 990
      }
  },
  mounted() {
    this.handleView()
    // this.$gtag.pageview(this.$route)
    
    const currentPath = this.$route.path;
    const regexsHide = [
      /^\/question\/\d+$/,
      /^\/class\/\d+\/verification$/,
    ];

    this.hideNavbarDesktop = regexsHide.some((regex) => regex.test(currentPath));
  }
}
</script>