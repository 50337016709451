<template>
  <svg
    v-if="type === 1"
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.8931 19.0935C23.5478 19.4282 23.3891 19.9122 23.4678 20.3868L24.6531 26.9468C24.7531 27.5028 24.5185 28.0655 24.0531 28.3868C23.5971 28.7202 22.9905 28.7602 22.4931 28.4935L16.5878 25.4135C16.3825 25.3042 16.1545 25.2455 15.9211 25.2388H15.5598C15.4345 25.2575 15.3118 25.2975 15.1998 25.3588L9.29314 28.4535C9.00114 28.6002 8.67047 28.6522 8.34647 28.6002C7.55714 28.4508 7.03047 27.6988 7.1598 26.9055L8.34647 20.3455C8.42514 19.8668 8.26647 19.3802 7.92114 19.0402L3.10647 14.3735C2.7038 13.9828 2.5638 13.3962 2.7478 12.8668C2.92647 12.3388 3.38247 11.9535 3.93314 11.8668L10.5598 10.9055C11.0638 10.8535 11.5065 10.5468 11.7331 10.0935L14.6531 4.10683C14.7225 3.9735 14.8118 3.85083 14.9198 3.74683L15.0398 3.6535C15.1025 3.58416 15.1745 3.52683 15.2545 3.48016L15.3998 3.42683L15.6265 3.3335H16.1878C16.6891 3.3855 17.1305 3.6855 17.3611 4.1335L20.3198 10.0935C20.5331 10.5295 20.9478 10.8322 21.4265 10.9055L28.0531 11.8668C28.6131 11.9468 29.0811 12.3335 29.2665 12.8668C29.4411 13.4015 29.2905 13.9882 28.8798 14.3735L23.8931 19.0935Z"
      fill="#E5C750"
    />
  </svg>

  <svg
    v-else-if="type === 2"
    :width="width"
    :height="height"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M38.2207 12.1829L43.5507 22.8309C44.0731 23.8762 45.0814 24.6011 46.2506 24.7688L58.1739 26.485C61.1198 26.9104 62.2921 30.4807 60.1601 32.5264L51.538 40.8112C50.6907 41.6259 50.305 42.797 50.5054 43.9472L52.5402 55.6435C53.0414 58.5369 49.9618 60.7444 47.3287 59.3756L36.6719 53.8494C35.6271 53.3073 34.3759 53.3073 33.3281 53.8494L22.6713 59.3756C20.0382 60.7444 16.9586 58.5369 17.4628 55.6435L19.4946 43.9472C19.695 42.797 19.3093 41.6259 18.462 40.8112L9.8399 32.5264C7.70792 30.4807 8.88021 26.9104 11.8261 26.485L23.7494 24.7688C24.9186 24.6011 25.93 23.8762 26.4523 22.8309L31.7793 12.1829C33.0973 9.55005 36.9027 9.55005 38.2207 12.1829Z"
      stroke="#ACAFBF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <svg
    v-else-if="type === 3"
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.8931 19.0935C23.5478 19.4282 23.3891 19.9122 23.4678 20.3868L24.6531 26.9468C24.7531 27.5028 24.5185 28.0655 24.0531 28.3868C23.5971 28.7202 22.9905 28.7602 22.4931 28.4935L16.5878 25.4135C16.3825 25.3042 16.1545 25.2455 15.9211 25.2388H15.5598C15.4345 25.2575 15.3118 25.2975 15.1998 25.3588L9.29314 28.4535C9.00114 28.6002 8.67047 28.6522 8.34647 28.6002C7.55714 28.4508 7.03047 27.6988 7.1598 26.9055L8.34647 20.3455C8.42514 19.8668 8.26647 19.3802 7.92114 19.0402L3.10647 14.3735C2.7038 13.9828 2.5638 13.3962 2.7478 12.8668C2.92647 12.3388 3.38247 11.9535 3.93314 11.8668L10.5598 10.9055C11.0638 10.8535 11.5065 10.5468 11.7331 10.0935L14.6531 4.10683C14.7225 3.9735 14.8118 3.85083 14.9198 3.74683L15.0398 3.6535C15.1025 3.58416 15.1745 3.52683 15.2545 3.48016L15.3998 3.42683L15.6265 3.3335H16.1878C16.6891 3.3855 17.1305 3.6855 17.3611 4.1335L20.3198 10.0935C20.5331 10.5295 20.9478 10.8322 21.4265 10.9055L28.0531 11.8668C28.6131 11.9468 29.0811 12.3335 29.2665 12.8668C29.4411 13.4015 29.2905 13.9882 28.8798 14.3735L23.8931 19.0935Z"
      fill="#ACAFBF"
    />
  </svg>
</template>
<script>
export default {
  name: "star-icon",
  props: {
    width: {
      type: Number,
      default: 32,
    },
    height: {
      type: Number,
      default: 32,
    },
    type: {
      type: Number,
      default: 1,
    },
  },
};
</script>
