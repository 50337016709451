<template>
    <div>
        <VerificationCallback />
    </div>
</template>
  
<script>
import VerificationCallback from '@/components/Verification/Callback.vue'
export default {
    name: "VerificationCallbackPage",
    components: {
        VerificationCallback
    },
}
</script>