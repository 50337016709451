import axios from 'axios';

// setup api

//prod
var crmAPI = axios.create({
    // baseURL: "http://localhost:5001",
    baseURL: '//apicrm.hicolleagues.com'
});

var lmsAPI = axios.create({
  // baseURL: 'http://localhost:5002',
  baseURL: '//apilms.hicolleagues.com'
});

var webAPI = axios.create({
    // baseURL: "http://localhost:5000",
    baseURL: '//api.hicolleagues.com'
});


// handle request

webAPI.interceptors.request.use((config) => {
  const token = localStorage.getItem('user');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

lmsAPI.interceptors.request.use((config) => {
  const token = localStorage.getItem('user');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

crmAPI.interceptors.request.use((config) => {
  const token = localStorage.getItem('user');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

// handle response

webAPI.interceptors.response.use(
  (config) => {
    return config;
  },
  (err) => {
    if (err.response.status === 401) {
      window.location = 'https://hicolleagues.com/login';
      localStorage.clear();
      sessionStorage.clear();
    }
    return Promise.reject(err);
  }
);

lmsAPI.interceptors.response.use(
  (config) => {
    return config;
  },
  (err) => {
    if (err.response.status === 401) {
      window.location = 'https://hicolleagues.com/login';
      localStorage.clear();
      sessionStorage.clear();
    }
    return Promise.reject(err);
  }
);

crmAPI.interceptors.response.use(
  (config) => {
    return config;
  },
  (err) => {
    if (err.response.status === 401) {
      window.location = 'https://hicolleagues.com/login';
      localStorage.clear();
      sessionStorage.clear();
    }
    return Promise.reject(err);
  }
);

export { webAPI, crmAPI, lmsAPI };