<template>
    <v-dialog v-model="isShown" max-width="650px" >
        <template v-slot:activator="{ on }" >
            <div v-on="on">
                <div style="margin-left:5px;cursor: pointer;margin-top: 2px;"><img src="/img/icons/ic_info.png" width="20px" height="20px"></div>
            </div>
        </template>

        <v-card class="card-info">
            <v-card-actions>
                <v-spacer></v-spacer>
                <img src="/img/icons/ic_close.png" @click="isShown = !isShown" width="20px" height="20px" style="cursor:pointer">
            </v-card-actions>

            <v-card-text style="color:#36373F">
                <h3 style="margin-top:25px">Pre-Test</h3>
                <span>Tes kemampuan dasar sebelum mengikuti pelatihan</span>

                <h3 style="margin-top:25px">Quiz</h3>
                <span>Tes kemampuan & kepahaman terhadap materi pelatihan</span>


                <h3 style="margin-top:25px">Post Test</h3>
                <span>Tes evaluasi penyerapan ilmu & pemahaman seluruh materi pelatihan</span>


                <h3 style="margin-top:25px">Unjuk Keterampilan</h3>
                <span>Evaluasi performa karya melalui Unjuk Keterampilan sebagai pendukung kompetensi pelatihan</span>

                <div class="line"></div>

                <h3>Keterangan Warna pada Nilai</h3>
                <div class="d-flex" style="margin-top:20px;">
                    <div><div class="circle mt-1" style="background: #01A368;"></div></div>
                    <div><span>Mengindikasikan nilai Baik, Rentan nilai 80-100</span></div>
                </div>

                <div class="d-flex" style="margin-top:20px">
                    <div><div class="circle mt-1" style="background: #E5AF0B;"></div></div>
                    <span>Mengindikasikan nilai Cukup Baik, Rentan nilai 70-79</span>
                </div>

                <div class="d-flex" style="margin-top:20px;">
                    <div><div class="circle mt-1" style="background: #B80F0A;"></div></div>
                    <span>Merah, mengindikasikan nilai Kurang Baik, Rentan nilai di bawah 69</span>
                </div>
            </v-card-text>
        </v-card>

    </v-dialog>
</template>

<script>

export default{
    data () {
        return {
            isShown : false,
        }
    }
}
</script>

<style scoped>
.card-info{
    background: #FFFFFF;
    border-radius: 30px !important;
    padding: 15px 0px;
}

.line {
    width: 100%;
    height: 1px;
    background: #D3D6E1;
    margin: 25px 0px;
}

.circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 12px;
}

span {
    font-size: 13px;
}

</style>