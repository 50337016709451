<template>
  <div class="template-mo-1">
    <NavbarBack />
    <input
      type="file"
      ref="file"
      style="display: none"
      @input="(e) => uploadTask(e)"
      :accept="$utils.applyFormatFile()"
    />
    <Loading v-if="isLoading" />
    <Popup
      v-if="isAlert"
      isError
      :message="messageError"
      @close="() => (isAlert = false)"
    />
    <Modal
      v-if="isModalInfoSesi"
      @close="() => (isModalInfoSesi = false)"
    >
      <h2 class="modal-title" style="text-align: left">
        Info tentang Sesi Kelas Pelatihan
      </h2>

      <div class="modal-body mt-5" style="text-align: left">
        <ol>
          <li>Seluruh sesi pelatihan <b>tersedia sesuai jadwal</b> yang telah ditentukan. Sesi pertama akan aktif sesuai jadwal dan berstatus “Terkunci”.</li>
          <li>Peserta dapat membuka sesi yang berstatus “Terkunci” dengan melakukan <b>input Kode Redeem & menerapkan Pengecekan Wajah (Face Recognition).</b></li>
          <li>Sesi selanjutnya akan aktif sesuai jadwal dan berstatus “Terkunci” apabila <b>rangkaian aktivitas sesi sebelumnya telah diselesaikan (wajib menyelesaikan Tugas Praktik Mandiri dan Quiz).</b></li>
          <li>Peserta dapat mengakses informasi di setiap sesi apabila sesi tersebut telah aktif & tidak terkunci.</li>
        </ol>
      </div>
    </Modal>
    <div class="d-flex mt-5" style="padding: 20px 0">
      <div><img :src="schedule.image_url" class="img-class" /></div>
      <div style="margin-left: 30px">
        <div
          class="d-flex button-light-blue"
          v-if="schedule.category === 4"
          style="align-items: center; justify-content: center"
        >
          <img src="/img/other/ic_prakerja.png" width="69" height="22" />
        </div>
        <h4
          class="button-light-blue"
          style="width: 120px"
          v-if="schedule.category !== 4"
        >
          Public Training
        </h4>
        <h2 class="title">{{ schedule.name }}</h2>
      </div>
    </div>
    <div class="class-info mt-2">
      <div class="content" style="width: 60px">
        <div>
          <img src="/img/icons/ic_level.png" width="20px" height="20px" />
        </div>
        <span style="margin-left: 10px">{{
          $utils.formatLevel(schedule.level)
        }}</span>
      </div>
      <div class="content">
        <img
          src="/img/icons/ic_training_method.png"
          width="20px"
          height="20px"
        />
        <span style="margin-left: 10px">{{
          schedule.learning_method | formatMethode
        }}</span>
      </div>
    </div>
    <div class="temp-1 mt-5">
    <h3>Sesi Kelas</h3>
            <div class="temp-1 cursor-pointer" @click="isModalInfoSesi = true">
              <Information style="margin-left: 10px" />
            </div>
          </div>
      <div
        v-for="(item, idx) in schedule.sessions" :key="idx"
        :class="`container-radius cursor-pointer ${item.is_locked ?  'opacity-1': '' }`"
        @click="goToPage(idx)"
      >
        <div class="d-flex" style="gap: 10px">
          <div style="width: 100%">
            <h3 class="title">Sesi {{ idx + 1 }}</h3>
            <h3 class="title text-line-1 mb-2" style="line-height: 19px">
              {{ item.name }}
            </h3>
            <span style="font-size: 12px"
              >{{ item.date }}, {{ item.time }}</span
            >
          </div>
          <div class="opt-session">
            <Padlock v-if="idx >= schedule.current_sequence && schedule.status && !isTester"  :width="25"  />
            <img
              :src="'/img/icons/ic_arrow_right.png'"
              width="24px"
              height="24px"
            />
          </div>
        </div>
      </div>
    <div class="card-tpm">
      <div>
        <h3>Tugas Unjuk Keterampilan</h3>
        <p style="text-align: justify; font-size: 14px">
          Unggah Tugas Unjuk Keterampilan untuk mendukung kelengkapan dalam
          pelatihan. Skema penilaian unjuk keterampilan dapat
          <b
            ><span
              style="color: #162ca2"
              @click="$utils.goLink(schedule.rubrik_url)"
              >dilihat di sini</span
            ></b
          >
        </p>
      </div>
      <div class="mt-3">
        <b>Instruksi Unjuk Keterampilan:</b>
        <span
          style="text-align: justify; font-size: 14px"
          v-html="schedule.final_project.instruction_final_project"
        ></span>
      </div>
      <v-btn
        depressed
        style="background: white; width: 100%"
        class="btn-stoke-blue justify-center mt-6 gray-button"
        @click="onUploadUK"
        v-if="!schedule.final_project.final_project_url && schedule.status"
      >
        <span
          v-if="!schedule.final_project.is_final_project_locked" class="text-capitalize font-weight-bold">UNGGAH</span>
        <div v-else style="display: flex; align-items: center; gap: 8px">
          <Padlock />
          <span class="text-capitalize font-weight-bold">TERKUNCI</span>
        </div>
      </v-btn>
      <v-btn
        depressed
        style="padding: 0px 30px; background: white; width: 100%"
        class="btn-radius d-flex confirmed mt-6"
        v-else
      >
        <GrayCheck /><span
          class="text-capitalize font-weight-bold"
          style="margin-left: 10px"
          >SELESAI DIKERJAKAN</span
        >
      </v-btn>
      <p class="text-red mt-2">*File yang dapat di upload adalah JPG/JPEG, PNG, DOC, DOCX, XLS, XLSX, TXT, PDF, PPT, PPTX <br> dan maksimal ukuran file 2 MB</p>
      <div
        class="container-radius mt-8"
        style="padding: 20px; padding-bottom: 5px"
      >
        <div class="d-flex" style="align-items: center">
          <h3 style="width: 100%">Informasi Kelas</h3>
          <div
            style="cursor: pointer"
            @click="isDetailClassHide = !isDetailClassHide"
          >
            <Arrow :down="isDetailClassHide" />
          </div>
        </div>
        <div v-if="isDetailClassHide" class="mt-6 class-description">
          <h3>Deskripsi Kelas</h3>
          <p>{{ classDetail.description }}</p>
          <p class="py-4" v-html="classDetail.class_detail.learning_objective"></p>
          <h3>Sasaran Peserta</h3>
          <ul>
            <li
              v-for="(item, idx) in classDetail.class_detail.target_audience"
              :key="idx"
              v-html="item.level"
            ></li>
          </ul>
          <h3 class="mt-4">Metode Penyampaian</h3>
          <p v-html="classDetail.class_detail.learning_method"></p>
          <h3>Perangkat Evaluasi</h3>
          <ul>
            <li>Pre-Test</li>
            <li>Kuis</li>
            <li>Tugas Praktik Mandiri</li>
            <li>Post-Test</li>
            <li>Uji Keterampilan</li>
          </ul>
          <h3 class="mt-4">Syarat & Ketentuan Mengikuti Kelas</h3>
          <ul>
            <li v-for="(item, idx) in classDetail.class_detail.requirements" :key="idx">{{ item.name }}</li>
          </ul>
        </div>
        <div style="height: 40px"></div>
      </div>
      <div
        class="container-radius mt-8"
        style="padding: 20px; padding-bottom: 5px"
      >
        <div class="d-flex" style="align-items: center">
          <h3 style="width: 100%">Tentang Instruktur</h3>
          <div
            style="cursor: pointer"
            @click="isDetailInstructor = !isDetailInstructor"
          >
            <Arrow :down="isDetailInstructor" />
          </div>
        </div>
        <div v-if="isDetailInstructor" class="mt-6 class-description">
          <div class="instructor">
            <img :src="$utils.loadImage(instructor.image_url)" alt="">
            <h5 class="title">{{ instructor.name }}</h5>
            <p style="margin: 0;">{{ instructor.position }}</p>
            <p>{{ instructor.company ? `at ${instructor.company }` : '' }}</p>
          </div>
          <p style="text-align: justify;">{{ instructor.description }}</p>
        </div>
      </div>
      <div class="spacing-btm"></div>
    </div>
  </div>
</template>

<script>
import NavbarBack from "@/components/Navbar/Mobile/NavbarBack";
import Padlock from "@/assets/icons/Padlock.vue";
import Loading from "@/components/others/Loading.vue";
import Popup from "../../others/Popup.vue";
import GrayCheck from "@/assets/icons/GrayCheck.vue";
import Arrow from "@/assets/icons/Arrow.vue";
import Information from "@/assets/icons/Information.vue";
import Modal from '../../Modal.vue';

export default {
  components: {
    NavbarBack,
    GrayCheck,
    Padlock,
    Loading,
    Popup,
    Arrow,
    Modal,
    Information
  },
  name: "MSessionClass",
  data() {
    return {
      menus: [
        {
          text: "Kelas Aktif",
          href: "/home",
        },
        {
          text: "Detail Kelas",
          href: "",
        },
      ],
      isLoading: false,
      messageError: "",
      isAlert: false,
      isDetailClassHide: true,
      isDetailInstructor: true,
      assesment: {},
      instructor: {
        image_url: "",
        name: "",
        position: "",
        company: "",
        description: ""
      },
      classId: this.$route.params.id,
      userID: this.$decode_token["id"],
      isModalInfoSesi: false,
      isTester: false,
      redeem_code: "",
      classDetail: {
        description: "",
        class_detail: {
          requirements: [],
          learning_objective: "",
          target_audience: [],
          learning_method: "",
          special_purpose: "",
        },
      },
      schedule: {
        id: 1,
        name: "",
        image_url: "",
        category: 4,
        total_tpm: 0,
        total_quiz: 1,
        learning_method: "",
        dates: "",
        days: "",
        hours: "",
        level: "",
        final_project: {},
        sessions: [
          {
            name: "",
            date: "",
            time: "",
          },
        ],
      },
    };
  },
  methods: {
    goToPage(idx) {
      if (!this.schedule.status || this.isTester) {
        this.$router.push(`/class/${this.classId}/detail/${idx+1}?done=true`);
        return
      }
      if (this.schedule.sessions[idx].is_locked) return;
      if (this.redeem_code) {
        this.$router.push(`/class/${this.classId}/detail/${idx+1}${idx+1 >= this.schedule.current_sequence ? "" : "?done=true"}`);
      } else {
        this.$router.push(`/class/${this.classId}/verification/${idx+1}`);
      }
    },
    async verificationCode() {
      this.isLoading = true
        const data = {
          redeem_code: this.redeem_code,
          class_id: this.classId,
          session: 999
        }
        try {
          const res = await this.$web_http.post(`/v1/prakerja/url-generate`, {
              redeem_code: this.redeem_code,
              email: this.$decode_token["email"],
              class_id: Number(this.classId),
              user_id: this.$decode_token["id"],
              sequence: data.session
          })
          this.$utils.setLocalPrakerja(data)
          window.location.href = res.data.data.url
        } catch (error) {
          this.isAlert = true
          this.messageError = error.response.data.data             
          this.isLoading = false
        }
    },
    async onUploadUK() {
      if (this.schedule.final_project.is_final_project_locked) return;
      if (!this.redeem_code) {
        this.$router.push(`/class/${this.classId}/verification/5`);
        return;
      }
      this.isLoading = true
      try {
        const res = await this.$http.get(`/v1/prakerja/status?redeem_code=${this.redeem_code}&sequence=999&class_id=${this.classId}&email=${this.$decode_token["email"]}&user_id=${this.$decode_token["id"]}`);
        this.isLoading = false
        const data = res.data.data;
        if (!data.attendance_status) {
          this.verificationCode()
          return
        }
      } catch (error) {
        this.isAlert = true
        this.messageError = error.response.data.data             
        this.isLoading = false
        return
      }
      this.$refs.file.click();
    },
    async uploadTask(file) {
      this.isLoading = true;
      const data = this.$utils.getFile(file);
      if (data[1] > 2) {
        this.isAlert = true;
        this.messageError = "Ukuran file tidak boleh lebih besar dari 2 MB";
        return;
      }
      const formData = new FormData();
      formData.append("attachment", data[0]);

      const res_upload = await this.$crm_http.post(
        "/v1/upload-file",
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
      const attachment_url = this.$utils.filterLinkClickup(
        res_upload.data.data.upload_image_response.url_w_query
      );
      await this.$http
        .post("/v1/user-assesment/prakerja", {
          assessment_id: this.assesment.assesment_id,
          redeem_code: this.redeem_code,
          sequence: 999,
          url_file: attachment_url,
          scope: "uk",
        })
        .then(() => {
          this.getShedule();
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.isAlert = true;
          this.messageError = err.response.data.data;
          if (this.messageError == "redeem code tidak ditemukan") {
            this.$router.push(`/class/${this.classId}/verification/5`);
          }
        });
    },
    async getAssesmentByUserClass() {
      await this.$http
        .get(
          `/v1/user-assesment/detail?user_id=${this.userID}&class_id=${this.classId}`
        )
        .then((response) => {
          this.assesment = response.data.data.assesment;
        })
        .catch((err) => console.error(err.response));
    },
    async getDetailClass() {
      try {
        this.redeem_code = await this.$utils.getRedeem(this.classId);
        const res = await this.$crm_http.get("/v1/class/" + this.classId);
        this.classDetail = res.data.data.class;
      } catch (err) {
        console.log(err);
      }
    },
    async getInstructor() {
      try {
        const res = await this.$web_http.get("/v1/instructor/class/" + this.classId);
        this.instructor = res.data.data.instructor
      } catch (err) {
        console.log(err);
      }
    },
    async getShedule() {
      this.isLoading = true;
      await this.$http
        .get(
          "/v2/user-schedule/detail?user_id=" +
            this.userID +
            "&class_id=" +
            this.classId
        )
        .then((response) => {
          const data = response.data.data.schedule;
          if (data.category !== 4) {
            this.$router.push("/home");
            return;
          }

          this.schedule = data;
          this.schedule.sessions = data.sessions.map((item) => {
            return {
              ...item,
              name: item.syllabus_description.split("</li>")[0].replace("<ul>", "").replace("<li>", ""),
              time: data.hours,
            };
          });          

          this.isLoading = false;
        })
        .catch(() => {
          this.$router.push("/home");
          this.isLoading = false;
        });
    },
    checkingTester() {
      const listIdTester = this.$utils.listIdTester()
      if (listIdTester.find((item) => item == this.userID)) {
        this.isTester = true
      } else {
        this.isTester = false
      }
    },
  },
  mounted() {
    this.checkingTester()
    this.getAssesmentByUserClass();
    this.getShedule();
    this.getDetailClass();
    this.getInstructor()
  },
};
</script>

<style scoped>
.btn-submit {
  margin: auto;
  display: block;
  font-size: 16px;
  border-radius: 14px !important;
  width: 50%;
}
.img-class {
  width: 70px;
  height: 70px;
  border-radius: 18px;
}
.title {
  font-weight: bold;
  font-size: 19px !important;
  line-height: 25px !important;
  color: #36373f;
}
.class-info {
  display: flex;
  justify-content: flex-end;
  gap: 40px;
  width: 80%;
  margin: auto;
  margin-top: -90px;
}
.class-info .content {
  align-items: flex-start;
  align-items: center;
  gap: 2px;
  display: flex;
  width: 150px;
  font-size: 12px;
}
.spacing-btm {
  height: 40px;
}
.gray-button {
  color: #575966 !important;
  border-color: #7b7e8c;
}
.instructor {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.instructor img {
  width: 110px;
  height: 110px;
  border-radius: 50%;
}
.opt-session {
  cursor: pointer;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
  color: #7b7e8c;
}
.opacity-1 {
  opacity: 0.6;
  border: 1px solid #d3d6e1;
}
.card-tpm {
  margin: 0 3px;
  margin-top: 30px;
}

.class-description h1, .class-description h2, .class-description h3  {
  padding-top: 13px;
  padding-bottom: 8px;
}
.temp-1 {
  display: flex;
  align-items: center;
}
</style>
