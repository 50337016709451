<template>
  <div>
    <div class="cursor-pointer card-schedule dekstop">
      <div class="d-flex" style="align-items: center" @click="goToPage(data)">
        <div
          style="display: flex; justify-content: center; align-items: center"
        >
          <img
            :src="data.image_url"
            width="120px"
            height="120px"
            style="border-radius: 24px"
          />
        </div>
        <div style="margin-left: 25px">
          <div style="display: flex">
            <div v-if="data.category === 4" style="display: flex">
              <div
                class="d-flex button-light-blue"
                v-if="data.category === 4"
                style="align-items: center; justify-content: center"
              >
                <img src="/img/other/ic_prakerja.png" width="69" height="22" />
              </div>
              <div
                class="d-flex button-light-blue"
                v-if="data.category === 4"
                style="
                  align-items: center;
                  justify-content: center;
                  margin-left: 10px;
                "
              >
                <span>Webinar</span>
              </div>
            </div>
            <div class="button-light-blue" v-if="data.category !== 4">
              <span>Public Training</span>
            </div>
            <div
              class="d-flex button-light-blue"
              style="
                align-items: center;
                justify-content: center;
                margin-left: 10px;
              "
              v-if="isTamat"
            >
              <img src="/img/icons/ic_graduate.png" width="26" height="26" />
              <span style="margin-left: 5px">Tamat</span>
            </div>
          </div>
          <span class="title" id="font-poppins">{{ data.name }}</span>
          <div style="display: flex; padding: 16px 0">
            <div>
              <div class="box-info">
                <img
                  class="box-img"
                  src="/img/icons/ic_level.png"
                  width="30px"
                  height="30px"
                />
                <span>{{ $utils.formatLevel(data.level) }}</span>
              </div>
            </div>

            <div>
              <div class="box-info">
                <img
                  class="box-img"
                  src="/img/icons/ic_date.png"
                  width="30px"
                  height="30px"
                />
                <span>{{ data.dates }}, {{ data.hours }}</span>
              </div>
            </div>

            <div>
              <div class="box-info">
                <img
                  class="box-img"
                  src="/img/icons/ic_training_method.png"
                  width="30px"
                  height="30px"
                />
                <span>{{ data.learning_method | formatMethode }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="container-chat"
        v-if="data.is_rating && isRiwayat && !isTamat"
        @click="goToPage(data)"
      >
        <div
          class="content"
          style="gap: 20px; padding: 4px 0; align-items: flex-start !important"
        >
          <div>
            <div class="name-circle d-flex">
              <span>{{ data.initial_name }}</span>
            </div>
          </div>
          <div>
            <StarTemplate :starCount="data.stars" />
            <p class="mt-1">{{ data.feedback }}</p>
            <span class="date-ratting">{{
              $utils.dateFormat(data.ratting_date)
            }}</span>
          </div>
        </div>
      </div>
      <div
      v-if="data.is_rating && !isTamat && !isRiwayat"
        class="container-chat"
        style="border-color: white; background-color: #F1F6FF;"
        id="ulas"
        @click="showReflectiveJournal(data)"
      >
        <div class="content">
          <ChatIcon :width="25" fill="#162CA2" />
          <div class="content">
            <span style="color: #162CA2;">Kamu sudah isi reflective journal, yuk lihat di sini!</span>
          </div>
        </div>
      </div>
      <div
        class="container-chat"
        v-else-if="
          ((data.sequence >= 5 && !data.is_rating) || isTester) && !isTamat
        "
        id="ulas"
        @click="tulisUlasan(data)"
      >
        <div class="content">
          <ChatIcon :width="25" />
          <div class="content">
            <span>Isi reflective journal & ulasan yuk!</span>
          </div>
        </div>
      </div>
    </div>
    <div class="card-schedule mobile">
      <div @click="goToPage(data)">
        <div class="d-flex">
          <div><img :src="data.image_url" class="img-class" /></div>
          <div style="margin-left: 20px">
            <div
              class="d-flex button-light-blue"
              v-if="data.category === 4"
              style="align-items: center; justify-content: center"
            >
              <img src="/img/other/ic_prakerja.png" width="69" height="22" />
            </div>
            <div class="button-light-blue" v-if="data.category !== 4">
              <span>Public Training</span>
            </div>
            <h3 class="title-2">
              {{
                data.name.length > 50
                  ? data.name.substring(0, 50) + "..."
                  : data.name
              }}
            </h3>
          </div>
        </div>
        <div class="info-class">
          <div class="info-class-ctn">
            <img src="/img/icons/ic_level.png" />
            <span>{{ $utils.formatLevel(data.level) }}</span>
          </div>
          <div class="info-class-ctn">
            <img src="/img/icons/ic_date.png" />
            <span>{{ data.dates }}, {{ data.hours }}</span>
          </div>
          <div class="info-class-ctn">
            <img src="/img/icons/ic_training_method.png" />
            <span>{{ data.learning_method | formatMethode }}</span>
          </div>
        </div>
      </div>
      <div
        class="container-chat"
        v-if="data.is_rating && isRiwayat && !isTamat"
        @click="goToPage(data)"
      >
        <div
          class="content"
          style="gap: 20px; padding: 4px 0; align-items: flex-start !important"
        >
          <div>
            <div class="name-circle d-flex">
              <span>{{ data.initial_name }}</span>
            </div>
          </div>
          <div>
            <StarTemplate :starCount="data.stars" />
            <p class="mt-1">{{ data.feedback }}</p>
            <span class="date-ratting">{{
              $utils.dateFormat(data.ratting_date)
            }}</span>
          </div>
        </div>
      </div>
      <div
        v-if="data.is_rating && !isRiwayat && !isTamat"
        class="container-chat"
        style="border-color: white; background-color: #F1F6FF;"
        id="ulas"
        @click="showReflectiveJournal(data)"
      >
        <div class="content">
          <ChatIcon :width="25" fill="#162CA2" />
          <div class="content">
            <span style="font-size: 12px; color: #162CA2;" >Kamu sudah isi reflective journal, yuk lihat di sini!</span>
          </div>
        </div>
      </div>
      <div
        class="container-chat"
        v-else-if="
          ((data.sequence >= 5 && !data.is_rating) || isTester) && !isTamat
        "
        id="ulas"
        @click="tulisUlasan(data)"
      >
        <div class="content">
          <ChatIcon :width="25" />
          <div class="content">
            <span style="font-size: 12px"
              >Isi reflective journal & ulasan yuk!</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ChatIcon from "@/assets/icons/Chat.vue";
import StarTemplate from "./StarTemplate.vue";

export default {
  name: "CardClass",
  props: {
    data: {
      type: Object,
      required: true,
    },
    isTester: {
      type: Boolean,
      default: false,
    },
    isTamat: {
      type: Boolean,
      default: false,
    },
    isRiwayat: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ChatIcon,
    StarTemplate,
  },
  methods: {
    goToPage(item) {
      if (this.isRiwayat) {
        window.location.href = `/survey/${item.class_id}?user_id=${this.$decode_token["id"]}`;
        return;
      }
      if (item.category === 4) {
        window.location.href = `/class/${item.class_id}/session`;
      } else {
        window.location.href = `/class/${item.class_id}/detail`;
      }
    },
    tulisUlasan(item) {
      window.location.href = `/survey/${item.class_id}`;
    },
    showReflectiveJournal(item) {
      window.location.href = `/survey/${item.class_id}?user_id=${this.$decode_token["id"]}`;
    },
  },
};
</script>
<style scoped>
.container-chat {
  margin-top: 10px;
  padding: 5px 19px;
  border-radius: 18px;
  position: relative;
  z-index: 10px;
  border: 1px solid #d3d6e1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.date-ratting {
  font-size: 15px;
}

.container-chat .content {
  display: flex;
  gap: 8px;
  align-items: center !important;
}
.dekstop {
  display: block;
}
.mobile {
  display: none;
}
.name-circle {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(111.75deg, #179eff -0.58%, #162ca2 100.82%);
}
@media only screen and (max-width: 920px) {
  .dekstop {
    display: none;
  }
  .mobile {
    display: block;
  }
  .img-class {
    height: 80px;
    width: 80px;
    border-radius: 16px;
  }
  .title-2 {
    font-weight: bold;
    font-size: 15px !important;
    line-height: 19px !important;
    color: #36373f;
    margin-top: 15px;
  }
  .date-ratting {
    font-size: 12px;
  }
}
</style>
