<template>
  <div>
    <div class="category-container" v-if="isCategory">
      <v-radio-group v-model="select">
        <v-radio
          v-for="(i, idx) in categories"
          :key="idx"
          :label="`${i.name}`"
          :value="i.value"
          @click="() => (select = i.value)"
        ></v-radio>
      </v-radio-group>
      <div class="mt-5">
        <Button
          name="TERAPKAN"
          width="100%"
          height="40px"
          @click="onFilterClass"
        />
      </div>
    </div>

    <div class="container-body-mobile">
      <div>
        <h4>Syarat Mendapatkan Sertifikat</h4>
        <p style="font-size: 14px">
          Peserta wajib menyelesaikan seluruh rangkaian pelatihan baik dari
          mengikuti pelatihan Webinar, mengerjakan Evaluasi (<b>Pre-Test</b>,
          <b>Quiz</b>, <b>Post-Test</b>, <b>Tes Praktek Mandiri</b> &
          <b>Tes Uji Keterampilan</b>)
        </p>
        <p style="font-size: 14px">
          Sertifikat akan diterbitkan maximal <b>7x24 jam</b>
        </p>
      </div>
      <p style="font-size: 14px" class="mt-2 mb-3">
        Kamu dapat mengunduh sertifikat dengan klik gambar atau klik Lihat
        Detail pada sertifikat yang dipilih
      </p>
      <div class="certificates">
        <div
          class="cardss"
          v-for="(n, idx) in certificates"
          :key="idx"
          @click="goToLink(n.file_url)"
        >
          <div
            class="d-flex button-light-blue label-category"
            v-if="n.category === 4"
            style="align-items: center; justify-content: center"
          >
            <img src="/img/other/ic_prakerja.png" width="69" height="22" />
          </div>
          <div class="button-light-blue label-category" v-if="n.category !== 4">
            <span>Public Training</span>
          </div>
          <img src="/img/prakerja/default_certificate.png" class="ctn" />
          <div class="title">
            <span>{{
              n.name.length > 50 ? n.name.substring(0, 50) + ".." : n.name
            }}</span>
          </div>
        </div>
      </div>
      <div class="button-filter" v-if="!isCategory">
        <Button
          name="KATEGORI"
          height="40px"
          width="80%"
          @click="() => (isCategory = true)"
          ><Grow
        /></Button>
      </div>
    </div>
  </div>
</template>

<script>
import Grow from "@/assets/icons/Grow.vue";
import Button from "../../others/Button.vue";

export default {
  data() {
    return {
      categories: [
        {name: "Semua", value: 0},
        {name: "Prakerja", value: 4},
        {name: "Public Training", value: 1},
      ],
      certificates: [],
      certificates_list: [],
      select: 0,
      isCategory: false,
    };
  },
  components: {
    Button,
    Grow,
  },
  methods: {
    async getCertificates() {
      await this.$http
        .get("/v1/user-certificate?user_id=" + this.$decode_token["id"])
        .then((response) => {
          this.certificates = response.data.data.certificates;
          this.certificates_list = response.data.data.certificates;
        })
        .catch((err) => console.error(err.response));
    },
    onFilterClass() {
      if (!this.select) this.certificates = this.certificates_list;
      else
        this.certificates = this.certificates_list.filter(
          (item) => item.category === this.select
        );
      this.isCategory = false;
    },
    goToLink(e) {
      window.open(
        e,
        "_blank" // <- This is what makes it open in a new window.
      );
    },
  },
  mounted() {
    // var data = JSON.parse(localStorage.getItem("user"))
    // this.user_id = data.id
    this.getCertificates();
  },
};
</script>

<style scoped>
.certificate-card {
  border-radius: 10px;
  cursor: pointer;
  width: 500px;
  height: 270px;
}

.notification-info {
  padding: 10px;
  background-color: #f1f6ff;
  display: flex;
  align-items: flex-start;
  gap: 15px;
}

.title {
  font-weight: 700;
  font-size: 14px !important;
  line-height: 28px;
  margin: 23px 20px;
  color: #36373f;
}

.label-category {
  position: absolute;
  right: 20px;
  top: 20px;
}
.certificates {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
}
.certificates .cardss {
  box-shadow: 2px 2px 10px 3px rgba(13, 28, 113, 0.1) !important;
  border-radius: 24px;
  padding: 0px !important;
  position: relative;
  cursor: pointer;
  background-color: white;
}
.certificates .ctn {
  width: 100%;
  border-radius: 14px 14px 0 0;
  height: 200px;
  object-fit: cover;
}
</style>
