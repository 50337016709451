<template>
    <div class="container-body-mobile">
        <Popup :message="errorMessage" isError v-if="isShowPopup"
            @close="() => { isShowPopup = !isShowPopup }" />
        <Modal v-if="isModal" @close="() => (isModal = false)" height="40%">
            <img :src="detailImg" alt="Detail Gambar" class="img-modal-mo">
        </Modal>        
        <div v-for="(n, idx) in question_sets" :key="idx" class="mb-5">
            <div>
                <div class="seq-no">
                    <h5>{{ n.number }}</h5>
                </div>
            
            <div class="box-quest">
                <img :src="n.attached_file" :alt="`Resource ` + idx1 "  class="question-img" v-if="n.attached_file_type === 'image'" @click="showDetailImage(n.attached_file)">
                <h4>{{ n.question }}</h4>
                <div v-for="i in n.choices" :key="i.option" @click="chooseOption(idx, n.question, i.option)" :class="`ans ${isChoice(tempAnswer.find(item => item.no_sys === idx), i.option) ? 'coice' : ''}`">
                    <span style="text-transform: uppercase;">{{ i.option }}.</span>
                {{ i.description }}
                </div>
            </div>
        </div>
        </div>
        <p class="text-center mt-10">Apakah HiColers yakin mengumpulkan jawaban ujian ini?</p>
        <v-btn depressed color="#162CA2" style="padding:0px 80px;width: 100%;" class="btn-radius d-flex" @click="submitAnswer()" :disabled="!isDone">
            <span class="text-capitalize font-weight-bold white--text" >SUBMIT</span>
        </v-btn>
    </div>
</template>

<script>
import Modal from '@/components/Modal.vue';

export default {
    data () {
        return {
            question_sets: [],
            tempAnswer: [],
            category: "",
            group: 0,
            isDone: false,
            isModal: false,
            detailImg: "",
            isShowPopup: false,
            errorMessage: "",
        }
    },
    components: {
        Modal
    },
    methods: {
        async getQuestion() {
            await this.$http
                .get("/v1/question-set?class_id="+this.$route.params.id+"&group="+this.$route.query.g+"&category_question_set="+this.category)
                    .then((response) => {
                    var tempNumber = 1
                    response.data.data.questions_sets.forEach((element) => {
                        this.question_sets.push({
                            grouping: element.grouping,
                            class_id: element.class_id,
                            category_question_set: element.category_question_set,
                            question: element.question,
                            question_type: element.question_type,
                            choices: element.choices,
                            correct_answer: element.correct_answer,
                            point: element.point,
                            attached_file: element.attached_file,
                            attached_file_type: element.attached_file_type,
                            number: tempNumber++
                        });
                    });
                })
            .catch((err) => console.error(err.response));
        },
        
        chooseOption(id, question, answer) {
            const quest_inti = this.tempAnswer.find((item) => item.question === question)
            if (quest_inti) {
                if (quest_inti.answer != answer) {
                    quest_inti.answer = answer
                }
            } else {
                this.tempAnswer.push({
                    no_sys: id,
                    question: question,
                    answer: answer
                })
            }
            if (this.tempAnswer.length >= this.question_sets.length) {
                this.isDone = true
            }
        },
        isChoice(data, alphabet) {
            if (!data) {
                return false
            }
            if (data.answer ==  alphabet) {
                    return true
                }
                return false
        },
        showDetailImage(img) {
            this.isModal = true
            this.detailImg = img
        },
        async submitAnswer() {
            await this.$http
            .post("/v1/user-assesment", {
                class_id: parseInt(this.$route.params.id),
                user_id: this.$decode_token["id"],
                answers: this.tempAnswer,
                category_question_set: this.$route.query.c,
                group: parseInt(this.$route.query.g)
            })
            .then(() => {
                window.location.href = '/assesment';
            })
            .catch((err) => {
                console.error(err.response)
                this.isShowPopup = true
                this.errorMessage = "Ups, submit jawaban ujian gagal. Coba lagi!"
            })
        },
    },
    mounted () {
        if (this.$route.query.c == "Quiz") {
            this.category = "quiz"
        } else {
            this.category = "pre post test"
        }

        this.getQuestion();
    }
}
</script>

<style scoped>
.seq-no {
    background-color: #FFDE59;
    border-radius: 8px 8px 0 0;
    width: 50px;
    padding: 5px 0;
    text-align: center;
}
.box-quest h4 {
    line-height: 20px;
}
.box-quest {
    background-color: white;
    padding: 20px;
    border-radius: 0 12px 12px 12px;
}
.box-quest .coice {
    border: 1px solid #162CA2 !important;
    background-color: #F1F6FF;
}
.box-quest .ans { 
    padding: 20px;
    display: flex;
    gap: 15px;
    border-radius: 10px;
    border: 1px solid #D3D6E1;
    margin-top: 10px;
}
.question-img {
    object-fit: contain;
    width: 100%;
    height: 300px;
    margin-bottom: 15px;
}
.img-modal-mo {
    width: 100%; height: 98%;
    object-fit: contain;
}
</style>