<template>
    <div class="container-custom-body">
        <h2>Detail Akun</h2>

        <div class="container-radius">
            <div class="d-flex" style="margin-bottom:20px">
                <div style="width:83%">
                    <div class="name-circle d-flex"><span>{{initialName}}</span></div>
                    <h2 style="margin-top:20px">{{ user.gender }} {{name}}</h2>
                </div>

                <div class="d-flex btn-out-grey" style="height:40px" @click="goToPage('/setting')">
                    <img src="/img/icons/ic_setting.png" width="24px" height="24px">
                    <span style="margin-left:10px">PENGATURAN</span>
                </div>
            </div>

            <div class="d-flex bg-ghost-white adjust-padding">
                <span style="width:90%">No. Handphone</span>
                <span>{{ user.phone_number }}</span>
            </div>
            <div class="d-flex adjust-padding">
                <span style="width:90%">Email</span>
                <span>{{ email }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import VueJwtDecode from 'vue-jwt-decode';
export default {
    data () {
        return {
            name: '',
            split_name: '',
            email: '',
            user: {},
            initialName: ''
        }
    },
    methods: {
        goToPage(e) {
            window.location.href = e;
        },
        async getAccount() {
            await this.$http
                .get("/v1/account?email="+this.email)
                    .then((response) => {
                        this.user = response.data.data.user

                        const req = JSON.stringify({ 
                            "full_name": this.user.full_name,
                            "email": this.user.email,
                            "phone_number": this.user.phone_number,
                            "gender": this.user.gender,
                        })
                        localStorage.setItem("account", req)

                        this.name = this.user.full_name
                        var nameSplit= this.user.full_name.split(" ")
                        if (nameSplit.length >= 2) {
                            this.initialName = nameSplit[0][0] + '' + nameSplit[1][0]
                        } else {
                            this.initialName = nameSplit[0][0]
                        }
                })
            .catch((err) => console.error(err.response));
        },
    },
    mounted () {
        if (this.$decode_token == null) {
            var data = localStorage.getItem("user")
            let decoded = VueJwtDecode.decode(data)
            this.$decode_token = decoded
        } 

        this.email = this.$decode_token["email"]
        this.getAccount()

    }
}
</script>

<style scoped>
.name-circle {
    font-weight: 600;
    font-size: 40px;
    line-height: 25px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    width: 120px;
    height:120px;
    border-radius:50%;
    background: linear-gradient(111.75deg, #179EFF -0.58%, #162CA2 100.82%);
}

.adjust-padding {
    padding: 20px 40px !important;
    align-items: center;
}
</style>