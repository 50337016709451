<template>
  <svg
    v-if="bold"
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.4298 25.4266C21.3549 29.5019 15.321 30.3823 10.3832 28.0988C9.65424 27.8053 9.05661 27.5681 8.48847 27.5681C6.90595 27.5775 4.93619 29.1119 3.91245 28.0894C2.88871 27.0655 4.42432 25.0942 4.42432 23.5021C4.42432 22.9339 4.19652 22.347 3.90307 21.6166C1.61841 16.6796 2.50011 10.6437 6.57498 6.56969C11.7768 1.36599 20.228 1.36599 25.4298 6.56835C30.6409 11.7801 30.6316 20.2242 25.4298 25.4266Z"
      :stroke="fill"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.2544 16.5507H21.2664"
      :stroke="fill"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.9068 16.5507H15.9188"
      :stroke="fill"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.563 16.5507H10.575"
      :stroke="fill"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <svg
    v-else
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.66797 16.0201C2.66797 8.99624 8.2813 2.66675 16.028 2.66675C23.6013 2.66675 29.3346 8.87606 29.3346 15.9801C29.3346 24.2191 22.6146 29.3334 16.0013 29.3334C13.8146 29.3334 11.388 28.7459 9.4413 27.5975C8.7613 27.1835 8.18797 26.8764 7.45464 27.1168L4.7613 27.918C4.0813 28.1316 3.46797 27.5975 3.66797 26.8764L4.5613 23.8852C4.70797 23.4713 4.6813 23.0306 4.46797 22.6834C3.3213 20.5736 2.66797 18.2635 2.66797 16.0201ZM14.268 16.0201C14.268 16.9682 15.028 17.7293 15.9746 17.7427C16.9213 17.7427 17.6813 16.9682 17.6813 16.0335C17.6813 15.0854 16.9213 14.3242 15.9746 14.3242C15.0413 14.3109 14.268 15.0854 14.268 16.0201ZM20.4146 16.0335C20.4146 16.9682 21.1746 17.7427 22.1213 17.7427C23.068 17.7427 23.828 16.9682 23.828 16.0335C23.828 15.0854 23.068 14.3242 22.1213 14.3242C21.1746 14.3242 20.4146 15.0854 20.4146 16.0335ZM9.82797 17.7427C8.89464 17.7427 8.1213 16.9682 8.1213 16.0335C8.1213 15.0854 8.8813 14.3242 9.82797 14.3242C10.7746 14.3242 11.5346 15.0854 11.5346 16.0335C11.5346 16.9682 10.7746 17.7293 9.82797 17.7427Z"
      :fill="fill != '#7B7E8C' ? fill : '#ACAFBF'"
    />
  </svg>
</template>

<script>
export default {
  name: "chat-icon",
  props: {
    width: {
      type: Number,
      default: 32,
    },
    height: {
      type: Number,
      default: 32,
    },
    fill: {
      type: String,
      default: '#7B7E8C',
    },
    bold: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
