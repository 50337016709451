<template>
  <div>
    <Loading v-if="isLoading" />
    <div class="category-container" v-if="isCategory">
      <v-radio-group v-model="select">
        <v-radio
          v-for="(i, idx) in categories"
          :key="idx"
          :label="`${i.name}`"
          :value="i.value"
          @click="() => (select = i.value)"
        ></v-radio>
      </v-radio-group>
      <div class="mt-5">
        <Button
          name="TERAPKAN"
          width="100%"
          height="40px"
          @click="onFilterClass"
        />
      </div>
    </div>

    <div class="container-body-mobile pb-10">
      <div v-for="item in schedules" :key="item.class_id">
        <CardClass :data="item" :isRiwayat="true" />
      </div>
      <div class="button-filter" v-if="!isCategory">
        <Button
          name="KATEGORI"
          height="40px"
          width="80%"
          @click="() => (isCategory = true)"
          ><Grow
        /></Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../others/Button.vue";
import Grow from "@/assets/icons/Grow.vue";
import CardClass from "../../others/CardClass.vue";
import Loading from "../../others/Loading.vue";

export default {
  data() {
    return {
      categories: [
        {name: "Semua", value: 0},
        {name: "Bootcamp", value: 5},
        {name: "Prakerja", value: 4},
        {name: "Public Training", value: 1},
      ],
      schedules_list: [],
      schedules: [],
      select: 0,
      isCategory: false,
      isLoading: false,
    };
  },
  components: {
    CardClass,
    Button,
    Loading,
    Grow,
  },
  methods: {
    onFilterClass() {
      if (!this.select) this.schedules = this.schedules_list;
      else
        this.schedules = this.schedules_list.filter(
          (item) => item.category === this.select
        );
      this.isCategory = false;
    },
    async getClass() {
      this.isLoading = true;
      this.schedules = [];
      this.schedules_list = [];
      await this.$http
        .get("/v1/user-schedule/review?user_id=" + this.$decode_token["id"])
        .then(async (response) => {
          for (let i = 0; i < response.data.data.schedules.length; i++) {
            const item = response.data.data.schedules[i];
            this.schedules.push(item);
            this.schedules_list.push(item);
          }
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.getClass();
  },
};
</script>
