<template>
  <div class="container-custom">
    <v-breadcrumbs :items="items"></v-breadcrumbs>

    <div class="d-flex" style="margin-top: 45px; margin-left: 10px">
      <div><img src="/img/logo-hc.png" width="80px" height="80px" /></div>
      <div style="margin-left: 30px">
        <div
          class="d-flex button-light-blue"
          v-if="schedule.category === 4"
          style="align-items: center; justify-content: center"
        >
          <img src="/img/other/ic_prakerja.png" width="69" height="22" />
        </div>
        <div class="button-light-blue" v-if="schedule.category !== 4">
          <span>Public Training</span>
        </div>
        <div>
          <span class="title">{{ schedule.name }}</span>
        </div>

        <div class="d-flex" style="margin-top: 16px">
          <div class="d-flex">
            <div>
              <img src="/img/icons/ic_level.png" width="24px" height="24px" />
            </div>
            <span style="margin-left: 10px">{{ $utils.formatLevel(schedule.level) }}</span>
          </div>

          <div class="d-flex" style="margin-left: 20px">
            <div>
              <img
                src="/img/icons/ic_training_method.png"
                width="24px"
                height="24px"
              />
            </div>
            <span style="margin-left: 10px">{{
              schedule.learning_method | formatMethode
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="container-radius" v-if="schedule.status">
      <div class="d-flex">
        <div style="width: 82%">
          <h2>Presensi Kelas</h2>
          <span
            >Klik kehadiran pada saat 5 menit sebelum pelatihan dimulai dan
            setelah pelatihan berakhir</span
          >
        </div>

        <!-- <div class="d-flex" style="align-items:center;cursor: pointer;">
                    <span>LIHAT SEMUA PRESENSI KELAS</span>
                    <img src="/img/icons/ic_arrow_right.png" width="32px" height="32px">
                </div> -->
      </div>

      <div class="d-flex mt-8">
        <div style="width: 20%"></div>
        <div style="width: 20%; text-align: right"><h4>Masuk</h4></div>
        <div style="width: 20%; text-align: right"><h4>Keluar</h4></div>
      </div>

      <div class="d-flex mt-3 bg-ghost-white absent">
        <div style="width: 20%">
          <h4>{{ date }}</h4>
        </div>
        <div style="width: 20%; text-align: right">
          <span>{{ attendance.start_date }}</span>
        </div>
        <div style="width: 22%; text-align: right">
          <span>{{ attendance.end_date }}</span>
        </div>
        <div style="width: 38%; display: flex; justify-content: flex-end">
          <Button
            :name="isAbsen ? 'KONFIRMASI KELUAR' : 'KONFIRMASI HADIR'"
            @click="addAttendance()"
            width="280px"
            height="50px"
            :red="isAbsen"
            v-if="!doneAbsen"
          />
          <v-btn
            depressed
            style="padding: 0px 80px"
            class="btn-radius d-flex confirmed"
            disabled
            v-else
          >
            <BlueCheck /><span
              class="text-capitalize font-weight-bold white--text"
              style="margin-left: 10px"
              >TERKONFIRMASI</span
            ></v-btn
          >
        </div>
      </div>
    </div>

    <h2
      class="mt-10 ml-10"
      v-if="schedule.learning_method == 'Daring' && schedule.status"
    >
      Aktivitas Kelas
    </h2>
    <span
      class="ml-10"
      v-if="schedule.learning_method == 'Daring' && schedule.status"
      >Pastikan HiColers memiliki akun aplikasi Zoom untuk mengikuti pelatihan
      ini</span
    >

    <div
      class="container-radius"
      v-if="schedule.learning_method == 'Daring' && schedule.status"
    >
      <h3>Bergabung dalam Kelas</h3>
      <div class="d-flex mt-10">
        <div class="d-flex" style="width: 100%">
          <div class="box-info">
            <div>
              <img src="/img/icons/ic_date.png" width="30px" height="30px" />
            </div>
            <div>
              <span>{{ schedule.dates }}</span>
            </div>
          </div>

          <div class="vertical-line"></div>

          <div class="box-info">
            <div>
              <img src="/img/icons/ic_clock.png" width="30px" height="30px" />
            </div>
            <div>
              <span>{{ schedule.hours }}</span>
            </div>
          </div>

          <div class="vertical-line"></div>

          <div class="box-info">
            <div>
              <img src="/img/icons/ic_day.png" width="30px" height="30px" />
            </div>
            <div>
              <span>{{ schedule.days }}</span>
            </div>
          </div>
        </div>

        <v-btn
          depressed
          style="background: white"
          class="btn-stoke-blue justify-end"
          @click="goToPage(schedule.learning_link)"
        >
          <span class="text-capitalize font-weight-bold">GABUNG</span>
        </v-btn>
      </div>
    </div>

    <div class="container-radius">
      <div class="d-flex">
        <div style="width: 100%">
          <h2>Materi Pelatihan</h2>
          <span
            >Akses materi sebagai modul pendukung dalam mengikuti
            pelatihan</span
          >
        </div>

        <v-btn
          depressed
          style="background: white"
          class="btn-stoke-blue justify-end"
          @click="goToPage(schedule.material_link)"
        >
          <span class="text-capitalize font-weight-bold">MATERI PELATIHAN</span>
        </v-btn>
      </div>
    </div>

    <div class="container-radius" v-if="schedule.status">
      <div class="d-flex" style="align-items: center">
        <h2 style="width: 100%">Soal</h2>
        <div
          style="cursor: pointer"
          @click="isAssesmentHide = !isAssesmentHide"
        >
          <img
            :src="
              isAssesmentHide === true
                ? '/img/icons/ic_plus.png'
                : '/img/icons/ic_minus.png'
            "
            width="24px"
            height="24px"
          />
        </div>
      </div>

      <div v-if="!isAssesmentHide">
        <span>Kerjakan semua tahapan dengan ketentuan di bawah ini :</span>
        <ul>
          <li>Wajib mengerjakan soal PreTest sebelum kelas di mulai</li>
          <li>
            Soal Quiz wajib dikerjakan ketika sesi pertama selesai dengan
            mendapatkan nilai minimum 80.
          </li>
          <li>
            Soal Post Test wajib dikerjakan ketika sesi kedua selesai dan akan
            menjadi syarat untuk mendapatkan sertifikat
          </li>
        </ul>

        <div
          class="bg-ghost-white d-flex"
          style="align-items: center; margin-top: 50px"
        >
          <div style="width: 100%">
            <h3>PreTest</h3>
            <span>Tes kemampuan dasar sebelum mengikuti pelatihan</span>
          </div>
          <v-btn
            depressed
            style="background: none"
            class="btn-stoke-blue justify-end"
            @click="goToCurrentPage('Pre Test', 1)"
          >
            <span class="text-capitalize font-weight-bold">KERJAKAN</span>
          </v-btn>
        </div>

        <div class="bg-ghost-white" style="margin-top: 20px">
          <div class="d-flex" style="align-items: center; margin-bottom: 20px">
            <div style="width: 100%">
              <h3>Quiz</h3>
              <span
                >Tes untuk mengukur kemampuan & kepahaman terhadap materi
                pelatihan</span
              >
            </div>
          </div>

          <div
            v-for="i in schedule.quizes"
            :key="i.iteration"
            style="padding: 10px 50px"
          >
            <div class="d-flex detail-quizz">
              <h4 style="width: 100%">Quiz Sesi {{ i.iteration }}</h4>
              <v-btn
                depressed
                style="background: none"
                class="btn-stoke-blue justify-end"
                @click="goToCurrentPage('Quiz', i.iteration)"
                :disabled="i.disable"
              >
                <span class="text-capitalize font-weight-bold">KERJAKAN</span>
              </v-btn>
            </div>
            <div class="long-line"></div>
          </div>
        </div>

        <div
          class="bg-ghost-white d-flex"
          style="align-items: center; margin-top: 20px"
        >
          <div style="width: 100%">
            <h3>Post-Test</h3>
            <span
              >Tes evaluasi penyerapan ilmu & pemahaman seluruh materi
              pelatihan</span
            >
          </div>
          <v-btn
            depressed
            style="background: none"
            class="btn-stoke-blue justify-end"
            @click="goToCurrentPage('Post Test', 1)"
          >
            <span class="text-capitalize font-weight-bold">KERJAKAN</span>
          </v-btn>
        </div>
      </div>
    </div>

    <div class="container-radius" v-if="schedule.status">
      <div class="d-flex">
        <div style="width: 100%">
          <h2>Tugas Unjuk Keterampilan</h2>
          <span
            >Unggah Tugas Unjuk Keterampilan untuk mendukung kelengkapan dalam
            pelatihan</span
          >
          <div class="mt-3">
            <span
              >Skema penilaian unjuk keterampilan dapat
              <u @click="goToPage(schedule.rubrik_url)">dilihat disini</u></span
            >
          </div>
          <div class="mt-3">
            <b>Instruksi Unjuk Keterampilan:</b>
            <span v-html="schedule.instruction_final_project"></span>
          </div>
        </div>

        <v-btn
          depressed
          style="background: white"
          class="btn-stoke-blue justify-end"
          @click="goToPage(schedule.final_project_link)"
        >
          <span class="text-capitalize font-weight-bold">UNGGAH</span>
        </v-btn>
      </div>
    </div>

    <div class="container-radius">
      <div class="d-flex" style="align-items: center">
        <div style="width: 100%">
          <h2>Materi Silabus</h2>
          <span>Apa saja yang HiColers pelajari dalam pelatihan ini</span>
        </div>
        <div
          style="cursor: pointer"
          @click="isMateriSilabus = !isMateriSilabus"
        >
          <img
            :src="
              isMateriSilabus === true
                ? '/img/icons/ic_plus.png'
                : '/img/icons/ic_minus.png'
            "
            width="24px"
            height="24px"
          />
        </div>
      </div>

      <div class="mt-5" v-if="!isMateriSilabus">
        <v-list style="background: white">
          <v-list-group
            v-for="item in schedule.syllabuses"
            :key="item.session"
            no-action
            active-class="bg-ghost-white"
            class="bg-ghost-white"
            style="margin-bottom: 10px; padding: 20px"
          >
            <template v-slot:activator>
              <v-list-item-content
                class="font-weight-bold"
                style="margin-bottom;color: #36373f;"
              >
                <v-list-item-title class="custom-text-size">{{
                  item.session
                }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item-content style="padding-left: 15px; color: #36373f">
              <v-list-item-title
                class="custom-desc-text-size"
                v-html="item.description"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-group>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import BlueCheck from "@/assets/icons/BlueCheck.vue";

import Button from "../../others/Button.vue";

export default {
  name: "DDetailClass",
  components: {
    Button,
    BlueCheck,
  },
  data() {
    return {
      isAssesmentHide: true,
      isMateriSilabus: true,
      doneAbsen: false,
      isAbsen: false,
      items: [
        {
          text: "Kelas Aktif",
          disabled: false,
          href: "/active-class",
        },
        {
          text: "Detail Kelas",
          disabled: true,
        },
      ],
      syllabuses: [
        {
          id: 1,
          session: "Pertemuan 1",
          description: "<ul><li>Dasar dalam Desain</li></ul>",
        },
        {
          id: 2,
          session: "Pertemuan 2",
          description: "<ul><li>Dasar dalam Desain</li></ul>",
        },
      ],
      attendance: {},
      schedule: {},
      assesment: {},
      userID: this.$decode_token["id"],
      isAssesmentAvailable: false,
      date: moment(new Date()).format("ddd, DD MMM YYYY"),
    };
  },
  methods: {
    async getClass() {
      await this.$http
        .get(
          "/v1/user-schedule/detail?user_id=" +
            this.userID +
            "&class_id=" +
            this.$route.params.id
        )
        .then((response) => {
          this.schedule = response.data.data.schedule;
          if (this.schedule.category === 4) {
            this.$router.push(`/class/${this.$route.params.id}/session`);  
          }
        })
        .catch((err) => console.error(err.response));
    },
    async addAttendance() {
      await this.$http
        .post("/v1/attendance/student", {
          user_id: parseInt(this.userID),
          class_id: parseInt(this.$route.params.id),
        })
        .then(() => {
          window.location.href = "/class/" + this.$route.params.id + "/detail";
        })
        .catch((err) => console.error(err.response));
    },
    async getAttendance() {
      await this.$http
        .get(
          "/v1/user-attendance?user_id=" +
            this.userID +
            "&class_id=" +
            this.$route.params.id
        )
        .then((response) => {
          this.attendance = response.data.data.attendance;
          if (this.attendance.start_date == "00:00") {
            this.attendance.start_date = "-";
          } else {
            this.attendance.start_date =
              response.data.data.attendance.start_date + " WIB";
          }

          if (this.attendance.end_date == "00:00") {
            this.attendance.end_date = "-";
          } else {
            this.attendance.end_date =
              response.data.data.attendance.end_date + " WIB";
          }
          this.isAbsen = this.attendance.start_date !== "-";
          if (this.isAbsen) {
            this.doneAbsen = this.attendance.end_date !== "-";
          }
        })
        .catch((err) => console.error(err.response));
    },
    async getAssesmentByUserClass() {
      await this.$http
        .get(
          "/v1/user-assesment/detail?user_id=" +
            this.userID + 
            "&class_id=" +
            this.$route.params.id
        )
        .then((response) => {
          this.assesment = response.data.data;
          if (response.data.data == "NO_DATA") {
            this.isAssesmentAvailable = true;
          }
        })
        .catch((err) => console.error(err.response));
    },
    goToPage(e) {
      window.open(
        e,
        "_blank" // <- This is what makes it open in a new window.
      );
    },
    goToCurrentPage(e, g) {
      window.location.href =
        "/question/" + this.$route.params.id + "?c=" + e + "&g=" + g;
    },
  },
  mounted() {
    if (this.$route.query.status == "tamat") {
      this.items[0].text = "Riwayat Kelas";
      this.items[0].href = "/history-class";
      this.items[1].text = "Detail Riwayat Kelas";
    }

    this.getClass();
    this.getAttendance();
    this.getAssesmentByUserClass();
  },
};
</script>

<style scoped>
.absent {
  align-items: center;
  justify-content: center;
  color: #36373f;
  padding: 20px 40px;
}
.btn-radius.confirmed:disabled {
  background-color: #ffffff !important;
  color: #162ca2 !important;
}

.btn-radius.confirmed:disabled .white--text {
  color: #162ca2 !important;
}
</style>
