<template>
  <div class="container-custom">
    <input
      type="file"
      ref="file"
      style="display: none"
      @input="(e) => uploadTask(e)"
      :accept="$utils.applyFormatFile()"
    />
    <Loading v-if="isLoading" />
    <Popup
      v-if="isAlert"
      isError
      :message="messageError"
      @close="() => (isAlert = false)"
    />
    <Modal
      v-if="isModalInfoSesi"
      @close="() => (isModalInfoSesi = false)"
      width="55%"
    >
      <h2 class="modal-title" style="text-align: left">
        Info tentang Sesi Kelas Pelatihan
      </h2>

      <div class="modal-body mt-5" style="text-align: left">
        <ol>
          <li>Seluruh sesi pelatihan <b>tersedia sesuai jadwal</b> yang telah ditentukan. Sesi pertama akan aktif sesuai jadwal dan berstatus “Terkunci”.</li>
          <li>Peserta dapat membuka sesi yang berstatus “Terkunci” dengan melakukan <b>input Kode Redeem & menerapkan Pengecekan Wajah (Face Recognition).</b></li>
          <li>Sesi selanjutnya akan aktif sesuai jadwal dan berstatus “Terkunci” apabila <b>rangkaian aktivitas sesi sebelumnya telah diselesaikan (wajib menyelesaikan Tugas Praktik Mandiri dan Quiz).</b></li>
          <li>Peserta dapat mengakses informasi di setiap sesi apabila sesi tersebut telah aktif & tidak terkunci.</li>
        </ol>
      </div>
    </Modal>
    <div class="list-scrumb" style="margin-top: -30px">
      <div
        v-for="(item, idx) in menus"
        :key="idx"
        class="list-scrumb ml-1 cursor-pointer"
        @click="toGo(item.href)"
      >
        <span>{{ item.text }}</span>
        <arrow strokeColor="#162CA2" direct="right" />
      </div>
      <span class="title-scrumb">{{
        this.$helpers.maxStringLength(schedule.name, 60)
      }}</span>
    </div>
    <div class="d-flex" style="margin-top: 20px; margin-left: 10px">
      <div style="display: flex; justify-content: center; align-items: center">
        <img
          :src="schedule.image_url ? schedule.image_url : '/img/logo-hc.png'"
          width="120px"
          height="120px"
          style="border-radius: 24px"
        />
      </div>
      <div style="margin-left: 25px">
        <div style="display: flex">
          <div
            class="d-flex button-light-blue"
            style="align-items: center; justify-content: center"
          >
            <img src="/img/other/ic_prakerja.png" width="69" height="22" />
          </div>
          <div
            class="d-flex button-light-blue"
            style="
              align-items: center;
              justify-content: center;
              margin-left: 10px;
            "
          >
            <span>Webinar</span>
          </div>
        </div>
        <span class="title" id="font-poppins">{{ schedule.name }}</span>
        <div style="display: flex; padding: 16px 0">
          <div>
            <div class="box-info">
              <img
                class="box-img"
                src="/img/icons/ic_level.png"
                width="30"
                height="30"
              />
              <span>{{ $utils.formatLevel(schedule.level) }}</span>
            </div>
          </div>
          <div>
            <div class="box-info">
              <img
                class="box-img"
                src="/img/icons/ic_training_method.png"
                width="30"
                height="30"
              />
              <span>{{ schedule.learning_method | formatMethode }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="temp-1 mt-6">
      <h2>Sesi Kelas</h2>
      <div class="temp-1 cursor-pointer" @click="isModalInfoSesi = true">
        <Information style="margin-left: 10px" />
      </div>
    </div>
      <div v-for="(item, idx) in schedule.sessions" :key="idx"
        :class="`container-radius cursor-pointer ${ item.is_locked ?  'opacity-1': '' }`"
        @click="goToPage(idx)"
      >
        <div class="d-flex">
          <div style="width: 100%">
            <h3>Sesi {{ idx + 1 }} : {{ item.name }}</h3>
            <span>{{ item.date }}, {{ item.time }}</span>
          </div>
          <div class="opt-session">
            <Padlock v-if="idx >= schedule.current_sequence && schedule.status && !isTester" />
            <img
              :src="'/img/icons/ic_arrow_right.png'"
              width="24px"
              height="24px"
            />
          </div>
        </div>
    </div>
    <div style="margin-top: 80px">
      <div class="d-flex">
        <div style="width: 100%">
          <h2>Tugas Unjuk Keterampilan</h2>
          <span style="max-width: 75%; display: inline-block">
            Unggah Tugas Unjuk Keterampilan untuk mendukung kelengkapan dalam
            pelatihan. Skema penilaian unjuk keterampilan dapat
            <b
              ><span
                style="color: #162ca2"
                @click="$utils.goLink(schedule.rubrik_url)"
                >dilihat di sini</span
              ></b
            >
          </span>
        </div>
        <v-btn
          depressed
          style="background: white"
          class="btn-stoke-blue justify-end gray-button"
          @click="onUploadUK"
          v-if="!schedule.final_project.final_project_url && schedule.status"
        >
          <span v-if="!schedule.final_project.is_final_project_locked" class="text-capitalize font-weight-bold">UNGGAH</span>
          <div v-else style="display: flex; align-items: center; gap: 8px">
            <Padlock />
            <span class="text-capitalize font-weight-bold">TERKUNCI</span>
          </div>
        </v-btn>
        <v-btn v-else depressed style="padding: 0px 30px; background: white" class="btn-radius d-flex confirmed">
          <GrayCheck /><span
            class="text-capitalize font-weight-bold"
            style="margin-left: 10px"
            >SELESAI DIKERJAKAN</span>
        </v-btn>
      </div>
      <div>
        <div class="mt-3">
          <b>Instruksi Unjuk Keterampilan:</b>
          <span v-html="schedule.final_project.instruction_final_project"></span>
          <p class="text-red mt-4">*File yang dapat di upload adalah JPG/JPEG, PNG, DOC, DOCX, XLS, XLSX, TXT, PDF, PPT, PPTX <br>dan maksimal ukuran file 2 MB</p>
        </div>
      </div>
    </div>
    <div class="container-radius" style="padding: 30px; padding-bottom: 5px">
      <div class="d-flex" style="align-items: center">
        <h2 style="width: 100%">Informasi Kelas</h2>
        <div
          style="cursor: pointer"
          @click="isDetailClassHide = !isDetailClassHide"
        >
          <Arrow :down="isDetailClassHide" />
        </div>
      </div>
      <div v-if="isDetailClassHide" class="class-description">
        <h3>Deskripsi Kelas</h3>
        <p>{{ classDetail.description }}</p>
        <p v-html="classDetail.class_detail.learning_objective" class="my-4"></p>
        <h3>Sasaran Peserta</h3>
        <ul>
          <li
            v-for="(item, idx) in classDetail.class_detail.target_audience"
            :key="idx"
            v-html="item.level"
          ></li>
        </ul>
        <h3 class="mt-4">Metode Penyampaian</h3>
        <p v-html="classDetail.class_detail.learning_method"></p>
        <h3>Perangkat Evaluasi</h3>
        <ul>
          <li>Pre-Test</li>
          <li>Kuis</li>
          <li>Tugas Praktik Mandiri</li>
          <li>Post-Test</li>
          <li>Uji Keterampilan</li>
        </ul>
        <h3 class="mt-4">Syarat & Ketentuan Mengikuti Kelas</h3>
        <ul>
          <li v-for="(item, idx) in classDetail.class_detail.requirements" :key="idx">{{ item.name }}</li>
        </ul>
      </div>
    </div>
    <div class="container-radius" style="padding: 30px; padding-bottom: 5px">
      <div class="d-flex" style="align-items: center">
        <h2 style="width: 100%">Tentang Instruktur</h2>
        <div
          style="cursor: pointer"
          @click="isDetailInstructor = !isDetailInstructor"
        >
          <Arrow :down="isDetailInstructor" />
        </div>
      </div>
      <div v-if="isDetailInstructor" class="class-description">
        <div class="narasumber">
            <div style="width: 9%;">
              <img
                :src="$utils.loadImage(instructor.image_url)"
                alt="Profile"
                class="narasumber-img"
              />
            </div>
            <div style="width: 91%;">
              <div>
                <h2 style="margin: 0; padding: 0;">{{instructor.name }}</h2>
                <span class="sub-title-narasumber">{{ instructor.position }} {{ instructor.company ? `/ ${instructor.company }` : ''}}</span>
              </div>
              <p class="description-2">{{ instructor.description }}</p>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from "@/assets/icons/Arrow.vue";
import Padlock from "../../../assets/icons/Padlock.vue";
import Loading from "@/components/others/Loading.vue";
import Popup from "../../others/Popup.vue";
import GrayCheck from "@/assets/icons/GrayCheck.vue";
import Information from "@/assets/icons/Information.vue";
import Modal from '../../Modal.vue';

export default {
  components: {
    Arrow,
    Padlock,
    Loading,
    Popup,
    GrayCheck,
    Information,
    Modal
  },
  name: "DSessionClass",
  data() {
    return {
      menus: [
        {
          text: "Kelas Aktif",
          href: "/active-class",
        },
        {
          text: "Detail Kelas",
          href: "",
        },
      ],
      isLoading: false,
      messageError: "",
      isAlert: false,
      isModalInfoSesi: false,
      isDetailClassHide: true,
      isDetailInstructor: true,
      classDetail: {
        description: "",
        class_detail: {
          requirements: [],
          learning_objective: "",
          target_audience: [],
          learning_method: "",
          special_purpose: "",
        },
      },
      classId: this.$route.params.id,
      userID: this.$decode_token["id"],
      redeem_code: "",
      isActive: false,
      isTester: true,
      instructor: {
        image_url: "",
        name: "",
        position: "",
        company: "",
        description: ""
      },
      schedule: {
        id: 1,
        assessment_id: 0, 
        name: "",
        image_url: "",
        category: 4,
        total_tpm: 0,
        total_quiz: 1,
        learning_method: "",
        dates: "",
        days: "",
        hours: "",
        level: "",
        final_project: {},
        sessions: [{
            name: "",
            date: "",
            time: "",
          },
        ],
      },
    };
  },
  methods: {
    goToPage(idx) {
      if (!this.schedule.status || this.isTester) {
        this.$router.push(`/class/${this.classId}/detail/${idx+1}?done=true`);
        return
      }
      if (this.schedule.sessions[idx].is_locked) return
      if (this.redeem_code) {
        this.$router.push(`/class/${this.classId}/detail/${idx+1}${idx+1 >= this.schedule.current_sequence ? "" : "?done=true"}`);
      } else {
        this.$router.push(`/class/${this.classId}/verification/${idx+1}`);
      }
    },
    async getInstructor() {
      try {
        const res = await this.$web_http.get("/v1/instructor/class/" + this.classId);
        this.instructor = res.data.data.instructor
      } catch (err) {
        console.log(err);
      }
    },
    async getDetailClass() {
      try {
        this.redeem_code = await this.$utils.getRedeem(this.classId)
        const res = await this.$crm_http.get("/v1/class/" + this.classId);
        this.classDetail = res.data.data.class;
      } catch (err) {
        console.log(err);
      }
    },
    async verificationCode() {
      this.isLoading = true
        const data = {
          redeem_code: this.redeem_code,
          class_id: this.classId,
          session: 999
        }
        try {
          const res = await this.$web_http.post(`/v1/prakerja/url-generate`, {
              redeem_code: this.redeem_code,
              email: this.$decode_token["email"],
              class_id: Number(this.classId),
              user_id: this.$decode_token["id"],
              sequence: data.session
          })
          this.$utils.setLocalPrakerja(data)
          window.location.href = res.data.data.url
        } catch (error) {
          this.isAlert = true
          this.messageError = error.response.data.data             
          this.isLoading = false
        }
    },
    async onUploadUK() {
      if (this.schedule.final_project.is_final_project_locked) return
      if (!this.redeem_code) {
        this.$router.push(`/class/${this.classId}/verification/5`);
        return;
      }
      this.isLoading = true
      try {
        const res = await this.$http.get(`/v1/prakerja/status?redeem_code=${this.redeem_code}&sequence=999&class_id=${this.classId}&email=${this.$decode_token["email"]}&user_id=${this.$decode_token["id"]}`);
        this.isLoading = false
        const data = res.data.data;
        if (!data.attendance_status) {
          this.verificationCode()
          return
        }
      } catch (error) {
        this.isAlert = true
        this.messageError = error.response.data.data             
        this.isLoading = false
        return
      }
      this.$refs.file.click();
    },
    async uploadTask(file) {

      const data = this.$utils.getFile(file);
      if (data[1] > 2) {
        this.isAlert = true;
        this.messageError = "Ukuran file tidak boleh lebih besar dari 2 MB";
        return
      }

      this.isLoading = true;
      const formData = new FormData();
      formData.append("attachment", data[0]);

      const res_upload = await this.$crm_http.post(
        "/v1/upload-file",
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );

      const attachment_url = this.$utils.filterLinkClickup(
        res_upload.data.data.upload_image_response.url_w_query
      );

      await this.$http
        .post("/v1/user-assesment/prakerja", {
          assessment_id: this.schedule.assessment_id,
          redeem_code: this.redeem_code,
          sequence: 999,
          url_file: attachment_url,
          scope: "uk",
        })
        .then(() => {
          this.getSchedule();
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.isAlert = true;
          this.messageError = err.response.data.data;
          if (this.messageError == "redeem code tidak ditemukan") {
            this.$router.push(`/class/${this.classId}/verification/5`);
          }
        });
    },
    async getSchedule() {
      this.isLoading = true;
      await this.$http.get("/v2/user-schedule/detail?user_id=" +this.userID +"&class_id=" +this.classId)
        .then((response) => {
          const data = response.data.data.schedule;
          this.schedule = data;
          this.schedule.sessions = data.sessions.map((item) => {
            return {
              ...item,
              name: item.syllabus_description.split("</li>")[0].replace("<ul>", "").replace("<li>", ""),
              time: data.hours,
            };
          });          

          this.isLoading = false;
          if (!this.schedule.status) {
            this.menus[0] = {
              text: "Riwayat Kelas",
              href: "/history-class",
            };
          }
        })
        .catch((err) => {
          console.log(err)
          // this.$router.push("/home");
          this.isLoading = false;
        });
    },
    toGo(herf) {
      window.location.href = herf;
    },
    checkingTester() {
      const listIdTester = this.$utils.listIdTester()
      if (listIdTester.find((item) => item == this.userID)) {
        this.isTester = true
      } else {
        this.isTester = false
      }
    },
  },
  mounted() {
    this.checkingTester()
    this.getSchedule();
    this.getDetailClass();
    this.getInstructor()
  },
};
</script>

<style scoped>
.list-scrumb {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  color: #162ca2;
  font-size: 18px;
}
.title-scrumb {
  color: #7b7e8c;
  font-weight: 400;
}
.opt-session {
  cursor: pointer;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
  color: #7b7e8c;
}
.opacity-1 {
  opacity: 0.6;
  border: 1px solid #d3d6e1;
}
.btn-submit {
  margin: auto;
  display: block;
  font-size: 16px;
  border-radius: 14px !important;
  width: 40%;
}
.container-radius > div {
  margin-bottom: 20px; /* Adjust the value according to the desired space between div elements */
}
.class-description h1, .class-description h2, .class-description h3  {
  padding-top: 20px;
  padding-bottom: 5px;
}
.temp-1 {
  display: flex;
  align-items: center;
}
.narasumber {
  display: flex;
  gap: 40px;
  margin: 15px 0;
}
.narasumber-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}
.narasumber-work {
  color: #7b7e8c;
}
.description-2 {
  margin-top: 5px;
  line-height: 22px;
  text-align: justify;
}

</style>
