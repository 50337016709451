<template>
    <div class="bg">
        <div class="mt-5" @click="goToPreviousPage()">
            <img src="/img/icons/ic_back.png" width="24px" height="24px">
        </div>

        <h3 class="mt-5">No. Handphone</h3>
        <span>Di sini dapat mengatur Nomor Handphone yang HiColers gunakan</span>

        <div class="d-flex mt-5">
            <div class="d-flex" style="justify-content:center;align-items:center">
                <img src="/img/other/indo_flag.png" width="30px" height="20px">
                <span style="margin-left:10px">+ 62</span>
            </div>

            <v-text-field 
                append-icon="mdi-pencil"
                v-model="phoneNumber"
                solo
                clearable
                placeholder="Nomor Handphone"
                class="custom-text-field mt-3 ml-3"
                :rules="[rules.required]"
                required>
            </v-text-field>
        </div>

        <v-btn depressed color="#162CA2" style="width: 100%;" class="btn-radius" @click="updatePhoneNumber()">
            <span class="text-capitalize font-weight-bold white--text" >SIMPAN</span>
        </v-btn>
    </div>
</template>

<script>
export default {
    data () {
        return {
            phoneNumber: '',
            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 5 || 'Min 5 characters',
            },
            data: JSON.parse(localStorage.getItem("account")),
        }
    },
    methods: {
        goToPreviousPage() {
            history.back()
        },
        async updatePhoneNumber() {
            await this.$http
                .put("/v1/account/phone-number", {
                    'phone_number': this.phoneNumber,
                    'email': this.data["email"]
                })
                .then(() => {
                    const req = JSON.stringify({ 
                        "full_name": this.data["full_name"],
                        "email": this.data["email"],
                        "gender": this.data["gender"],
                        "phone_number": this.phoneNumber
                    })
                    localStorage.setItem("account", req);
                    window.location.href = '/setting';
                })
            .catch((err) => console.error(err))
        },
    },
    mounted () {
        this.phoneNumber = this.data["phone_number"]
    }
}
</script>


<style scoped>
.bg {
    padding: 0px 19px;
    min-height: 100vh;
}

.custom-text-field >>> .v-input__control > .v-input__slot:before {
    border-color: inherit;
    border-style: none !important;
    border-width: thin 0 0 0;
}

.custom-text-field >>> .v-input__control > .v-input__slot:after {
    border-color: inherit;
    border-style: none !important;
    border-width: thin 0 0 0;
}

.custom-text-field >>> .v-input__control > .v-input__slot{
    border-radius: 16px;
    background: #F7F9FD;
    width: 100%;
    box-shadow: none !important;
    margin-top: 10px;
}
</style>