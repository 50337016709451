<template>
    <div>
        <DesktopAboutUs v-if="!isMobile" />
        <MobileAboutUs v-else />
    </div>
</template>

<script>
import DesktopAboutUs from '@/components/AboutUs/Desktop/AboutUs'
import MobileAboutUs from '@/components/AboutUs/Mobile/AboutUs'
export default {
  components: {
    DesktopAboutUs,
    MobileAboutUs
  },
  data() {
    return{
      isMobile: false
    }
  },
  methods: {
      handleView() {
          this.isMobile = screen.width <= 990
      }
  },
  beforeMount() {
    this.handleView()
    // this.$gtag.pageview(this.$route)
  }
}
</script>