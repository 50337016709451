<template>
  <div style="background: #f5f5f5">
    <NavbarBack />
    <input
      type="file"
      ref="file"
      style="display: none"
      @input="(e) => uploadTask(e)"
      :accept="$utils.applyFormatFile()"
    />
    <Popup
      v-if="isAlert"
      isError
      :message="messageError"
      @close="() => (isAlert = false)"
    />
    <Loading v-if="isLoading" />
    <Modal
      v-if="isModalPresensi"
      @close="() => (isModalPresensi = false)"
      width="72%"
    >
      <h2 class="modal-title" style="text-align: left">
        Info tentang Presensi Kelas
      </h2>

      <div class="modal-body mt-5" style="text-align: left">
        <ol>
          <li>
            Presensi Awal/Hadir akan aktif dari
            <span class="font-bold"
              >1 jam sebelum kelas berlangsung ({{ time.toleranceStart }})
              hingga 2 jam setelah dimulainya kelas ({{
                time.toleranceEnd
              }})</span
            >.
          </li>
          <li>
            Presensi Akhir/Keluar akan tertampil
            <b>setelah waktu kelas berakhir ({{ time.endTime }}) hingga 23.59.</b> Apabila melewati batas waktu presensi sistem akan merekap presensi secara default dengan <b>status “Terkonfirmasi” pukul 23.59 WIB.</b>
          </li>
          <li>
            Peserta wajib mengisi Presensi Kelas Awal/Hadir & Akhir/Keluar
            sebagai <b>Data Kehadiran Peserta tiap sesi</b>.
          </li>
        </ol>
      </div>
    </Modal>

    <Modal
      v-if="isModalAktivitas"
      @close="() => (isModalAktivitas = false)"
      width="72%"
    >
      <h2 class="modal-title" style="text-align: left">
        Info tentang Aktivitas Kelas
      </h2>

      <div class="modal-body mt-5" style="text-align: left">
        <ol>
          <li>
            Peserta kelas Kartu Prakerja dapat bergabung dalam sesi webinar
            melalui tombol <b>Gabung Kelas</b>
          </li>
          <li>
            Tombol Gabung Kelas akan aktif apabila peserta
            <b>telah melakukan Presensi Awal/Hadir</b>
          </li>
          <li>
            Pastikan <b>memiliki akun Zoom</b> untuk mengikuti pelatihan
            berbasis Webinar.
          </li>
        </ol>
      </div>
    </Modal>
    <div v-if="isBlankScreen" class="container-size">
      <div class="text-center mb-10" style="margin-top:150px"><img src="/img/other/ic_404.png" height="300"></div>
        <div class="text-center font-weight-bold mt-10"><span style="font-size:2.0rem">Access Denied</span></div>
        <div class="text-center mt-5 mb-15">
            <v-btn depressed color="#000080" class="pa-5" @click="$router.push(`/class/${param.class_id}/session`);">
                <span class="text-capitalize white--text font-weight-bold">Kembali ke detail kelasmu</span>
            </v-btn>
        </div>
    </div>
    <div v-else>
    <div class="d-flex mt-7" style="padding: 20px">
      <div><img :src="schedule.image_url" class="img-class" /></div>
      <div style="margin-left: 30px">
        <div
          class="d-flex button-light-blue"
          v-if="schedule.category === 4"
          style="align-items: center; justify-content: center"
        >
          <img src="/img/other/ic_prakerja.png" width="69" height="22" />
        </div>
        <h4
          class="button-light-blue"
          style="width: 120px"
          v-if="schedule.category !== 4"
        >
          Public Training
        </h4>
        <h2 class="title">{{ schedule.name }}</h2>
      </div>
    </div>
    <div class="class-info mt-2">
      <div class="content" style="width: 60px">
        <div>
          <img src="/img/icons/ic_level.png" width="20px" height="20px" />
        </div>
        <span style="margin-left: 10px">{{ $utils.formatLevel(schedule.level) }}</span>
      </div>
      <div class="content">
        <img src="/img/icons/ic_date.png" width="20px" height="20px" />
        <span>{{ schedule.dates }}, {{ schedule.hours }}</span>
      </div>
      <div class="content">
        <img
          src="/img/icons/ic_training_method.png"
          width="20px"
          height="20px"
        />
        <span style="margin-left: 10px">{{
          schedule.learning_method | formatMethode
        }}</span>
      </div>
    </div>
    <div class="container-radius" style="color: #36373f" v-if="schedule.status">
      <div class="d-flex">
        <div>
          <div class="temp-1">
            <h2>Presensi Kelas</h2>
            <div class="temp-1 cursor-pointer" @click="isModalPresensi = true">
              <Information style="margin-left: 10px" />
            </div>
          </div>

          <span
            >Konfirmasi kehadiran peserta tiap sesi dalam pada saat 5 menit
            sebelum pelatihan dimulai dan setelah pelatihan berakhir</span
          >
        </div>
      </div>
      <!-- <div class="d-flex justify-end mt-4" style="align-items:center;cursor: pointer;">
                <span style="font-weight: 600;font-size: 13px;">LIHAT SEMUA PRESENSI KELAS</span>
                <img src="/img/icons/ic_arrow_right.png" width="20px" height="20px">
            </div> -->
      <div class="d-flex mt-8" style="margin-left: 30px">
        <div style="width: 50%"><h4>Masuk</h4></div>
        <div style="width: 50%"><h4>Keluar</h4></div>
      </div>
      <div class="bg-ghost-white mt-3">
        <div class="absent d-flex mb-2 ml-2">
          <div style="width: 50%">
            <span>{{ attendance.start_date }}</span>
          </div>
          <div style="width: 50%">
            <span>{{ attendance.end_date }}</span>
          </div>
        </div>
        <Button
          name="KONFIRMASI HADIR"
          @click="addAttendance()"
          width="100%"
          height="40px"
          v-if="!isAbsen"
          :disabled="time.validationStart > time.now && time.now < time.validationStart"
        />
        <Button
          name="KONFIRMASI KELUAR"
          @click="addAttendance()"
          width="100%"
          height="40px"
          red
          v-if="isAbsen && !doneAbsen && time.now > time.validationEnd"
        />
        <v-btn
          depressed
          style="background: white; border-radius: 12px; width: 100%;"
          class="justify-center gray-button"
          v-if="isAbsen && !doneAbsen && time.now < time.validationEnd"
        >
          <div
          
            style="display: flex; align-items: center; gap: 8px"
          >
            <Padlock />
            <span class="text-capitalize font-weight-bold">TERKUNCI</span>
          </div>
          
        </v-btn>
        <v-btn
            depressed
            style="padding: 0px 80px; width: 100%"
            class="btn-radius d-flex confirmed"
            v-if="isAbsen && doneAbsen"
          >
            <GrayCheck /><span
              class="text-capitalize font-weight-bold"
              style="margin-left: 10px"
              >TERKONFIRMASI</span
            >
          </v-btn>
      </div>
    </div>
    <div v-if="param.session == 1" class="container-radius">
      <h2>Evaluasi Pelatihan Awal</h2>
      <span>Wajib kerjakan soal Pre-Test sebelum kelas di mulai</span>
      <div class="bg-ghost-white mt-5">
          <div>
            <h2>Pre-Test</h2>
            <span>Tes kemampuan dasar sebelum mengikuti pelatihan</span>
          </div>
          <v-btn
            v-if="schedule.status"
            depressed
            style="background: none; width: 100%"
            class="btn-stoke-blue mt-5"
            @click="goToCurrentPage('Pre Test', 1)"
            :disabled="!isAbsen && !isTester"
          >
            <span class="text-capitalize font-weight-bold">KERJAKAN</span>
          </v-btn>
          <v-btn
            v-else
            depressed
            style="padding: 0px 30px; width: 100%"
            class="btn-radius d-flex confirmed"
          >
            <GrayCheck /><span
              class="text-capitalize font-weight-bold"
              style="margin-left: 10px"
              >SELESAI DIKERJAKAN</span
            >
          </v-btn>
        </div>
    </div>
    <div
      class="container-radius"
      v-if="(schedule.learning_method == 'Online' || schedule.learning_method == 'Daring') && schedule.status"
    >
      <div class="temp-1">
        <h2>Aktivitas Kelas</h2>
        <div class="temp-1 cursor-pointer" @click="openModal('AKTIVITAS')">
          <Information style="margin-left: 10px" />
        </div>
      </div>
      <span
        >Masuk ke dalam kelas. Pastikan peserta memiliki akun aplikasi Zoom
        untuk mengikuti pelatihan ini!</span
      >
      <v-btn
        depressed
        style="background: #f7f9fd; width: 100%"
        class="btn-stoke-blue mt-5"
        @click="goToPage(schedule.learning_link)"
        :disabled="(!isAbsen || isAssesmentAvailable) && !isTester"
      >
        <span class="text-capitalize font-weight-bold">GABUNG KELAS</span>
      </v-btn>
    </div>
    <div class="container-radius">
      <div>
        <h2>Materi Pelatihan</h2>
        <span
          >Akses materi sebagai modul pendukung dalam mengikuti pelatihan</span
        >
      </div>
      <v-btn
        depressed
        style="background: white; width: 100%"
        class="btn-stoke-blue mt-5"
        :disabled="(!isAbsen || isAssesmentAvailable) && !isTester"
          @click="goToMateri"
      >
        <span class="text-capitalize font-weight-bold">MATERI PELATIHAN</span>
      </v-btn>
    </div>

    <div class="container-radius">
      <div class="d-flex" style="align-items: center">
        <h2 style="width: 100%">Soal</h2>
        <div
          style="cursor: pointer"
          @click="isAssesmentHide = !isAssesmentHide"
        >
          <img
            :src="
              isAssesmentHide === true
                ? '/img/icons/ic_plus.png'
                : '/img/icons/ic_minus.png'
            "
            width="24px"
            height="24px"
          />
        </div>
      </div>

      <div v-if="!isAssesmentHide">
        <span>Kerjakan semua tahapan dengan ketentuan di bawah ini :</span>
        <ul>
          <li>Wajib mengerjakan soal Pre-Test sebelum kelas di mulai.</li>
          <li>Soal Quiz wajib dikerjakan di setiap sesi pelatihan dengan batas nilai minimum 80 poin, sebagai syarat peserta dapat melanjutkan ke sesi berikutnya.</li>
          <li>Tugas Praktik Mandiri wajib dikerjakan pada tiap sesi.</li>
          <li>Soal Post Test wajib dikerjakan dan dapat diakses apabila seluruh rangkaian Tugas Praktik Mandiri dari sesi 1 sampai 4 selesai dikerjakan.</li>
        </ul>
        <div
          class="bg-ghost-white"
          style="align-items: center; margin-bottom: 20px; margin-top: 20px"
          v-if="param.session <= schedule.total_quiz"
        >
          <div>
            <h2>Formative Test : Quiz {{ param.session }}</h2>
            <span
              >Tes untuk mengukur kemampuan & kepahaman terhadap materi
              pelatihan</span
            >
          </div>
          <v-btn
          v-if="schedule.status"
            style="background: none; width: 100%"
            class="btn-stoke-blue mt-5"
            @click="goToCurrentPage('Quiz',tpmData.iteration)"
            :disabled="(tpmData.is_locked || isAssesmentAvailable || !isAbsen) && !isTester"
            depressed
          >
            <span class="text-capitalize font-weight-bold">KERJAKAN</span>
          </v-btn>
        </div>
        <div
          class="bg-ghost-white"
          style="align-items: center; margin-bottom: 20px; margin-top: 20px"
          v-if="tpmData.instruction_tpm_description"
        >
          <div>
            <h2>Tugas Praktik Mandiri {{ tpmData.iteration }}</h2>
            <span
              >Tes evaluasi untuk mengetahui tingkat penyerapan ilmu melalui
              tugas praktik</span
            >
          </div>
          <div class="mt-3">
            <b>Instruksi Tugas Praktik Mandiri: {{ tpmData.iteration }}</b>
            <p v-html="tpmData.instruction_tpm_description"></p>
          </div>
          <v-btn
            depressed
            style="background: white; width: 100%"
            v-if="!tpmData.is_tpm_completed && schedule.status"
            class="btn-stoke-blue justify-center gray-button"
            :disabled="(!isAbsen || isAssesmentAvailable) && !isTester"
            @click="$refs.file.click()">
            <span class="text-capitalize font-weight-bold">UNGGAH</span>
          </v-btn>
          <v-btn
            depressed
            style="padding: 0px 30px; width: 100%"
            class="btn-radius d-flex confirmed"
            v-else
          >
            <GrayCheck /><span
              class="text-capitalize font-weight-bold"
              style="margin-left: 10px"
              >SELESAI DIKERJAKAN</span
            >
          </v-btn>
          <p class="text-red mt-2">*File yang dapat di upload adalah JPG/JPEG, PNG, DOC, DOCX, XLS, XLSX, TXT, PDF, PPT, PPTX <br> dan maksimal ukuran file 2 MB</p>
        </div>
        <div
          v-if="param.session >= schedule.total_quiz"
          style="align-items: center; margin-top: 20px"
          class="bg-ghost-white"
        >
          <div>
            <h2>Post-Test</h2>
            <span
              >Tes evaluasi penyerapan ilmu & pemahaman seluruh materi
              pelatihan</span
            >
          </div>
          <v-btn
          v-if="schedule.status"
            style="background: none; width: 100%"
            class="btn-stoke-blue mt-5"
            @click="goToCurrentPage('Post Test', 1)"
            :disabled="(!isAbsen || isAssesmentAvailable) && !isTester"
            depressed
          >
            <span class="text-capitalize font-weight-bold">KERJAKAN</span>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import GrayCheck from "@/assets/icons/GrayCheck.vue";
import NavbarBack from "@/components/Navbar/Mobile/NavbarBack";
import moment from "moment";
import Loading from "../../others/Loading.vue";
import Popup from "../../others/Popup.vue";
import Button from "../../others/Button.vue";
import Information from "@/assets/icons/Information.vue";
import Modal from '../../Modal.vue';
import Padlock from "@/assets/icons/Padlock.vue";

export default {
  components: {
    NavbarBack,
    Button,
    Loading,
    Modal,
    Information,
    Popup,
    GrayCheck,
    Padlock
  },
  data() {
    return {
      isModalAktivitas: false,
      isModalPresensi: false,
      isAssesmentHide: false,
      isMateriSilabus: true,
      doneAbsen: false,
      isAbsen: false,
      isAlert: false,
      isLoading: false,
      isBlankScreen: false,
      isTester: false,
      redeem_code: "",
      messageError: "",
      tpmData: {},
      time: {
        toleranceStart: "",
        toleranceEnd: "",
        endTime: "",
        validationStart: "",
        validationEnd: "",
        now: new Date(),
      },
      param: {
        class_id: this.$route.params.id,
        session: this.$route.params.session,
        done: this.$route.query.done,
      },
      attendance: {},
      schedule: {
        list_tpm: []
      },
      assesment: {},
      userID: this.$decode_token["id"],
      isAssesmentAvailable: false,
      date: moment(new Date()).format("ddd, DD MMM YYYY"),
    };
  },
  methods: {
    goToCurrentPage(e, g) {
      window.location.href =
        "/question/" + this.$route.params.id + "?c=" + e + "&g=" + g;
    },
    async getSchedule() {
      this.redeem_code = await this.$utils.getRedeem(this.param.class_id)
      await this.$http
        .get("/v2/user-schedule/detail?user_id=" +this.userID +"&class_id=" +this.$route.params.id)
        .then((response) => {
          this.schedule = response.data.data.schedule;
          this.tpmData =  this.schedule.sessions.find((item) => item.iteration == this.param.session)
          this.validationChecking();
        })
        .catch(() => {
          this.$router.push("/home")
        });
      this.setValidationAbsent();
    },
    setValidationAbsent() {
      const [startTime, endTime] = this.schedule.hours.split(" - ");
      const startTimeString = startTime.replace(".", ":");

      const originalDate = new Date(
        `${moment(this.time.now).format("YYYY-MM-DD")}T${startTimeString}:00`
      );
      const oneHourBeforeDate = new Date(
        originalDate.getTime() - 60 * 60 * 1000
      );
      const twoHoursAfterDate = new Date(
        originalDate.getTime() + 120 * 60 * 1000
      );
      this.time.validationStart = oneHourBeforeDate;
      this.time.validationEnd = twoHoursAfterDate;

      const hours = oneHourBeforeDate.getHours().toString().padStart(2, "0");
      const minutes = oneHourBeforeDate
        .getMinutes()
        .toString()
        .padStart(2, "0");
      this.time.toleranceStart = `${hours}.${minutes} WIB`;

      const hours2 = twoHoursAfterDate.getHours().toString().padStart(2, "0");
      const minutes2 = twoHoursAfterDate
        .getMinutes()
        .toString()
        .padStart(2, "0");
      this.time.toleranceEnd = `${hours2}.${minutes2} WIB`;

      this.time.endTime = endTime;
    },
   async uploadTask(file) {
      const data = this.$utils.getFile(file);
      if (data[1] > 2) {
        this.isAlert = true;
        this.messageError = "Ukuran file tidak boleh lebih besar dari 2 MB";
        return
      }

      this.isLoading = true
      const formData = new FormData();
      formData.append("attachment", data[0]);

      const res_upload = await this.$crm_http.post(
        "/v1/upload-file",
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
      const attachment_url = this.$utils.filterLinkClickup(res_upload.data.data.upload_image_response.url_w_query);
      await this.$http.post("/v1/user-assesment/prakerja", {
          assessment_id: this.assesment.assesment_id,
          redeem_code: this.redeem_code,
          sequence: Number(this.param.session),
          url_file: attachment_url,
          scope: "tpm",
        })
        .then(() => {
          this.getSchedule();
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false;
          this.isAlert = true;
          this.messageError = err.response.data.data;
          if (this.messageError == "redeem code tidak ditemukan" || this.messageError == "Gagal verifikasi, kode tidak ditemukan.") {
            this.$router.push(`/class/${this.param.class_id}/verification/${this.param.session}`); 
          }
        });
    },
    async addAttendance() {
      await this.$http
        .post("/v1/attendance/student", {
          user_id: parseInt(this.userID),
          class_id: parseInt(this.param.class_id),
          now: new Date(),
          sequence: Number(this.param.session),
        })
        .then(() => {
          this.getAttendance();
          this.getAssesmentByUserClass();
        })
        .catch((err) => console.error(err.response));
    },
    async getAttendance() {
      await this.$http
        .get(
          `/v1/user-attendance?user_id=${this.userID}&class_id=${this.param.class_id}&sequence=${this.param.session}`
        )
        .then((response) => {
          this.attendance = response.data.data.attendance;
          if (this.attendance.start_date == "00:00") {
            this.attendance.start_date = "-";
          } else {
            this.attendance.start_date =
              response.data.data.attendance.start_date + " WIB";
          }

          if (this.attendance.end_date == "00:00") {
            this.attendance.end_date = "-";
          } else {
            this.attendance.end_date =
              response.data.data.attendance.end_date + " WIB";
          }
          this.isAbsen = this.attendance.start_date !== "-";
          if (this.isAbsen) {
            this.doneAbsen = this.attendance.end_date !== "-";
          }
        })
        .catch((err) => console.error(err.response));
    },
    async getAssesmentByUserClass() {
      await this.$http
        .get(
          "/v1/user-assesment/detail?user_id=" +
            this.userID +
            "&class_id=" +
            this.$route.params.id
        )
        .then((response) => {
          if (response.data.data == "NO_DATA") {
            this.isAssesmentAvailable = true;
          } else {
            this.assesment = response.data.data.assesment;
          }
        })
        .catch((err) => console.error(err.response));
    },
    validationChecking() {
      if (!this.schedule.status || this.isTester) return;
      if (this.param.done && this.tpmData.is_locked) {
        this.$router.push(`/class/${this.param.class_id}/session`);
        return;
      }
      if (!this.redeem_code && !this.param.done) {
        console.log("running")
        this.$router.push(`/class/${this.param.class_id}/session`);
        return
      }
      if (!this.param.done) {
        this.checkStatus();
      }
    },
    async checkStatus() {
      try {
        const res = await this.$http.get(
          `/v1/prakerja/status?redeem_code=${this.redeem_code}&sequence=${this.param.session}&class_id=${this.param.class_id}&email=${this.$decode_token["email"]}&user_id=${this.$decode_token["id"]}`
        );
        const data = res.data.data
        if (!data.attendance_status ||this.param.session > res.sequence) {
          if (!data.attendance_status || this.param.session > res.sequence) {
            this.verificationCode()
          }
        }
      } catch (error) {
        this.isAlert = true
        this.messageError = error.response.data.data             
        this.isBlankScreen = true
        if (this.messageError == "redeem code tidak ditemukan" || this.messageError == "Gagal verifikasi, kode tidak ditemukan.") {
          this.$router.push(`/class/${this.param.class_id}/verification/${this.param.session}`); 
        }
      }
    },
    async verificationCode() {
        const data = {
                redeem_code: this.redeem_code,
                class_id: this.param.class_id,
                session: this.param.session
            }
            try {
                const res = await this.$web_http.post(`/v1/prakerja/url-generate`, {
                    redeem_code: this.redeem_code,
                    email: this.$decode_token["email"],
                    class_id: Number(this.param.class_id),
                    user_id: this.$decode_token["id"],
                    sequence: Number(this.param.session)
                })
                this.$utils.setLocalPrakerja(data)
                window.location.href = res.data.data.url
            } catch (error) {
                this.isAlert = true
                this.messageError = error.response.data.data             
                this.isBlankScreen = true
            }
    },
    checkingTester() {
      const listIdTester = this.$utils.listIdTester()
      if (listIdTester.find((item) => item == this.userID)) {
        this.isTester = true
      } else {
        this.isTester = false
      }
    },
    goToPage(e) {
      window.open(e);
    },
    goToMateri() {
      if (this.tpmData.material_link) {
          window.open(this.tpmData.material_link);
          return;
        }
        window.open(this.schedule.rubrik_url);
    },
    openModal(type) {
      if (type === "PRESENSI") {
        this.isModalPresensi = true;
      } else if (type === "AKTIVITAS") {
        this.isModalAktivitas = true;
      }
    },
  },
  mounted() {
    this.checkingTester()
    this.getSchedule();
    this.getAttendance();
    this.getAssesmentByUserClass();
  },
};
</script>

<style scoped>
li {
  font-size: 12px;
}

.title {
  font-weight: bold;
  font-size: 15px !important;
  line-height: 20px !important;
  color: #36373f;
}

span {
  font-size: 12px;
}

.container-radius {
  margin-top: 35px;
  padding: 19px;
  background: #ffffff;
  border-radius: 24px;
}

h2 {
  font-size: 17px;
}

h4 {
  font-size: 12px;
}

.absent {
  align-items: center;
  color: #36373f;
}
.img-class {
  width: 70px;
  height: 70px;
  border-radius: 18px;
}
.container-radius {
  margin-top: 15px;
  text-align: justify;
  padding: 19px;
  background: #ffffff;
  border-radius: 24px;
}
.class-info {
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 80%;
  margin: auto;
  margin-top: -90px;
}
.class-info .content {
  align-items: flex-start;
  gap: 8px;
  display: flex;
  width: 150px;
}
.temp-1 {
  display: flex;
  align-items: center;
}
</style>
