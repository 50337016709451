<template>
    
<svg :width="width"
    :height="height" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24 10.6663H22.6666V7.99967C22.6666 4.31967 19.6799 1.33301 15.9999 1.33301C12.3199 1.33301 9.33328 4.31967 9.33328 7.99967V10.6663H7.99995C6.53328 10.6663 5.33328 11.8663 5.33328 13.333V26.6663C5.33328 28.133 6.53328 29.333 7.99995 29.333H24C25.4666 29.333 26.6666 28.133 26.6666 26.6663V13.333C26.6666 11.8663 25.4666 10.6663 24 10.6663ZM15.9999 22.6663C14.5333 22.6663 13.3333 21.4663 13.3333 19.9997C13.3333 18.533 14.5333 17.333 15.9999 17.333C17.4666 17.333 18.6666 18.533 18.6666 19.9997C18.6666 21.4663 17.4666 22.6663 15.9999 22.6663ZM11.9999 10.6663V7.99967C11.9999 5.78634 13.7866 3.99967 15.9999 3.99967C18.2133 3.99967 19.9999 5.78634 19.9999 7.99967V10.6663H11.9999Z" fill="currentColor"/>
</svg>

</template>
<script>
export default {
  name: "Padlock",
  props: {
    width: {
      type: Number,
      default: 27,
    },
    height: {
      type: Number,
      default: 27,
    },
  },
};
</script>
