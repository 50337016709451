<template>
    <div class="d-flex bg">
        <div @click="goToPreviousPage" class="d-flex" style="align-items:center"><img src="/img/icons/ic_back.png" width="24px" height="24px"></div>
        <div><span style="margin-left: 20px">{{ title }}</span></div>
    </div>
</template>

<script>
export default{
    data () {
        return {
            title: ''
        }
    },
    methods: {
        goToPreviousPage() {
            history.back()
        }
    },
    mounted () {
        this.title = this.$route.name
    }
}
</script>

<style scoped>
.bg {
    background: #FFFFFF;
    box-shadow: 0px 1px 3px 1px rgba(13, 28, 113, 0.1);
    padding: 12px 19px;
    align-items: center;
}
</style>