<template>
    <div class="container-custom-body">
        <Popup
            v-if="isPopup"
            :isError="typePopup"
            :message="message"
            @close="() => (isPopup = false)"
            />
        <Loading v-if="isLoading" />

        <h2 style="margin-bottom:30px">Pengaturan</h2>

        <div class="bg-white-radius">
            <div class="d-flex" style="align-items:center">
                <h2 style="width:100%">Akun</h2>
                <div style="cursor:pointer" @click="isAccountMenuHide = !isAccountMenuHide"><img :src="isAccountMenuHide === true ? '/img/icons/ic_arrow_down.png' : '/img/icons/ic_arrow_up.png'" width="24px" height="24px"></div>
            </div>

            <div style="margin-top:30px" v-if="!isAccountMenuHide">
                <h3>Title</h3>
                <v-autocomplete
                    ref="gender"
                    v-model="gender"
                    :items="genders"
                    label="Titel"
                    placeholder="Select..."
                    required
                    solo
                    class="gender-dropdown"
                ></v-autocomplete>

                <h3>Nama Lengkap</h3>
                <v-text-field 
                    append-icon="mdi-pencil"
                    label="Nama Lengkap" 
                    v-model="fullname"
                    solo
                    clearable
                    placeholder="Nama Lengkap"
                    class="custom-text-field"
                    :rules="[rules.required]"
                    required>
                </v-text-field>

                <v-btn depressed color="#162CA2" style="padding:0px 80px;" class="btn-radius d-flex" @click="updateAccount()">
                    <span class="text-capitalize font-weight-bold white--text" >SIMPAN</span>
                </v-btn>
            </div>
        </div>

        <div class="bg-white-radius" style="margin-top:30px">
            <div class="d-flex" style="align-items:center">
                <h2 style="width:100%">Nomor Handphone</h2>
                <div style="cursor:pointer" @click="isPhoneNumberMenuHide = !isPhoneNumberMenuHide"><img :src="isPhoneNumberMenuHide === true ? '/img/icons/ic_arrow_down.png' : '/img/icons/ic_arrow_up.png'" width="24px" height="24px"></div>
            </div>
            <div v-if="!isPhoneNumberMenuHide" style="margin-top:30px">

            
            <div class="d-flex" >
                <div style="margin-right:40px">
                    <span>Kode Negara</span>
                    <div class="d-flex" style="justify-content:center;align-items:center">
                        <img src="/img/other/indo_flag.png" width="30px" height="20px">
                        <span style="margin-left:10px">+ 62</span>
                    </div>
                </div>

                <v-text-field 
                    append-icon="mdi-pencil"
                    v-model="phoneNumber"
                    solo
                    clearable
                    placeholder="Nomor Handphone"
                    class="custom-text-field"
                    :rules="[rules.required]"
                    required>
                </v-text-field>
            </div>
            <v-btn depressed color="#162CA2" style="padding:0px 80px;" class="btn-radius d-flex" @click="updatePhoneNumber()">
                    <span class="text-capitalize font-weight-bold white--text" >SIMPAN</span>
                </v-btn>
            </div>
        </div>

        <div class="bg-white-radius" style="margin-top:30px">
            <div class="d-flex" style="align-items:center">
                <div style="width:100%">
                    <h2 >Ubah Kata Sandi</h2>
                    <span>Atur Kata Sandi untuk keamanan akunmu</span>
                </div>
                <div style="cursor:pointer" @click="isChangePasswordMenuHide = !isChangePasswordMenuHide"><img :src="isChangePasswordMenuHide === true ? '/img/icons/ic_arrow_down.png' : '/img/icons/ic_arrow_up.png'" width="24px" height="24px"></div>
            </div>

            <div style="margin-top:30px" v-if="!isChangePasswordMenuHide">
                <h3>Kata Sandi Saat Ini</h3>
                <v-text-field 
                    :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show_password ? 'text' : 'password'"
                    label="Masukkan Kata Sandi Saat ini" 
                    filled
                    clearable
                    class="custom-text-field"
                    placeholder="Password"
                    :rules="[rules.min]"
                    @click:append="show_password = !show_password"
                    v-model="old_password"
                    required>
                </v-text-field>

                <div class="long-line" style="width: 840px !important;height:2px;margin-bottom: 30px;"></div>

                <h3>Kata Sandi Baru</h3>
                <v-text-field 
                    :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show_password ? 'text' : 'password'"
                    label="Masukkan Kata Sandi Baru" 
                    filled
                    clearable
                    class="custom-text-field"
                    placeholder="Password"
                    :rules="[rules.min]"
                    @click:append="show_password = !show_password"
                    v-model="new_password"
                    required>
                </v-text-field>

                <div class="d-flex justify-end" style="margin-bottom:30px">
                    <div>
                        <span>Kata Sandi harus memiliki</span>
                        <ul>
                            <li>Minimum 8 karakter</li>
                            <li>Kombinasi angka, huruf besar, huruf kecil dan simbol</li>
                        </ul>
                    </div>
                </div>

                <v-btn depressed color="#162CA2" style="padding:0px 80px;" class="btn-radius d-flex" @click="updateCurrentPasssword()">
                    <span class="text-capitalize font-weight-bold white--text" >SIMPAN</span>
                </v-btn>
            </div>
        </div>
        <!-- <div class="bg-white-radius" style="margin-top:30px">
            <div class="d-flex" style="align-items:center">
                <h2 style="width:100%">List Kode Redeem</h2>
                <div style="cursor:pointer" @click="isRedeemMenuHide = !isRedeemMenuHide"><img :src="isRedeemMenuHide === true ? '/img/icons/ic_arrow_down.png' : '/img/icons/ic_arrow_up.png'" width="24px" height="24px"></div>
            </div>

            <div style="margin-top:30px" v-if="!isRedeemMenuHide">
                <div v-for="(item, idx) in listRedeemCode" :key="idx">
                    <h3>Kelas ID: {{ item.class_id }}</h3>
                    <v-text-field 
                    filled
                    clearable
                    type="text"
                    class="custom-text-field"
                    placeholder="Redeem Code"
                    v-model="item.redeem_code"
                    required>
                </v-text-field>
                </div>
                <v-btn depressed color="#162CA2" style="padding:0px 80px;" class="btn-radius d-flex" @click="updateRedeem">
                    <span class="text-capitalize font-weight-bold white--text" >SIMPAN</span>
                </v-btn>
            </div>
        </div> -->

    </div>
</template>

<script>
import Loading from "../../others/Loading.vue";
import Popup from "../../others/Popup.vue";

export default {
    data () {
        return {
            isAccountMenuHide: true,
            isRedeemMenuHide: true,
            isPhoneNumberMenuHide: true,
            isChangePasswordMenuHide: true,
            old_password: '',
            isLoading: false,
            isPopup: false,
            typePopup: true,
            message: "",
            new_password: '',
            listRedeemCode: [],
            show_password: false,
            gender: '',
            genders: ['Tuan', 'Nyonya', 'Nona'],
            fullname: '',
            phoneNumber: '',
            modal: false,
            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 5 || 'Min 5 characters',
            },
            data: {}
        }
    },
    components: {
        Popup,
        Loading
    },
    methods: {
        async updateAccount() {
            this.isLoading = true
            await this.$http
                .put("/v1/account/profile", {
                    'gender': this.gender,
                    'full_name': this.fullname,
                    'email': this.data["email"]
                })
                .then(() => {
                    const req = JSON.stringify({ 
                        "full_name": this.fullname,
                        "email": this.data["email"],
                        "gender": this.gender,
                        "phone_number": this.data["phone_number"]
                    })
                    localStorage.setItem("account", req);
                    this.getData()
                    this.isPopup = true
                    this.typePopup = false
                    this.message = "Profile berhasil diperbarui"
                    this.isLoading = false
                })
            .catch(() => {
                this.isLoading = false
                this.isPopup = true
                this.typePopup = true
                this.message = "Terjadi kesalahan saat memperbarui data"    
            })
        },
        async updatePhoneNumber() {
            this.isLoading = true
            await this.$http
                .put("/v1/account/phone-number", {
                    'phone_number': this.phoneNumber,
                    'email': this.data["email"]
                })
                .then(() => {
                    const req = JSON.stringify({ 
                        "full_name": this.data["full_name"],
                        "email": this.data["email"],
                        "gender": this.data["gender"],
                        "phone_number": this.phoneNumber
                    })
                    localStorage.setItem("account", req);
                    this.getData()
                    this.isPopup = true
                    this.typePopup = false
                    this.message = "Nomor Telpon berhasil diperbarui"
                    this.isLoading = false  
                })
            .catch(() => {
                this.isLoading = false
                this.isPopup = true
                this.typePopup = true
                this.message = "Terjadi kesalahan saat memperbarui data"
            })
        },
        // async updateRedeem() {
        //     this.isLoading = true
        //     for (let i = 0; i < this.listRedeemCode.length; i++) {
        //         const element = this.listRedeemCode[i];
        //         try {
        //             await this.$http.get(`/v1/prakerja/status?redeem_code=${element.redeem_code}&sequence=${element.sequence}&class_id=${element.class_id}&email=${this.$decode_token["email"]}&user_id=${this.$decode_token["id"]}`);
        //         } catch (err) {
        //             this.isPopup = true
        //             this.typePopup = true
        //             this.message = err.response.data.data
        //             this.isLoading = false
        //             return
        //         }
        //     }
        //     localStorage.setItem("pra-key", JSON.stringify(this.listRedeemCode));
        //     this.getData()
        //     this.isLoading = false
        //     this.isPopup = true
        //     this.typePopup = false
        //     this.message = "Redeem Code berhasil diperbarui"  
        // },
        async updateCurrentPasssword() {
            this.isLoading = true
            await this.$http
                .put("/v1/account/current-password", {
                    'old_password': this.old_password,
                    'new_password': this.new_password,
                    'email': this.data["email"]
                })
                .then(() => {
                    this.getData()
                    this.isPopup = true
                    this.typePopup = false
                    this.message = "Password berhasil diperbarui"
                    this.isLoading = false
                })
            .catch(() => {
                this.isLoading = false
                this.isPopup = true
                this.typePopup = true
                this.message = "Terjadi kesalahan saat memperbarui data"
            })
        },
        getData() {
            this.data = JSON.parse(localStorage.getItem("account"))
            this.fullname = this.data["full_name"]
            this.phoneNumber = this.data["phone_number"]
            this.gender = this.data["gender"]
            this.listRedeemCode = JSON.parse(localStorage.getItem("pra-key"))
        }
    },
    mounted () {
        this.getData()
    }
}
</script>
<style scoped>
.bg-white-radius {
    background: #FFFFFF;
    border-radius: 24px;
    padding: 25px 40px;
}

.gender-dropdown {
    width: 360px;
    margin-top: 16px;
    box-shadow: none !important;
}

.gender-dropdown >>> .v-input__control > .v-input__slot:before {
    border-color: #7B7E8C !important;
    box-shadow: none !important;
    
}

.gender-dropdown >>> .v-input__control > .v-input__slot:after {
    border-color: #7B7E8C !important;
    box-shadow: none !important;
}

.gender-dropdown >>> .v-input__control > .v-input__slot{
    border-radius: 16px;
    background: #F7F9FD;
    border-style: none;
    box-shadow: none !important;
}

.custom-text-field >>> .v-input__control > .v-input__slot:before {
    border-color: inherit;
    border-style: none !important;
    border-width: thin 0 0 0;
}

.custom-text-field >>> .v-input__control > .v-input__slot:after {
    border-color: inherit;
    border-style: none !important;
    border-width: thin 0 0 0;
}

.custom-text-field >>> .v-input__control > .v-input__slot{
    border-radius: 16px;
    background: #F7F9FD;
    width: 840px;
    box-shadow: none !important;
    margin-top: 10px;
}


</style>