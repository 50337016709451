<template>
    <button :class="`button-cust ${!disabled ? 'button' : 'button-disabled'}`" :style="`width: ${width}; height: ${height};`" @click="onSubmit" v-if="!red" :title="title">
      <slot />
        {{ name }}
      </button>
      <button v-else :class="`button-cust ${!disabled ? 'button-red' : 'button-red-disabled'}`" :style="`width: ${width}; height: ${height};`" @click="onSubmit" :title="title">
        <slot />
        {{ name }}
      </button>
</template>

<script>
export default {
    name: "ButtonComponent",
    props: {
        name: {
            type: String,
            default: ""
        },
        width: {
            type: String,
            default: "150px"
        },
        height: {
            type: String,
            default: "45px"
        },
        param: {
            type: [String, Number]
        },
        title: {
          type: String
        },
        red: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    methods: {
      onSubmit() {
        if (this.disabled) return
        this.$emit('click', this.param)
      }
    }
}
</script>

<style scoped>
.button {
  display: flex;
  font-weight: bold !important;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  font-size: 17px;
  color: white;
  background-color: #162ca2;
}
.button:hover {
  background-color: #132791;
}
.button:active {
  color: #112381;
}
.button-disabled {
  background-color: #ACAFBF !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border-radius: 12px;
  font-weight: bold !important;
  cursor: pointer;
  font-size: 17px;
  color: white;
}
.button-red {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  font-weight: bold !important;
  cursor: pointer;
  font-size: 17px;
  color: white;
  background-color: #B80F0A;
}
.button-red:hover {
  background-color: #A50D09;
}
.button-red:active {
  color: #930C08;
}
.button-red-disabled {
  background-color: #ACAFBF !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border-radius: 12px;
  font-weight: bold !important;
  cursor: pointer;
  font-size: 17px;
  color: white;
}
.button-cust {
  display: flex;
  gap: 10px;
}

@media only screen and (max-width: 990px) {
  .button-red-disabled {
    font-size: 14px;
  } 
  .button-red {
    font-size: 14px;
  } 
  .button-disabled {
    font-size: 14px;
  } 
  .button {
    font-size: 14px;
  } 
}

</style>