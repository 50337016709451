<template>
  <div style="    background-color: #F5F5F5; min-height: 100vh;">
    <div class="popup" id="success" v-if="isSuccess">
      <div class="popup-content">
        <div class="imgbox">
          <img
            src="/img/prakerja/success_notification.jpg"
            alt=""
            class="img"
          />
        </div>
        <div class="title">
          <h3>Verifikasi Berhasil!</h3>
        </div>
        <p class="para">
          Selamat! Verifikasi data peserta Kartu Prakerja telah berhasil
          dilakukan. Silahkan akses LMS-HiColleagues untuk mengikuti kelas!
        </p>
        <div class="button mt-7" @click="$router.push('/home?feedback=a')">
          KEMBALI KE LMS
        </div>
      </div>
    </div>
    <div class="popup" id="error" v-else>
      <div class="popup-content">
        <div class="imgbox">
          <img
            src="/img/prakerja/failure_notification.jpg"
            alt=""
            class="img"
          />
        </div>
        <div class="title">
          <h3>{{ error }}</h3>
        </div>
        <p class="para">{{ errorDescription }}</p>

        <div class="option-button">
          <div class="button" @click="$router.push('/home?feedback=b')">
            VERIFIKASI
          </div>
          <div class="button-2" @click="$router.push('/home?feedback=c')">
            BATAL
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VerificationCallback",
  data() {
    return {
      isSuccess: false,
      error: "",
      errorDescription: "",
    };
  },
  mounted() {
    if (!this.$route.query.error) {
      this.isSuccess = true;
    } else {
      this.isSuccess = false;
      this.error = this.$route.query.error;
      this.errorDescription = this.$route.query.error_description;
    }
  },
};
</script>

<style scoped>
#success {
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#error {
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popup-content {
  /* display: block; */
  max-width: 450px;
  width: 100%;
  padding: 40px;
  background: white;
  box-shadow: 2px 2px 10px 3px rgba(13, 28, 113, 0.1) !important;
  border-radius: 25px;
}

.option-button {
  margin-top: 15px;
  display: flex;
  gap: 15px;
  justify-content: center;
}

.imgbox {
  margin-top: 30px;
  text-align: center;
}

.img {
  width: 290px;
}

.title {
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 0.05em;
  text-align: center;
  margin: auto;
  margin-top: 10px;
}

.para {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.05em;
  text-align: center;
  margin: auto;

  margin-top: 10px;
  /* margin-top: 80px; */
}

.button {
  width: 70%;
  margin: 20px auto;
  border-radius: 16px;
  padding: 25px 10px;
  background: #162ca2;
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  height: 35px;
  text-decoration: none;
  color: white;
  text-align: center;
  letter-spacing: 0.05em;
  cursor: pointer;
}

.button-2 {
    cursor: pointer;
  margin: 20px auto;
  border: 1px solid #acafbf;
  width: 170px;
  border-radius: 16px;
  padding: 25px 10px;
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  height: 35px;
  text-decoration: none;
  color: #575966;
  letter-spacing: 0.05em;
}

</style>
