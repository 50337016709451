<template>
    <div >
        <div class="d-flex sub-menu" style="margin-top:20px;" :class="{actived: layout === 'Beranda'}" @click="homeMenu">
            <div><img :src="layout == 'Beranda' ? '/img/other/ic_home_blue.png' : '/img/other/ic_home.png'" width="24px" height="24px"></div>
            <div style="font-size:14px;margin-left: 19px;"><span>Beranda</span></div>
        </div>

        <div class="d-flex sub-menu" style="margin-top:20px;" :class="{actived: isActivity === true || parentActivity === true}" @click="classMenu">
            <div><img :src="isActivity === true || parentActivity === true ? '/img/other/ic_activity_blue.png' : '/img/other/ic_activity.png'" width="24px" height="24px"></div>
            <div style="font-size:14px;margin-left: 19px;"><span>Aktivitas</span></div>
        </div>

        <div style="margin-left:45px">
            <div class="d-flex sub-menu" :class="{actived: layout === 'Kelas Aktif'}" @click="classMenu">
                <div style="margin-top:4px"><img :src="layout === 'Kelas Aktif' ? '/img/other/ic_active_class_blue.png' : '/img/other/ic_active_class.png'" width="24px" height="24px"></div>
                <div style="margin-left: 20px;"><span>Kelas Aktif</span></div>
            </div>

            <div class="d-flex sub-menu" :class="{actived: layout === 'Penilaian'}" @click="assesmentMenu">
                <div style="margin-top:4px"><img :src="layout === 'Penilaian' ? '/img/other/ic_assesment_blue.png' : '/img/other/ic_assesment.png'" width="24px" height="24px"></div>
                <div style="margin-left: 20px;"><span>Penilaian</span></div>
            </div>

            <div class="d-flex sub-menu" :class="{actived: layout === 'Sertifikat'}" @click="certificateMenu">
                <div style="margin-top:4px"><img :src="layout === 'Sertifikat' ? '/img/other/ic_certificate_blue.png' : '/img/other/ic_certificate.png'" width="24px" height="24px"></div>
                <div style="margin-left: 20px;"><span>Sertifikat</span></div>
            </div>

            <div class="d-flex sub-menu" :class="{actived: layout === 'Riwayat Kelas'}" @click="historyClassMenu">
                <div style="margin-top:4px"><img :src="layout === 'Riwayat Kelas' ? '/img/other/ic_history_class_blue.png' : '/img/other/ic_history_class.png'" width="24px" height="24px"></div>
                <div style="margin-left: 20px;"><span>Riwayat Kelas</span></div>
            </div>
            <div class="d-flex sub-menu" :class="{actived: layout === 'Ulasan'}" @click="reviewClassMenu">
                <div style="margin-top:4px"><img :src="layout === 'Ulasan' ? '/img/other/ic_chat_blue.png' : '/img/other/ic_chat.png'" width="24px" height="24px"></div>
                <div style="margin-left: 20px;"><span>Reflective Journal</span></div>
            </div>
        </div>

        <div class="d-flex sub-menu" style="margin-top:20px;" :class="{actived: layout === 'Profile'}" @click="profileMenu">
            <div><img :src="layout === 'Profile' ? '/img/other/ic_profile_fill_blue.png' : '/img/other/ic_profile_fill.png'" width="24px" height="24px"></div>
            <div style="font-size:14px;margin-left: 19px;"><span>Akun</span></div>
        </div>

        <div class="long-line"></div>

        <div class="d-flex sub-menu" :class="{actived: layout === 'FAQ'}" @click="faqMenu">
            <div style="margin-top:4px"><img :src="layout === 'FAQ' ? '/img/other/ic_faq_blue.png' : '/img/other/ic_faq.png'" width="24px" height="24px"></div>
            <div style="margin-left: 20px;"><span>FAQ</span></div>
        </div>

        <div class="d-flex sub-menu" :class="{actived: layout === 'AboutUs'}" @click="aboutUsMenu">
            <div style="margin-top:4px"><img :src="layout === 'AboutUs' ? '/img/other/ic_about_us_blue.png' : '/img/other/ic_about_us.png'" width="24px" height="24px"></div>
            <div style="margin-left: 20px;"><span>Tentang Kami</span></div>
        </div>

        <div class="d-flex sub-menu" :class="{actived: layout === 'PrivacyTerms'}" @click="privacyTermsMenu">
            <div style="margin-top:4px"><img :src="layout === 'PrivacyTerms' ? '/img/other/ic_doc_blue.png' : '/img/other/ic_doc.png'" width="24px" height="24px"></div>
            <div style="margin-left: 20px;"><span>Ketentuan Privasi</span></div>
        </div>
        <div class="d-flex sub-menu" :class="{actived: layout === 'TermCondition'}" @click="termConditionMenu">
            <div style="margin-top:4px"><img :src="layout === 'TermCondition' ? '/img/other/ic_doc_blue.png' : '/img/other/ic_doc.png'" width="24px" height="24px"></div>
            <div style="margin-left: 20px;"><span>Syarat & Ketentuan</span></div>
        </div>

        <div class="d-flex sub-menu" @click="logout">
            <div style="margin-top:4px"><img src="/img/other/ic_logout.png" width="24px" height="24px"></div>
            <div style="margin-left: 20px;"><span>Keluar</span></div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            isAccount: false,
            isActivity: false,
            layout: 'Home',
            parentActivity: false,
        }
    },
    methods: {
        homeMenu() {
            this.layout = "Home",
            window.location.href = '/home';
        },
        classMenu() {
            window.location.href = '/active-class';
        },
        assesmentMenu() {
            window.location.href = '/assesment';
        },
        certificateMenu() {
            window.location.href = '/certificate';
        },
        historyClassMenu() {
            this.layout = "HistoryClass",
            window.location.href = '/history-class';
        },
        profileMenu() {
            this.layout = "ProfileMenu",
            window.location.href = '/profile';
        },
        faqMenu() {
            window.location.href = '/faq';
        },
        aboutUsMenu() {
            this.layout = "AboutUs",
            window.location.href = '/about-us';
        },
        reviewClassMenu() {
            window.location.href = '/review';
        },
        privacyTermsMenu() {
            this.layout = "PrivacyTerms",
            window.location.href = '/privacy-terms'
        },
        termConditionMenu() {
            this.layout = "TermCondition",
            window.location.href = '/term-condition'
        },
        logout(){
            // localStorage.removeItem("user");
            localStorage.clear();
            window.location.href = 'https://www.hicolleagues.com'
        },
    },
    mounted () {
        this.layout = this.$route.name
    }
}
</script>

<style scoped>
.actived {
    color: #162CA2 !important;
    background: #F1F6FF;
    border-radius: 10px;
    padding: 8px;
    font-weight: bold;
    
}

.sub-menu {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
}
</style>