<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 30 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0001 2.25348C15.1391 2.25101 15.2765 2.28308 15.4001 2.34682L27.0001 8.62682C27.1395 8.69678 27.2555 8.80601 27.3337 8.94104C27.4119 9.07608 27.4488 9.23103 27.4401 9.38682C27.4357 9.54321 27.387 9.69514 27.2996 9.82495C27.2123 9.95476 27.0899 10.0571 26.9467 10.1201L24.2001 11.3335L23.0134 11.8535V17.7468C23.0122 17.8766 22.9739 18.0032 22.903 18.1119C22.8321 18.2206 22.7317 18.3067 22.6134 18.3601L15.2801 21.6935C15.1877 21.7125 15.0924 21.7125 15.0001 21.6935C14.908 21.7151 14.8122 21.7151 14.7201 21.6935L7.44008 18.3735C7.32184 18.3201 7.22135 18.2339 7.15048 18.1253C7.0796 18.0166 7.0413 17.8899 7.04008 17.7601V11.8535L5.85342 11.3335L3.10675 10.1201C2.9552 10.0535 2.82715 9.94288 2.7392 9.80263C2.65124 9.66237 2.60742 9.49893 2.61342 9.33348C2.60961 9.19202 2.64339 9.05208 2.71132 8.92793C2.77924 8.80378 2.87889 8.69988 3.00008 8.62682L14.6001 2.34682C14.7236 2.28308 14.8611 2.25101 15.0001 2.25348ZM15.0001 0.253482C14.5329 0.250255 14.0732 0.3698 13.6667 0.600148L2.09342 6.86681C1.61162 7.11272 1.21049 7.49171 0.937656 7.95879C0.664821 8.42587 0.531736 8.96142 0.554196 9.50188C0.576657 10.0423 0.753721 10.565 1.06437 11.0078C1.37502 11.4507 1.80621 11.7951 2.30675 12.0001L5.04008 13.2135V17.8135C5.05009 18.3159 5.20183 18.8052 5.47784 19.2251C5.75384 19.645 6.14286 19.9784 6.60008 20.1868L13.8267 23.5068C14.176 23.6673 14.5558 23.7503 14.9401 23.7503C15.3244 23.7503 15.7042 23.6673 16.0534 23.5068L23.3868 20.1868C23.8567 19.9756 24.2554 19.6327 24.5346 19.1997C24.8138 18.7667 24.9616 18.262 24.9601 17.7468V13.1601L27.6934 12.0001C28.1797 11.7885 28.5966 11.4444 28.8966 11.007C29.1965 10.5697 29.3674 10.0568 29.3896 9.5269C29.4119 8.99704 29.2846 8.47162 29.0224 8.01066C28.7602 7.5497 28.3736 7.1718 27.9067 6.92015L16.3334 0.600148C15.927 0.3698 15.4672 0.250255 15.0001 0.253482Z"
      fill="#7B7E8C"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 30,
    },
    height: {
      type: Number,
      default: 24,
    },
  },
};
</script>
